// Copyright (C) 2022 by Posit Software, PBC.

export const notices = {
  deprecatedSettings: 'Your configuration is using deprecated settings. Check your server logs and adjust the configuration as suggested.',
  httpWarning: `Warning: You are accessing @.sys:{'system.displayName'} over an insecure HTTP connection.`,
  licensing: {
    pending: 'Information about your Posit Connect license is unavailable.',
    expired: 'Your Posit Connect license has expired.',
    expiring: `Your Posit Connect {licenseType} expires {expiresWhen}.`,
    contact: `Please contact {contactLink}.`,
  },
};
