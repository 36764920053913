<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <button
    type="button"
    :aria-label="$t('contentHeader.actions.refresh.label')"
    :disabled="isBusy || !isInitialized"
    :title="$t('contentHeader.actions.refresh.label')"
    class="action update content-actions__refresh-button"
    data-automation="menuitem-refresh-report"
    @click="onRefresh"
  />
</template>

<script>
import { SET_CONTENT_FRAME_RELOADING, LOAD_RENDERINGS_HISTORY } from '@/store/modules/contentView';
import { PARAMETERIZATION_REFRESH_REPORT } from '@/store/modules/parameterization';
import { mapActions, mapState, mapMutations } from 'vuex';
import { SHOW_INFO_MESSAGE, CLEAR_STATUS_MESSAGE } from '@/store/modules/messages';
import isEmpty from 'lodash/isEmpty';

export default {
  name: 'RefreshButton',
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      currentVariant: state => state.parameterization.currentVariant,
      isBusy: state => state.parameterization.isBusy,
    }),
    isInitialized() {
      return !isEmpty(this.currentVariant);
    },
  },
  methods: {
    ...mapMutations({
      reloadAppFrame: SET_CONTENT_FRAME_RELOADING,
      clearStatusMessage: CLEAR_STATUS_MESSAGE,
    }),
    ...mapActions({
      refreshReport: PARAMETERIZATION_REFRESH_REPORT,
      fetchRenderings: LOAD_RENDERINGS_HISTORY,
      setInfoMessage: SHOW_INFO_MESSAGE,
    }),
    onRefresh() {
      this.setInfoMessage({
        message: this.$t('content.view.refreshReportMsg'),
        autoHide: false,
      });
      this.refreshReport().then(() => {
        this.clearStatusMessage();
        this.reloadAppFrame(true);
        this.fetchRenderings(this.currentVariant.id);
        this.setInfoMessage({ message: this.$t('content.view.refreshReportComplete') });
      });
    },
  },
};
</script>
