<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--

  The CopyButton component provides a button that will copy a piece of text
  to the clipboard, supplied by the "copyText" prop.

  See `@/views/content/PublishWizard/panels/pinEnvironment/index.vue` for an example

-->
<template>
  <button
    id="copyButton"
    class="rs-button"
    @click="copy"
  >
    {{ copied ? `${$t('common.buttons.copied')} ✓` : $t('common.buttons.copy') }}
  </button>
</template>

<script>
const COPIED_DURATION = 2000;
import { copyToClipboard } from '@/utils/clipboard';

export default {
  name: 'CopyButton',
  props: {
    copyText: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      copied: false,
    };
  },
  methods: {
    async copy() {
      const triggerTarget = document.getElementById('copyButton');
      await copyToClipboard({ value: this.copyText, triggerTarget });
      this.copied = true;
      this.$emit('copied');

      setTimeout(() => {
        this.copied = false;
      }, COPIED_DURATION);
    },
  }
};
</script>

<style scoped lang="scss">
@import 'connect-elements/src/styles/shared/_colors';
@import 'connect-elements/src/styles/shared/_variables';

.rs-button {
  min-width: 8.75rem;
  margin: 0;
  padding: 0.7rem 1.1rem;
  font-size: $rs-font-size-normal;
  line-height: 1rem;
  overflow: visible;
  border: none;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition-duration: 250ms;
  text-align: center;
  background-color: $color-light-grey-3;
  &:hover {
    background-color: $color-light-grey-2;
  }
}
</style>
