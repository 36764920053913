<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <LandingPage
    v-if="serverSettings.customizedLanding"
    data-test="custom-landing-page"
  />
  <div
    v-else
    id="homeContent"
    class="limitBandContentWidth"
    data-test="home-content"
  >
    <div class="band first rightText">
      <div class="bandContent vCenter">
        <div class="blurb">
          <h1
            class="tagline"
            data-automation="welcome-tagline"
          >
            {{ $t("welcome.tagline") }}
          </h1>
          <div>{{ $t("welcome.description") }}.</div>
          <div
            v-if="serverSettings.authentication.handlesLogin"
            class="actions"
          >
            <!-- eslint-disable vuejs-accessibility/no-autofocus -->
            <router-link
              :to="{ name: 'login_view' }"
              class="bigButton"
              data-test="get-started-button"
              autofocus
            >
              {{ $t("welcome.getStarted") }}
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="band second rightText">
      <div class="bandContent vCenter">
        <div class="blurb">
          <div>
            {{ $t("welcome.oneDeploymentButton") }}:
            <ul>
              <li>
                <a
                  target="_blank"
                  href="https://shiny.posit.co/"
                >{{ $t("welcome.apps.shiny") }}</a>
              </li>
              <li>
                {{ $t("welcome.apps.python") }}
                <a
                  target="_blank"
                  href="https://flask.palletsprojects.com/"
                >Flask</a>, <a
                  target="_blank"
                  href="https://dash.plotly.com/"
                >Dash</a>,
                <a
                  target="_blank"
                  href="https://bokeh.org/"
                >Bokeh</a>, {{ $t("common.or") }}
                <a
                  target="_blank"
                  href="https://streamlit.io/"
                >Streamlit</a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://rmarkdown.rstudio.com/"
                >{{ $t("welcome.apps.r") }}</a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://jupyter.org/"
                >{{ $t("welcome.apps.jupyter") }}</a>
              </li>
              <li>{{ $t("welcome.apps.static") }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="band third rightText">
      <div class="bandContent vCenter">
        <div class="blurb">
          <div>
            {{ $t('welcome.features.manageAccess') }}.
          </div>
        </div>
      </div>
    </div>

    <div class="band fourth rightText">
      <div class="bandContent vCenter">
        <div class="blurb">
          <div>
            {{ $t('welcome.features.handsFree') }}.
          </div>
        </div>
      </div>
    </div>

    <div class="band fifth">
      <div class="bandContent">
        <div class="blurb">
          <div>
            {{ $t('welcome.moreInfo') }}
            <a
              target="_blank"
              href="https://posit.co/products/enterprise/connect/"
            >https://posit.co/products/enterprise/connect/</a>.
          </div>
        </div>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import LandingPage from '@/components/Landing/LandingPage';
import AppFooter from '@/components/AppFooter';
import { vueI18n } from '@/i18n/index';
import { mapState, mapActions, mapMutations } from 'vuex';
import {
  SHOW_INFO_MESSAGE,
  SHOW_ERROR_MESSAGE,
} from '@/store/modules/messages';
import { SHRINK_HEADER } from '@/store/modules/navigation';

export default {
  name: 'WelcomeView',
  components: {
    AppFooter,
    LandingPage,
  },
  computed: {
    messages() {
      return {
        loginError: this.$t('welcome.errors.login'),
        policyError: this.$t('welcome.errors.policy'),
      };
    },
    ...mapState({
      serverSettings: state => state.server.settings,
    })
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions({
      statusInfoMessage: SHOW_INFO_MESSAGE,
      statusErrorMessage: SHOW_ERROR_MESSAGE,
    }),
    ...mapMutations({
      shrinkMainHeader: SHRINK_HEADER
    }),
    init() {
      this.setErrorMessage();
      this.shrinkMainHeader(this.serverSettings.customizedLanding);

      if (this.serverSettings.publicWarning) {
        this.statusInfoMessage({ message: this.serverSettings.publicWarning });
      }
    },
    setErrorMessage() {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('msg')) {
        const defaultErrorMessage = vueI18n.global.t('welcome.errors.default');
        const errorMessage =
          this.messages[urlParams.get('msg')] || defaultErrorMessage;
        this.statusErrorMessage({ message: errorMessage });
      }
    },
  },
};
</script>
