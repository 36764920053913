// Copyright (C) 2022 by Posit Software, PBC.

const password = {
  required: 'This field cannot be blank.',
  minLength: 'Password is too short. Must be at least 6 characters.',
  same: 'Passwords must match exactly.',
  sameAsPassword: 'Passwords must match exactly.',
};

const authentication = {
  userCompletion: {
    title: 'Initial Account Setup',
    description:
      'We need a little more information to finish setting up your account.',
    updating: {
      message: 'Updating your user profile...',
    },
  },
  setPassword: {
    title: 'Choose a new password',
    help: [
      'Try using a few words, avoiding common phrases.',
      `Don't base it on the names associated with this user.`,
      `Don't base it on common English names.`,
      `Don't use easy-to-guess passwords such as "password".`,
      `Don't use dates or sequential numbers.`,
      'Letters or keystrokes on a keyboard are considered weak.',
      'Character variety (upper/lower case, digits or symbols) do not guarantee password strength.',
      `"l33t speak" doesn't necessarily make a password harder to guess.`,
    ],
    status: {
      success: 'Your new password has been successfully saved.',
      missingToken: `Missing token in reset password link.
      Please try using the provided link again.`,
      changed: 'Successfully updated password.',
    },
  },
  label: {
    username: 'Username',
    email: 'Email',
    password: 'Password',
    changePassword: 'Change Password',
    currentPassword: 'Current Password',
    newPassword: 'New password',
    password2: 'Password (again)',
    repeatNewPassword: 'New password (again)',
    setPassword: 'Set Password',
    forgotPassword: 'Forgot your password?',
    selfRegistration: 'Don\'t have an account?',
    alreadyHaveAccount: 'Already have an account?',
    confirmPassword: 'Confirm Password',
    challengeResponse: 'Captcha Code',
    challengeImage: 'Captcha Image',
    challengeVerification: 'Captcha Verification *',
    challengeLoading: 'Loading captcha...',
    audioUnsupported: 'Captcha audio is unsupported on this browser.',
    confirmingPassword: 'Confirming Password...',
  },
  validation: {
    username: {
      required: 'Username is required.',
      blank: password.required,
      prohibited: 'Username is prohibited by policy.',
      minLength: 'Username is too short. Must be at least 3 characters.',
      maxLength: 'Username is too long. Must be at most 64 characters.',
      firstLetter: 'The first characters in a username must be a letter.',
      validCharacters:
        'Invalid character used. Can only use letters, numbers, periods, and underscores (_).',
    },
    email: {
      required: 'Email is required.',
      email: 'Not a valid email address.',
    },
    password,
    currentPassword: password,
    newPassword: password,
    repeatNewPassword: password,
  },
  websudo: {
    message:
      'For security purposes, please re-enter the password associated with your {authentication} credentials.',
    unrecoverable: `An unexpected error occurred. Please refresh the page and try again. Error: {error}`,
  },
  login: {
    title: 'Log In',
    blurb: 'Log in with your {auth} credentials',
    warning: `Your authentication is taking longer than expected.
      Please notify your @.sys:{'system.displayName'} administrator.
      Logs may indicate the reason for the delay.`,
    unableToSignIn: `Unable to sign in. Please try again or contact your @.sys:{'system.displayName'}
      administrator if the problem persists.`,
    confirm: {
      enterPassword: `You've already been confirmed. Enter your password to proceed.`,
      enterNewPassword: `You've already set your password. Enter your new password to proceed.`,
    },
    illegalRedirect: 'Illegal redirect to unsafe host. Please contact the Server Administrator. {errUrl}',
    malformedRedirect: 'Illegal redirect to unsafe host. Cannot parse url argument. {errUrl}',
  },
  unauthorized: {
    title: 'Unauthorized',
    message: 'You don\'t have permission to access this page.',
  },
};

export { authentication };
