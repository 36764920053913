<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="contents">
    <ContentTable
      v-show="showTableView"
      :applications="content.applications"
    />
    <ContentBlog
      v-show="showBlogView"
      :applications="content.applications"
    />

    <RSPager
      v-show="showPager"
      data-automation="content-list-pager"
      :disable-left-actions="disablePreviousPagination"
      :disable-right-actions="disableNextPagination"
      :labels="pagerLabels"
      :hide-first-and-last="true"
      @previous-page="gotoPage('previous')"
      @next-page="gotoPage('next')"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import RSPager from 'Shared/components/RSPager';
import ContentBlog from './ContentBlog';
import ContentTable from './ContentTable';
import {
  CONTENT_LIST_UPDATE_START_PAGE,
  ViewType,
} from '@/store/modules/contentList';

// number of records to display at a time
const maxRecords = 10;

export default {
  name: 'MainContent',
  components: { ContentBlog, ContentTable, RSPager },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      contentList: state => state.contentList,
    }),
    showPager() {
      return this.content.total > maxRecords;
    },
    disablePreviousPagination() {
      return this.contentList.startPage === 0;
    },
    disableNextPagination() {
      return (
        this.contentList.startPage + this.content.count >= this.content.total
      );
    },
    showTableView() {
      return this.contentList.viewType === ViewType.TABLE;
    },
    showBlogView() {
      return this.contentList.viewType === ViewType.BLOG;
    },
    pagerLabels() {
      return {
        first: this.$t('navigation.pagination.temporal.first'),
        previous: this.$t('navigation.pagination.temporal.previous'),
        next: this.$t('navigation.pagination.temporal.next'),
        last: this.$t('navigation.pagination.temporal.last'),
      };
    }
  },
  methods: {
    ...mapMutations({
      updateStartPage: CONTENT_LIST_UPDATE_START_PAGE,
    }),
    gotoPage(direction) {
      switch (direction) {
        case 'previous':
          this.updateStartPage(this.contentList.startPage - maxRecords);
          break;
        case 'next':
          this.updateStartPage(this.contentList.startPage + maxRecords);
          break;
      }

      this.$emit('fetch', {
        count: maxRecords,
        cont: this.content.continuation,
      });
    },
  },
};
</script>

<style lang="scss">
.contents {
  margin: 0 .3rem;
}
</style>
