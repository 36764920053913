<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--

Presents a radio group that allows selection of the content access type.

  * Anyone
  * Login required
  * Specific users/groups

Access types prohibited by the Applications.MostPermissiveAccessType or
Applications.AdminMostPermissiveAccessType settings are removed from the set
of available selections (not displayed). When the current access type is
prohibited by these configuration, that value is displayed until a different
access type is chosen.

Access types prohibited by the anonymous-servers license entitlement are
presented as disabled, but included in the radio group.

The entire radio group is presented as disabled when the viewer does not have
write permissions.

-->

<template>
  <RSRadioGroup
    :options="options"
    :value="type"
    name="as-types"
    data-automation="access-type"
    @change="onChange"
  />
</template>

<script>
import RSRadioGroup from 'Shared/components/RSRadioGroup';
import AccessTypes from '@/api/dto/accessType';
import { mapState, mapMutations } from 'vuex';
import {
  ACCESS_SETTINGS_UPDATE_TYPE,
  ACCESS_SETTINGS_UPDATE_MODE,
  ModeType,
} from '@/store/modules/accessSettings';

export default {
  name: 'AccessType',
  components: {
    RSRadioGroup,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    visibleTypes: {
      type: Array,
      required: true,
    },
    isWorkerApp: {
      type: Boolean,
      default: true,
    },
    anonymousServers: {
      type: Boolean,
      default: true,
    },
    anonymousBranding: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapState({
      type: state => state.accessSettings.type,
    }),
    options() {
      // The product license may force the "all" choice to be read-only and
      // include additional messages.
      const allChoice = {
        value: AccessTypes.All,
        label: this.$t('appSettings.access.types.all'),
        disabled: this.readOnly,
      };

      if (this.isWorkerApp) {
        if (!this.anonymousServers) {
          allChoice.disabled = true;
          if (this.type === AccessTypes.All) {
            allChoice.message = this.$t('appSettings.access.sections.sharing.license.allViolation');
            allChoice.messageType = 'error';
          } else {
            allChoice.message = this.$t('appSettings.access.sections.sharing.license.allNotAvailable');
            allChoice.messageType = 'info';
          }
        }
      } else if (this.anonymousBranding) {
        allChoice.message = this.$t('appSettings.access.sections.sharing.license.allBranded');
        allChoice.messageType = 'info';
      }

      // An access type is visible when permitted by configuration.
      const choices = [
        allChoice,
        {
          value: AccessTypes.LoggedIn,
          label: this.$t('appSettings.access.types.loggedIn'),
          disabled: this.readOnly,
        },
        {
          value: AccessTypes.Acl,
          label: this.$t('appSettings.access.types.acl'),
          disabled: this.readOnly,
        },
      ];
      return choices.filter(({ value }) => {
        return (this.type === value ||
                this.visibleTypes.includes(value));
      });
    },
  },
  methods: {
    ...mapMutations({
      updateType: ACCESS_SETTINGS_UPDATE_TYPE,
      updateMode: ACCESS_SETTINGS_UPDATE_MODE,
    }),
    onChange(value) {
      const type = AccessTypes.of(value);
      const mode =
        type === AccessTypes.Acl ? ModeType.VIEWER : ModeType.OWNER;
      this.updateType(type);
      this.updateMode(mode);
      this.$emit('change');
    },
  },
};
</script>
