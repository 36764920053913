<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
  Contains different href targets to visit specific product documentation
-->
<template>
  <div class="layoutMenuOnPage">
    <div class="menu">
      <div class="menuItems">
        <div class="menuItemContainer">
          <h2>
            <a
              :href="userUrl"
              target="_blank"
              class="menuItem userGuide"
              data-automation="documentation-userguide"
            >{{ $t('documentation.user.title') }}</a>
          </h2>
          <div class="standardDescription">
            {{ $t('documentation.user.description') }}
          </div>
        </div>

        <div class="menuItemContainer">
          <h2>
            <a
              :href="adminUrl"
              target="_blank"
              class="menuItem adminGuide"
              data-automation="documentation-adminguide"
            >{{ $t('documentation.admin.title' ) }}</a>
          </h2>
          <div class="standardDescription">
            {{ $t('documentation.admin.description') }}
          </div>
        </div>

        <div class="menuItemContainer">
          <h2>
            <a
              :href="apiReference"
              target="_blank"
              class="menuItem apiDocs"
              data-automation="documentation-apidocs"
            >{{ $t('documentation.api.title') }}</a>
          </h2>
          <div class="standardDescription">
            <i18n-t
              keypath="documentation.api.description"
              tag="span"
            >
              <template #cookbook>
                <a
                  :href="apiCookbook"
                  data-automation="documentation-apicookbook"
                  target="_blank"
                >
                  {{ $t('documentation.api.cookbook') }}
                </a>
              </template>
              <template #reference>
                <a
                  :href="apiReference"
                  data-automation="documentation-apireference"
                  target="_blank"
                >
                  {{ $t('documentation.api.reference') }}
                </a>
              </template>
              <template #yaml>
                <a
                  :href="apiDocsSwaggerYAML"
                  target="_blank"
                >
                  {{ $t('common.yaml') }}
                </a>
              </template>
              <template #json>
                <a
                  :href="apiDocsSwaggerJSON"
                  target="_blank"
                >
                  {{ $t('common.json') }}
                </a>
              </template>
            </i18n-t>
          </div>
        </div>

        <div class="menuItemContainer">
          <h2>
            <a
              href="https://support.posit.co/hc/en-us/sections/205987508-RStudio-Connect"
              target="_blank"
              class="menuItem faq"
              data-automation="documentation-faq"
            >{{ $t('documentation.faq.title') }}</a>
          </h2>
          <div class="standardDescription">
            {{ $t('documentation.faq.description') }}
          </div>
        </div>

        <div class="menuItemContainer">
          <h2>
            <a
              href="https://support.posit.co/hc/en-us/sections/204688508-RStudio-Connect"
              target="_blank"
              class="menuItem articles"
              data-automation="documentation-articles"
            >{{ $t('documentation.articles.title') }}</a>
          </h2>
          <div class="standardDescription">
            {{ $t('documentation.articles.description') }}
          </div>
        </div>

        <div class="menuItemContainer">
          <h2>
            <a
              :href="releaseNotes"
              target="_blank"
              class="menuItem releaseNotes"
              data-automation="documentation-releasenotes"
            >{{ $t('documentation.releaseNotes.title') }}</a>
          </h2>
          <div class="standardDescription">
            {{ $t('documentation.releaseNotes.description') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { docsPath } from '@/utils/paths';

export default {
  name: 'DocumentationViewItems',
  computed: {
    userUrl() {
      return docsPath('user/');
    },
    adminUrl() {
      return docsPath('admin/');
    },
    releaseNotes() {
      return docsPath('news/');
    },
    apiReference() {
      return docsPath('api/');
    },
    apiCookbook() {
      return docsPath('cookbook/');
    },
    apiDocsSwaggerYAML() {
      return docsPath('api/swagger.yaml');
    },
    apiDocsSwaggerJSON() {
      return docsPath('api/swagger.json');
    },
  },
};
</script>

<style scoped lang="scss">

.standardDescription {
  line-height: 1.5em;
}

.layoutMenuOnPage {
  .menuItem {
    &.userGuide {
      background-image: url(connect-elements/src/images/navUserGuide.svg);
    }
    &.adminGuide {
      background-image: url(connect-elements/src/images/navAdminGuide.svg);
    }
    &.faq {
      background-image: url(connect-elements/src/images/navFAQ.svg);
    }
    &.articles {
      background-image: url(connect-elements/src/images/navArticles.svg);
    }
    &.releaseNotes {
      background-image: url(connect-elements/src/images/navReleaseNotes.svg);
    }
    &.apiDocs {
      background-image: url(connect-elements/src/images/navApiDocs.svg);
    }
  }
}
</style>
