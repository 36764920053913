<!-- Copyright (C) 2022 by Posit Software, PBC. -->
<template>
  <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
  <div
    id="userPanel"
    class="sidePanel"
    data-automation="user-panel"
    tabindex="-1"
    @click="closePanel"
    @keyup.esc="closePanel"
  >
    <FocusLock group="sidePanel">
      <nav
        v-if="isAuthenticated"
        class="navMenu"
        data-automation="user-menu"
        aria-label="User Menu"
      >
        <div class="menuSectionTitle">
          <span data-automation="username">{{ currentUser.username }}</span>
          <span
            class="role"
            data-automation="user-role"
          >{{ roleName }}</span>
        </div>
        <ul class="menu">
          <li>
            <router-link
              ref="profile"
              :to="{ name: 'people.users.profile', params: { guid: currentUser.guid } }"
              :class="{ active: isActiveTab('people.users.profile') }"
              class="menuItem profile"
              data-automation="profile"
            >
              <span>{{ $t('userPanel.profile') }}</span>
            </router-link>
          </li>
          <li>
            <router-link
              ref="apiKeys"
              class="menuItem apiKeys"
              data-automation="api-keys"
              :to="{ name: 'people.users.api_keys', params: { guid: currentUser.guid } }"
              :class="{ active: isActiveTab('people.users.api_keys') }"
            >
              <span>{{ $t('userPanel.apiKeys') }}</span>
            </router-link>
          </li>
          <li>
            <div v-if="hasLogout">
              <div class="menuSeparator" />
              <button
                class="menuItem logout"
                data-automation="logout"
                @click="onLogout"
              >
                <span>{{ $t('userPanel.logout') }}</span>
              </button>
            </div>
          </li>
        </ul>
      </nav>
      <nav
        v-else
        class="navMenu"
        data-automation="account-menu"
      >
        <div class="menuSectionTitle">
          {{ $t('userPanel.account') }}
        </div>
        <div class="menu">
          <router-link
            v-if="!isActiveTab('login_view')"
            :to="{ name: 'login_view' }"
            class="menuItem login"
            data-automation="login"
          >
            <span>{{ $t('userPanel.login') }}</span>
          </router-link>
          <router-link
            v-if="!isActiveTab('register_view') && !externalAuth"
            :to="{ name: 'register_view' }"
            class="menuItem signup"
            data-automation="signup"
          >
            <span>{{ $t('userPanel.signUp') }}</span>
          </router-link>
        </div>
      </nav>
    </FocusLock>
  </div>
</template>

<script>
import UserRoles from '@/api/dto/userRole';
import { SHOW_USER_PANEL } from '@/store/modules/navigation';
import { mapState, mapMutations } from 'vuex';

import FocusLock from 'vue-focus-lock';

export default {
  name: 'UserPanel',
  components: { FocusLock },
  computed: {
    ...mapState({
      isAuthenticated: state => state.currentUser.isAuthenticated,
      currentUser: state => state.currentUser.user,
      serverSettings: state => state.server.settings,
    }),
    roleName() {
      const userRole = UserRoles.stringOf(this.currentUser.userRole);
      return this.$t(`users.label.role.${userRole}`);
    },
    externalAuth() {
      return this.serverSettings.authentication.externalUserData;
    },
    hasLogout() {
      return this.serverSettings.logoutUrl !== '';
    },
    isActiveTab() {
      const currentPath = this.$route.name;
      return prefix => currentPath?.startsWith(prefix);
    },
  },
  watch: {
    showUserPanel(show) {
      if (!show) { return; }

      const initialFocus = this.$route.name.startsWith('people.users.api_keys') ?
        this.$refs.apiKeys.$el :
        this.$refs.profile.$el;
      setTimeout(() => initialFocus.focus(), 200);
    },
  },
  methods: {
    ...mapMutations({
      showUserPanel: SHOW_USER_PANEL
    }),
    closePanel() {
      this.showUserPanel(false);
      setTimeout(() => document.querySelector('button.profileButton').focus(), 200);
    },
    onLogout() {
      window.location.href = this.serverSettings.logoutUrl;
    },
  }
};
</script>

<style scoped lang="scss">
@import 'connect-elements/src/styles/shared/_colors';
@import 'connect-elements/src/styles/shared/_variables';
@import 'connect-elements/src/styles/shared/_mixins';

#userPanel {
  right: 0;
  width: $user-panel-width;
  background-color: $color-alternate-dark;
}

.navMenu {
  font-size: 13px;
  overflow-x: hidden;

  .menu {
    font-size: 13px;
    margin-bottom: 30px;

    .menuSeparator {
      margin: 3px $side-panel-padding;
      height: 0;
      border-bottom: 1px dotted;
      opacity: 0.2;
      border-color: #fff;
    }
  }

  .menuSectionTitle {
    display: block;
    line-height: 30px;
    padding: 0 $side-panel-padding;
    color: $color-white;

    .role {
      font-size: .8em;

      &::before {
        content: " : "
      }
    }
  }

  .menuItem {
    display: block;
    border-radius: 0;
    margin-right: 0;
    line-height: 40px;
    padding: 0 $side-panel-padding 0 47px;
    font-size: 13px;
    background-repeat: no-repeat;
    background-size: 15px 80px;
    background-position: 25px 0;

    &:hover,
    &:hover * {
      background-color: $color-alternate;
    }

    span {
      background-color: $color-alternate-dark;
    }

    &.active,
    &.active * {
      background-position-y: -40px;
      color: $color-brown;
      background-color: $color-light-grey-2;
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: none;
      background-color: $color-posit-teal;
      background-position-y: -40px;

      span {
        background-color: transparent;
        color: $color-button-text;
      }
    }

    &.apiKeys {
      background-image: url(connect-elements/src/images/navAPIKeys.svg);
    }

    &.login {
      background-image: url(connect-elements/src/images/navLogin.svg);
    }

    &.signup {
      background-image: url(connect-elements/src/images/navSignup.svg);
    }

    &.profile {
      background-image: url(connect-elements/src/images/navProfile.svg);
    }

    &.logout {
      background-image: url(connect-elements/src/images/navLogout.svg);
      width: 100%;
      text-align: left;
    }
  }
}</style>
