<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <fieldset :disabled="disableInputs">
    <RSInputSelect
      ref="directorySelect"
      :value="directory"
      :options="directories"
      name="git-directory-name"
      data-automation="git-directory-name"
      @change="newValue => $emit('update:directory', newValue)"
    >
      <template #label>
        <i18n-t
          keypath="content.deploymentDialog.selectDirectory.label"
          tag="span"
        >
          <template #manifestJson>
            <code>
              {{ $t('content.deploymentDialog.common.manifestJson') }}
            </code>
          </template>
        </i18n-t>
      </template>
    </RSInputSelect>

    <RSInputText
      :value="contentTitle"
      :label="
        $t('content.deploymentDialog.selectDirectory.enterTitleLabel')
      "
      :message="contentTitleMessage"
      name="git-content-title"
      data-automation="git-content-title"
      @input="newValue => $emit('update:contentTitle', newValue)"
    />
  </fieldset>
</template>

<script>
import RSInputSelect from 'Shared/components/RSInputSelect';
import RSInputText from 'Shared/components/RSInputText';

export default {
  name: 'SelectDirectory',
  components: {
    RSInputSelect,
    RSInputText,
  },
  props: {
    directory: {
      type: String,
      required: true,
    },
    directories: {
      type: Array,
      required: true,
    },
    disableInputs: {
      type: Boolean,
      required: true,
    },
    contentTitle: {
      type: String,
      required: true,
    },
    contentTitleMessage: {
      type: String,
      default: '',
    },
  },
  emits: ['update:contentTitle', 'update:directory'],
  mounted() {
    setTimeout(() => this.$refs.directorySelect.focusElement(), 0);
  },
};
</script>
