<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <RSModal
    :active="true"
    :subject="$t('appSettings.access.labels.runAsWarning')"
    @close="$emit('close')"
  >
    <template #content>
      <div>
        <p>
          {{ $t('appSettings.access.messages.runAsWarning',
                [$t(`content.type.description.${contentType}`)]
          ) }}
        </p>
      </div>
    </template>

    <template #controls>
      <RSButton
        :label="$t('common.buttons.save')"
        data-automation="runas-warning-dialog-submit"
        @click="$emit('save')"
      />
    </template>
  </RSModal>
</template>

<script>
import RSButton from 'Shared/components/RSButton';
import RSModal from 'Shared/components/RSModal';

export default {
  name: 'RunAsWarning',
  components: { RSButton, RSModal },
  props: {
    contentType: {
      type: String,
      required: true,
    },
  },
};
</script>
