<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <RSModal
    :active="true"
    :subject="$t('appSettings.access.executionEnvironment.selectDialog.title')"
    width="medium"
    data-automation="default-execution-environment-dialog"
    name="default-execution-environment-dialog"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <template #content>
      <div class="description">
        <div
          v-for="msg in $tm('appSettings.access.executionEnvironment.selectDialog.help')"
          :key="msg"
          class="description__msg"
        >
          {{ $rt(msg) }}
        </div>
      </div>
      <EnvironmentsList
        :readonly="true"
        :dialog-mode="true"
        :currently-selected-guid="currentlySelectedGuid"
        data-automation="environments-list"
        @environment-count="onEnvironmentCountUpdate"
        @select="onSelect"
        @selectionVisible="onSelectionVisible"
      />
    </template>
    <template #controls>
      <RSButton
        id="select-default-execution-environment-dialog-submit"
        :label="$t('appSettings.access.executionEnvironment.selectDialog.submitLabel')"
        :disabled="disableSubmit"
        data-automation="select-default-execution-environment-dialog-submit"
        @click="onSubmit"
      />
    </template>
  </RSModal>
</template>

<script>
import RSModal from 'Shared/components/RSModal';
import RSButton from 'Shared/components/RSButton';
import EnvironmentsList from '@/views/executionEnvironments/EnvironmentsList.vue';

export default {
  name: 'DefaultExecutionEnvironmentDialog',
  components: {
    RSModal,
    RSButton,
    EnvironmentsList,
  },
  props: {
    initialSelectedEnvironment: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      currentlySelectedEnvironment: this.initialSelectedEnvironment,
      numberOfEnvironments: 0,
      selectionVisible: false,
    };
  },
  computed: {
    disableSubmit() {
      return (
        this.numberOfEnvironments === 0 ||
        !this.haveSelection ||
        !this.selectionVisible
      );
    },
    currentlySelectedGuid() {
      if (!this.haveSelection) {
        return '';
      }
      return this.currentlySelectedEnvironment.guid;
    },
    haveSelection() {
      return this.currentlySelectedEnvironment !== null;
    },
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.currentlySelectedEnvironment);
    },
    onSelect(environment) {
      this.currentlySelectedEnvironment = environment;
    },
    onSelectionVisible(state) {
      this.selectionVisible = state;
    },
    onEnvironmentCountUpdate(count) {
      this.numberOfEnvironments = count;
    },
  },
};
</script>

<style lang="scss" scoped>

.description {
  margin-bottom: 2rem;

  &__msg {
    font-size: 1rem;
    font-weight: 400;
    word-wrap: break-word;
    line-height: 1.5;
    margin-bottom: 0.5rem;
  }
}
</style>
