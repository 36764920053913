<!-- Copyright (C) 2022 by Posit Software, PBC. -->
<template>
  <RSModalForm
    v-if="active"
    :active="true"
    :subject="$t('content.deploymentDialog.title')"
    data-automation="deployment-wizard__modal"
    @close="close"
    @submit="handleNext"
  >
    <template #content>
      <div>
        <EmbeddedStatusMessage
          v-show="statusMessage"
          :message="statusMessage"
          :type="statusMessageType"
          class="rs-field"
          @close="clearStatusMessage"
        />

        <SelectRepository
          v-if="enterRepositoryState"
          :disable-inputs="disableInputs"
          :learn-more-link="learnMoreLink"
          :repository-url="repositoryUrl"
          @update:repositoryUrl="url => repositoryUrl = url"
          @valid="onRepositoryURLValidityChange"
        />

        <BranchScanFailure
          v-if="branchScanningFailedState"
          :branch-scanning-error="branchScanningError"
        />

        <SelectBranch
          v-if="selectBranchState"
          :branch-name="branchName"
          :branch-names="branchNames"
          :label="$t('content.deploymentDialog.selectBranch.label')"
          @update:branchName="branch => branchName = branch"
        />

        <DirectoryScanFailure
          v-if="directoryScanningFailedState"
          :has-directory-scanning-error="hasDirectoryScanningError"
          :user-guide-location="userGuideLocation"
        />

        <SelectDirectory
          v-if="selectDirectoryState"
          :content-title="contentTitle"
          :content-title-message="contentTitleMessage"
          :directory="directory"
          :directories="directories"
          :disable-inputs="disableInputs"
          @update:contentTitle="onUpdateContentTitle"
          @update:directory="newDirectory => directory = newDirectory"
        />

        <div
          v-if="gitMisconfiguredState"
          class="rs-field"
        >
          {{ $t('content.deploymentDialog.gitMisconfigured.message') }}
        </div>

        <div
          v-if="showLogsView"
          class="rs-field"
        >
          <LogViewer
            :entries="logEntries"
            data-automation="git-deploy-message"
          />
        </div>
      </div>
    </template>

    <template #controls>
      <div class="rsc-row">
        <RSButton
          v-if="showBackButton"
          ref="backButton"
          :label="backButtonLabel"
          type="secondary"
          @click.prevent="handleBack"
        />
        <RSButton
          v-if="showNextButton"
          :label="nextButtonLabel"
          :disabled="disableNextButton"
          data-automation="git-next-button"
          @click.prevent="handleNext"
        />
      </div>
      <RSButton
        v-if="showOpenContentButton"
        :label="openContentLabel"
        :disabled="disableOpenContentButton"
        data-automation="git-open-content"
        @click.prevent="handleOpen"
      />
      <RSButton
        v-if="showOkButton"
        v-bind="{
          label: $t('common.buttons.ok'),
          buttonClass: 'default'
        }"
        :label="$t('common.buttons.ok')"
        data-automation="git-dialog-submit"
        @click.prevent="close"
      />
    </template>
  </RSModalForm>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { mapMutations, mapState } from 'vuex';
import { DEPLOY_WIZARD_CLOSE } from '@/store/modules/deployWizard';
import RSButton from 'Shared/components/RSButton';
import RSModalForm from 'Shared/components/RSModalForm';

import LogViewer from '@/components/LogViewer.vue';
import EmbeddedStatusMessage, {
  ActivityMessage,
  ErrorMessage,
} from '@/components/EmbeddedStatusMessage.vue';
import { vueI18n } from '@/i18n/index';

import {
  getBranchesResult,
  getSubdirectoriesResult,
} from '@/api/git';
import {
  createApplication,
  deployApplicationResult,
  setApplicationRepository,
} from '@/api/app';
import { safeAPIErrorMessage } from '@/api/error';
import { JobLogLine } from '@/api/dto/job';
import { docsPath } from '@/utils/paths';
import { required } from '@vuelidate/validators';

import BranchScanFailure from './BranchScanFailure';
import DirectoryScanFailure from './DirectoryScanFailure';
import SelectBranch from './SelectBranch';
import SelectDirectory from './SelectDirectory';
import SelectRepository from './SelectRepository';

/**
 * Some enums for the dialog state
 */
const ENTER_REPOSITORY_STATE = 'ENTER_REPOSITORY_STATE';
const SELECT_BRANCH_STATE = 'SELECT_BRANCH_STATE';
const SELECT_DIRECTORY_STATE = 'SELECT_DIRECTORY_STATE';
const DEPLOYING_CONTENT_STATE = 'DEPLOYING_CONTENT_STATE';
const READY_TO_OPEN_CONTENT_STATE = 'READY_TO_OPEN_CONTENT_STATE';
const BRANCH_SCANNING_FAILED_STATE = 'BRANCH_SCANNING_FAILED_STATE';
const DIRECTORY_SCANNING_FAILED_STATE = 'DIRECTORY_SCANNING_FAILED_STATE';
const DEPLOYING_CONTENT_FAILED_STATE = 'DEPLOYING_CONTENT_FAILED_STATE';
const GIT_MISCONFIGURED_STATE = 'GIT_MISCONFIGURED_STATE';

// directoriesTaskToOptions converts an array of directory names to an array of objects suitable to be used as Options
// in a Select component.  The root directory is re-labeled from '.' to something more descriptive to make it clear.
export function directoriesTaskToOptions(task) {
  return task.data.map(dirname => ({
    value: dirname,
    label:
    dirname === '.'
      ? `[${vueI18n.global.t('content.deploymentDialog.common.rootDirectory')}]`
      : dirname,
  }));
}

const RefreshContentListEvent = 'refresh-content-list';

function concatenateLogEntriesFactory(thisReference) {
  return function onPoll(polledTask) {
    thisReference.logEntries = thisReference.logEntries.concat(
      polledTask.status.map(line => (new JobLogLine({ line: line, isError: false })))
    );
  };
}

function initialState(gitAvailable) {
  return {
    state: gitAvailable ? ENTER_REPOSITORY_STATE : GIT_MISCONFIGURED_STATE,
    repositoryUrl: '',
    repositoryUrlValid: false,
    branchName: '',
    branches: [],
    directory: '/',
    statusMessage: '',
    statusMessageType: null,
    directories: [],
    logEntries: [],
    contentTitle: '',
    appGuid: '',
    branchScanningError: '',
    directoryScanningError: '',
    learnMoreLink: docsPath('user/git-backed/'),
    disableInputs: false,
  };
}

/**
 * DeploymentWizard is the entire content deployment wizard dialog.
 *
 * +----------------+                        +---------------+
 * | enter repo URL | -> ( get branches ) -> | select branch | -> ( get dirs ) ->
 * +----------------+                        +---------------+
 *
 * +-------------+
 * | select dir  | -> ( deploy ) -> ( go to content )
 * +-------------+
 *
 * If get branches or get dirs fail, we send the user to an intermediary step that
 * informs them of the nature of the failure and lets them go back or enter manually.
 */
export default {
  name: 'DeploymentWizard',
  components: {
    BranchScanFailure,
    DirectoryScanFailure,
    EmbeddedStatusMessage,
    LogViewer,
    RSButton,
    RSModalForm,
    SelectBranch,
    SelectDirectory,
    SelectRepository,
  },
  props: {
    gitAvailable: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return initialState(this.gitAvailable);
  },
  validations: {
    contentTitle: {
      required,
    },
  },
  computed: {
    ...mapState({
      active: state => state.deployWizard.active,
    }),
    enterRepositoryState() {
      return this.state === ENTER_REPOSITORY_STATE;
    },
    selectBranchState() {
      return this.state === SELECT_BRANCH_STATE;
    },
    selectDirectoryState() {
      return this.state === SELECT_DIRECTORY_STATE;
    },
    branchScanningFailedState() {
      return this.state === BRANCH_SCANNING_FAILED_STATE;
    },
    directoryScanningFailedState() {
      return this.state === DIRECTORY_SCANNING_FAILED_STATE;
    },
    gitMisconfiguredState() {
      return this.state === GIT_MISCONFIGURED_STATE;
    },
    showLogsView() {
      const showLogsViewStates = [
        DEPLOYING_CONTENT_STATE,
        READY_TO_OPEN_CONTENT_STATE,
        DEPLOYING_CONTENT_FAILED_STATE,
      ];
      return (
        showLogsViewStates.find(state => state === this.state) !== undefined
      );
    },
    showBackButton() {
      const hideBackButtonStates = [
        ENTER_REPOSITORY_STATE,
        DEPLOYING_CONTENT_STATE,
        READY_TO_OPEN_CONTENT_STATE,
        GIT_MISCONFIGURED_STATE,
      ];
      return (
        hideBackButtonStates.find(state => state === this.state) === undefined
      );
    },
    showNextButton() {
      const hideNextButtonStates = [
        READY_TO_OPEN_CONTENT_STATE,
        DEPLOYING_CONTENT_STATE,
        BRANCH_SCANNING_FAILED_STATE,
        DIRECTORY_SCANNING_FAILED_STATE,
        DEPLOYING_CONTENT_FAILED_STATE,
        GIT_MISCONFIGURED_STATE,
      ];
      return (
        hideNextButtonStates.find(state => state === this.state) === undefined
      );
    },
    showOpenContentButton() {
      const showOpenContentButtonStates = [
        DEPLOYING_CONTENT_STATE, // But disabled
        READY_TO_OPEN_CONTENT_STATE,
      ];
      return (
        showOpenContentButtonStates.find(state => state === this.state) !==
          undefined
      );
    },
    showOkButton() {
      return this.state === GIT_MISCONFIGURED_STATE;
    },
    nextButtonLabel() {
      if (this.state === SELECT_DIRECTORY_STATE) {
        return this.$t('content.deploymentDialog.common.deployContentLabel');
      }
      return this.$t('content.deploymentDialog.common.nextButtonLabel');
    },
    backButtonLabel() {
      return this.$t('content.deploymentDialog.common.backButtonLabel');
    },
    openContentLabel() {
      return this.$t('content.deploymentDialog.common.openContentLabel');
    },
    disableOpenContentButton() {
      return this.state !== READY_TO_OPEN_CONTENT_STATE;
    },
    disableNextButton() {
      if (this.state === ENTER_REPOSITORY_STATE) {
        return !this.repositoryUrlValid;
      } else if (this.state === SELECT_DIRECTORY_STATE) {
        return this.v$.contentTitle.$invalid;
      }
      return false;
    },
    hasDirectories() {
      return !!this.directories.length;
    },
    hasDirectoryScanningError() {
      return !!this.directoryScanningError;
    },
    branchNames() {
      return this.branches.map(branchData => ({
        value: branchData.branch,
        label: branchData.branch,
      }));
    },
    contentTitleRequiredError() {
      return !this.v$.contentTitle.required && this.v$.contentTitle.$dirty;
    },
    contentTitleMessage() {
      return this.contentTitleRequiredError
        ? this.$t('content.deploymentDialog.selectDirectory.required')
        : null;
    },
    userGuideLocation() {
      return docsPath('user/git-backed/');
    },
  },
  methods: {
    ...mapMutations({
      closeModal: DEPLOY_WIZARD_CLOSE,
    }),
    close() {
      if (this.state !== DEPLOYING_CONTENT_STATE) {
        this.resetState();
      }
      this.closeModal();
    },
    clearStatusMessage() {
      this.statusMessage = '';
      this.statusMessageType = '';
    },
    setActivityMessage(message) {
      this.statusMessage = message;
      this.statusMessageType = ActivityMessage;
    },
    setErrorMessage(message) {
      this.statusMessage = message;
      this.statusMessageType = ErrorMessage;
    },
    selectDefaultBranch() {
      // priority = ['master', 'main', 'develop']; otherwise, use the first element of this.branches
      const masters = this.branches.filter(({ branch }) => branch === 'master');
      const mains = this.branches.filter(({ branch }) => branch === 'main');
      const develops = this.branches.filter(({ branch }) => branch === 'develop');
      if (masters.length > 0) {
        return 'master';
      }
      if (mains.length > 0) {
        return 'main';
      }
      if (develops.length > 0) {
        return 'develop';
      }
      return this.branches[0].branch;
    },
    handleOpen() {
      this.closeModal();
      this.$router.push({ name: 'apps.access', params: { idOrGuid: this.appGuid } });
    },
    handleBack() {
      const backStates = {
        ENTER_REPOSITORY_STATE,
        BRANCH_SCANNING_FAILED_STATE: ENTER_REPOSITORY_STATE,
        SELECT_BRANCH_STATE: ENTER_REPOSITORY_STATE,
        DIRECTORY_SCANNING_FAILED_STATE: SELECT_BRANCH_STATE,
        SELECT_DIRECTORY_STATE: SELECT_BRANCH_STATE,
        DEPLOYING_CONTENT_STATE: SELECT_DIRECTORY_STATE,
        DEPLOYING_CONTENT_FAILED_STATE: SELECT_DIRECTORY_STATE,
      };

      this.state = backStates[this.state];
    },
    handleNext() {
      switch (this.state) {
        case ENTER_REPOSITORY_STATE: {
          this.setActivityMessage(
            this.$t(
              'content.deploymentDialog.common.fetchingBranchesActivityMessage'
            )
          );

          // disable inputs while contacting server
          this.disableInputs = true;

          return getBranchesResult(this.repositoryUrl, function onPoll() {
            // TODO: Follow log lines
          })
            .then(successfulTask => {
              this.branches = successfulTask.data;
              this.branchName = this.selectDefaultBranch();
              this.state = SELECT_BRANCH_STATE;
            })
            .catch(err => {
              this.state = BRANCH_SCANNING_FAILED_STATE;
              this.branchScanningError = err;
            })
            .finally(() => {
              this.clearStatusMessage();
              this.disableInputs = false;
            });
        }
        case SELECT_BRANCH_STATE: {
          this.setActivityMessage(
            this.$t(
              'content.deploymentDialog.common.fetchingDirectoriesActivityMessage'
            )
          );

          // disable inputs while contacting server
          this.disableInputs = true;

          return getSubdirectoriesResult(
            this.repositoryUrl,
            this.branchName,
            function onPoll() {
              // TODO: Placeholder until we have output to report here.
              // TODO: ... and a place to report the output.
            }
          )
            .then(successfulTask => {
              if (!successfulTask.data.length) {
                throw new Error(
                  this.$t(
                    'content.deploymentDialog.common.noManifestsFoundError'
                  )
                );
              }
              this.directory = successfulTask.data[0];
              this.directories = directoriesTaskToOptions(successfulTask);
              this.state = SELECT_DIRECTORY_STATE;
              this.clearStatusMessage();
            })
            .catch(err => {
              this.directoryScanningError = true;
              this.state = DIRECTORY_SCANNING_FAILED_STATE;
              this.setErrorMessage(err.message);
            })
            .finally(() => {
              this.disableInputs = false;
            });
        }
        case SELECT_DIRECTORY_STATE: {
          let appGuid;
          this.state = DEPLOYING_CONTENT_STATE;
          this.setActivityMessage(
            this.$t(
              'content.deploymentDialog.common.deployingContentActivityMessage'
            )
          );
          // FIXME: Slugify content name and append timestamp, for time being
          // FIXME: This prevents conflicts, pending https://github.com/rstudio/connect/issues/12984
          const cName = this.contentTitle
            .replace(/[^a-zA-Z0-9\-]/g, '-')
            .concat('-')
            .concat(Date.now().toString());

          // disable inputs while contacting server
          this.disableInputs = true;

          return createApplication(cName, this.contentTitle)
            .then(result => result.data)
            .then(application => {
              appGuid = application.guid;
              return setApplicationRepository(
                appGuid,
                this.repositoryUrl,
                this.branchName,
                this.directory
              );
            })
            .then(() => deployApplicationResult(
              appGuid,
              concatenateLogEntriesFactory(this)
            ))
            .then(result => {
              if (result.error) {
                // A deployment task error occurred.
                // We have an appGuid, though, and can open the
                // failed content to give them an opportunity to fix it.
                this.appGuid = appGuid;
                this.state = READY_TO_OPEN_CONTENT_STATE;
                this.setErrorMessage(result.error);
              } else {
                // Successful deployment
                this.appGuid = appGuid;
                this.state = READY_TO_OPEN_CONTENT_STATE;
                this.clearStatusMessage();
              }
            })
            .catch(err => {
              // An API error occurred.
              if (appGuid !== undefined) {
                // Their deployment failed, somehow.
                // We have an appGuid, though, and can open the
                // failed content to give them an opportunity to fix it.
                this.appGuid = appGuid;
                this.state = READY_TO_OPEN_CONTENT_STATE;
                this.setErrorMessage(safeAPIErrorMessage(err));
              } else {
                // Their deployment didn't even create content yet.
                // We cannot transition to "ready to deploy".
                this.state = DEPLOYING_CONTENT_FAILED_STATE;
                this.setErrorMessage(safeAPIErrorMessage(err));
              }
            })
            .finally(() => {
              this.disableInputs = false;
              // note: this is racy since this kicks off a saga in the background
              return this.$emit(RefreshContentListEvent);
            });
        }
        case DEPLOYING_CONTENT_STATE: {
          break;
        }
      }
    },
    onUpdateContentTitle(newTitle) {
      this.contentTitle = newTitle;
      this.v$.contentTitle.$touch();
    },
    resetState() {
      // restore original state and reset validators
      Object.assign(this.$data, initialState(this.gitAvailable));
      this.v$.$reset();
    },
    onRepositoryURLValidityChange(valid) {
      this.repositoryUrlValid = valid;
    },
  },
};
</script>

<style lang="scss" scoped>
.rsc-row {
  display: flex;

  :not(:last-child) {
    margin-right: 1rem;
  }
}

.rs-field {
  &:not(:last-child) {
    margin-bottom: 0.9rem;
  }
}
</style>
