<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="pageContainer">
    <div id="contentContainer">
      <iframe
        id="contentIFrame"
        :src="url"
        :title="$t('landingPage.title')"
        data-test="content-iframe"
      />
    </div>
  </div>
</template>

<script>
import { landingPath } from '@/utils/paths';

export default {
  name: 'LandingPage',
  data() {
    return {
      url: landingPath(),
    };
  }
};
</script>
