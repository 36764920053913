// Copyright (C) 2022 by Posit Software, PBC.

/**
 * Helper functions to build each chart's options object and dataset
 * to be consumed by chartjs.
 */

import moment from 'moment-mini';
import { vueI18n } from '@/i18n/index';
import { humanizeBytesBinary } from '@/utils/bytes.filter';

/**
 * Gets the custom format tick function used by chartjs when formatting the time axis.
 * The function returned takes in the unformatted value (val) to be displayed for the tick,
 * the index (i) for the unformatted value in the major ticks list of objects,
 * and a list of objects (vals) with the major ticks for the axis, where each object
 * in the list has the raw timestamp in milliseconds for the tick as { value }.
 * That raw timestamp in milliseconds for the tick is needed to apply the custom format.
 * However, this function is sometimes called by chartjs w/ an empty list, hence
 * that scenario needs special handling/ignoring for applying the custom format.
 *
 * @param {string} format - Time format string accepted by moment's format function
 *
 * @returns {Function} - Formats the value to be displayed for the axis' tick
 */
const getTimeFormatter = format => {
  return (val, i, vals) => {
    if (!vals || !vals.length) {
      return val;
    }

    return moment(vals[i].value).format(format);
  };
};

/**
 * Constructs a time formatting callback function that uses less granular
 * times for longer intervals. Used to format the x-axis time ticks.
 *
 * Examples:
 * A many-years time horizon (above 3 years) only shows the year on the x-axis.
 * A short (less than 2 days) time window uses hour-minute level marks.
 * Above 2 days and less than 1 year time window uses day-month level marks.
 * Above 1 year and less than 3 years time window uses month-year level marks.
 *
 * @param {Object} object - The current timeframe
 * @param {number} object.unit - The timeframe unit (1, 2, 3, 20, etc)
 * @param {string} object.range - The timeframe range (hours, days, weeks, months, years)
 *
 * @returns {Function} - callback function to format time using moment
 */
const getTimeFormat = ({ unit, range }) => {
  const durationAsSeconds = moment.duration(unit, range).asSeconds();
  const timeMeasure = {
    threeYears: moment.duration(3, 'years').asSeconds(),
    oneYear: moment.duration(1, 'years').asSeconds(),
    twoDays: moment.duration(2, 'days').asSeconds(),
  };

  if (durationAsSeconds >= timeMeasure.threeYears) {
    return getTimeFormatter('YYYY');
  }
  if (durationAsSeconds >= timeMeasure.oneYear) {
    return getTimeFormatter('MMM YYYY');
  }
  if (durationAsSeconds >= timeMeasure.twoDays) {
    return getTimeFormatter('D MMM');
  }

  return getTimeFormatter('hh:mm a');
};

/**
 * Returns an object with all the defaults for the options to be used
 * by chartjs to build each metrics chart
 *
 * @returns {Object} - default base options object for all charts
 */
const getChartDefaults = () => ({
  responsive: true, // make it mobile friendly (tooltips display/hide on touch action)
  maintainAspectRatio: false, // needed for custom chart sizing (done in container via CSS)
  legend: {
    labels: { boxWidth: 10 }, // makes the legend color square smaller
    // Disabling legend filtering b/c it needs proper handling
    // to maintain the filter selection on web socket update
    onClick: e => e.stopPropagation(),
  },
  elements: { line: { tension: 0 } }, // makes the line straight vs curvy
  // Displays the tooltip for nearest item in the x direction (best UX out of the box)
  tooltips: {
    mode: 'index',
    intersect: false,
    position: 'nearest',
    bodySpacing: 4,
    caretPadding: 5,
  },
  scales: {
    // Makes the x-axis a time axis that auto selects human friendly major ticks
    // the max/min rotation ensure the time labels will always display horizontally
    xAxes: [{ type: 'time', ticks: { maxRotation: 0, minRotation: 0 } }],
    // maxTicksLimit sets the max amount of y-axis ticks for the chart,
    // it could have less but no less than 2, the final tick number will be
    // calculated by chartjs and will break at human friendly points
    yAxes: [{ ticks: { beginAtZero: true, maxTicksLimit: 5 } }],
  },
});

/**
 * Build options object for each chart using the defaults with custom overriding
 *
 * @param {Object} object - Customization for each chart
 * @param {Object} object.period - The current timeframe (unit and range)
 * @param {boolean} object.stacked - Stack or unstacked chart
 * @param {Function} object.yFormatter - Formatting function for the y-axis
 * @param {number} object.max - Y-axis max value
 * @param {Object} object.tooltip - Tooltip formatter (label, footer and afterTitle)
 *
 * @returns {Object} - customized options object from chart default options
 */
const buildOptions = ({ period, stacked, yFormatter, max, tooltip }) => {
  const options = getChartDefaults();
  const xAxis = options.scales.xAxes[0].ticks;
  const yAxis = options.scales.yAxes[0];

  xAxis.callback = getTimeFormat(period); // x-axis tick time format function
  yAxis.stacked = stacked; // make it a stacked chart
  yAxis.ticks.max = max; // give it a set max for the y-axis
  // Formatting function for the y-axis, must declare a default function if not passed in
  // otherwise chartjs blows up if this callback function is set to unfined
  yAxis.ticks.callback = yFormatter || (value => value);
  // tooltip callback object with handlers to format the tooltip data (label, footer, afterTitle)
  options.tooltips.callbacks = tooltip;

  return options;
};

/**
 * Helper to format the RAM chart's y-axis data ticks
 *
 * @param {number} bytes - The amount of bytes to be formatted
 *
 * @returns {string} - humanized number of bytes
 */
const formatBytes = bytes => {
  return humanizeBytesBinary(Math.abs(bytes));
};

/**
 * Builds the options object for the cpu chart, has y-axis formatter,
 * must have a declared max of 100% and must be stacked
 *
 * @param {Object} period - The current timeframe
 * @param {number} period.unit - The timeframe unit (1, 2, 3, 20, etc)
 * @param {string} period.range - The timeframe range (hours, days, weeks, months, years)
 *
 * @returns {Object} - options object for the cpu chart
 */
const cpuOptions = period => {
  let cpuChartTotal = 0; // used to aggregate the tooltip total
  const yFormatter = value => `${value}%`;
  const tooltip = {
    // Re-initializes the total as the mouse moves across the axis from point to point
    afterTitle: () => {
      cpuChartTotal = 0;
    },
    title: tooltipItems => {
      const label = (tooltipItems[0] || tooltipItems[1] || tooltipItems[2]).xLabel;
      const date = moment(label, 'MMM D, YYYY, h:mm:ss a').toDate();
      return moment(date).format('MMM D, YYYY h:mma');
    },
    footer: () => `TOTAL:   ${Math.round(cpuChartTotal * 100) / 100}%`, // diplays the total
    label: (tooltipItem, data) => {
      // formats each data point label and aggregates total
      cpuChartTotal += tooltipItem.yLabel;
      const label = `  ${data.datasets[tooltipItem.datasetIndex].label || ''}`;
      return `${label}:   ${tooltipItem.yLabel.toFixed(2)}%`;
    },
  };

  return buildOptions({ period, stacked: true, yFormatter, tooltip, max: 100 });
};

/**
 * Builds the options object for the ram chart, has y-axis formatter,
 * max is automatically calculated by chartjs using the dataset and must be stacked
 *
 * @param {Object} period - The current timeframe
 * @param {number} period.unit - The timeframe unit (1, 2, 3, 20, etc)
 * @param {string} period.range - The timeframe range (hours, days, weeks, months, years)
 *
 * @returns {Object} - options object for the ram chart
 */
const ramOptions = period => {
  let ramChartTotal = 0; // used to aggregate the tooltip total
  const yFormatter = formatBytes;
  const tooltip = {
    // Re-initializes the total as the mouse moves across the axis from point to point
    afterTitle: () => {
      ramChartTotal = 0;
    },
    title: tooltipItems => {
      const label = (tooltipItems[0] || tooltipItems[1]).xLabel;
      const date = moment(label, 'MMM D, YYYY, h:mm:ss a').toDate();
      return moment(date).format('MMM D, YYYY h:mma');
    },
    footer: () => `TOTAL:   ${formatBytes(ramChartTotal)}`, // diplays the total
    label: (tooltipItem, data) => {
      // formats each data point label and aggregates total
      ramChartTotal += tooltipItem.yLabel;
      const label = `  ${data.datasets[tooltipItem.datasetIndex].label || ''}`;
      return `${label}:   ${formatBytes(tooltipItem.yLabel)}`;
    },
  };

  return buildOptions({ period, stacked: true, yFormatter, tooltip });
};

/**
 * Builds the options object for the users and shinyUsers charts, not stacked,
 * max is automatically calculated by chartjs using the dataset.
 * There is no aggregating of data points on tooltip formatting function
 * and y-axis is not formatted.
 *
 * @param {Object} period - The current timeframe
 * @param {number} period.unit - The timeframe unit (1, 2, 3, 20, etc)
 * @param {string} period.range - The timeframe range (hours, days, weeks, months, years)
 *
 * @returns {Object} - options object for the users and shinyUsers chart
 */
const userOptions = period => {
  const tooltip = {
    title: tooltipItems => {
      const label = (tooltipItems[0] || tooltipItems[1] || tooltipItems[2]).xLabel;
      const date = moment(label, 'MMM D, YYYY, h:mm:ss a').toDate();
      return moment(date).format('MMM D, YYYY h:mma');
    },
    label: (tooltipItem, data) => {
      // formats each data point label
      const label = `  ${data.datasets[tooltipItem.datasetIndex].label || ''}`;
      return `${label}:   ${tooltipItem.yLabel.toFixed(1)}`;
    },
  };

  return buildOptions({ period, tooltip });
};

/**
 * Determines the appropriate options builder for the chart type
 *
 * @param {string} chartType - The type of chart
 * @param {Object} period - The current timeframe
 * @param {number} period.unit - The timeframe unit (1, 2, 3, 20, etc)
 * @param {string} period.range - The timeframe range (hours, days, weeks, months, years)
 *
 * @returns {Object} - options object for the chart type
 */
export const getOptions = (chartType, period) => {
  switch (chartType) {
    case 'cpu':
      return cpuOptions(period);
    case 'ram':
      return ramOptions(period);
    case 'namedUsers':
      return userOptions(period);
    case 'shinyConnections':
      return userOptions(period);
  }
};

/**
 * Builds the series settings for each dataset object per chart.
 *
 * @param {Object} object - The series label, fill option and color
 * @param {string} object.label - The label for the series
 * @param {boolean} object.fill - Fill or not fill beneath the line
 * @param {string} object.color - Color for the series
 *
 * @returns {Object} - settings object for the series
 */
const getSeriesSettings = ({ label, fill, color }) => ({
  name: label, // internal - used to process websocket data
  pointRadius: 0, // hides the points in the line
  borderWidth: 1.5, // line thickness
  fill, // fill beneath the line or not
  label: vueI18n.global.t(`admin.metrics.charts.labels.${label}`), // legend
  backgroundColor: color, // fill color
  borderColor: color, // line color
});

/**
 * Transformer for the CPU chart. It calculates the 'misc' series y-coordinates
 * from the 'idle' series and converts any y-coordinate 'NaN' strings to Number.NaN.
 * It also converts all y-coordinate points to percentage values. The x-coordinate
 * represents time in milliseconds and the y-coordinate represents CPU usage as
 * percentage.
 *
 * It receives data series as:
 *  [
 *    { label: 'sys', data: [[1, 0.0021]] },
 *    { label: 'user', data: [[1, 0.0034]] },
 *    { label: 'idle', data: [[1, 0.9671]] },
 *  ]
 * and transforms them to:
 *  {
 *    datasets: [
 *      { data: [{ x: 1, y: 0.21 }], name: 'sys', ...more settings },
 *      { data: [{ x: 1, y: 0.34 }], name: 'user', ...more settings },
 *      { data: [{ x: 1, y: 0.39 }], name: 'idle', ...more settings }, // misc series
 *    ]
 *  }
 *
 * @param {Array} datasets - List of series
 *
 * @returns {Object} - transformed series
 */
const cpuTransformer = datasets => {
  const colors = { sys: '#9b2d27', user: '#f2c144', idle: '#4da74d' };

  return {
    datasets: datasets.map(series => ({
      // converting data from [1, 2] to [{ x: 1, y: 2 }]
      data: series.data.map((item, index) => {
        let yValue = Number(item[1]); // 'NaN' string -> Number.NaN

        // bail from transform for NaN y-values
        if (Number.isNaN(yValue)) {
          return { x: item[0], y: yValue };
        }

        // calculate `misc` data series from `idle`
        if (
          series.label === 'idle' &&
          index in datasets[0].data &&
          index in datasets[1].data
        ) {
          const sys = Number(datasets[0].data[index][1]);
          const user = Number(datasets[1].data[index][1]);
          yValue = Math.max(1 - yValue - (user + sys), 0); // yValue becomes `misc` value
        }

        // the incoming CPU percentage (yValue) is represented as a floating point number
        // between zero and one. We want to display 45.7% when we receive 0.457.
        return { x: item[0], y: yValue * 100 };
      }),
      ...getSeriesSettings({
        label: series.label,
        fill: true,
        color: colors[series.label],
      }),
    })),
  };
};

/**
 * Transformer for the RAM chart. It calculates the 'cached' series y-coordinates
 * from the 'used' series and converts any y-coordinate 'NaN' strings to Number.NaN.
 * The x-coordinate represents time in milliseconds and the y-coordinate represents
 * RAM usage.
 *
 * It receives data series as:
 *  [
 *    { label: 'actualused', data: [[1, 470177382.4]] },
 *    { label: 'used', data: [[1, 1245309883.7]] },
 *  ]
 * and transforms them to:
 *  {
 *    datasets: [
 *      { data: [{ x: 1, y: 470177382.4 }], name: 'actualused', ...more settings },
 *      { data: [{ x: 1, y: 775132501.3 }], name: 'used', ...more settings }, // cached series
 *    ]
 *  }
 *
 * @param {Array} datasets - List of series
 *
 * @returns {Object} - transformed series
 */
const ramTransformer = datasets => {
  const colors = { actualused: '#4682b4', used: '#add8e6' };

  return {
    datasets: datasets.map(series => ({
      // converting data from [1, 2] to [{ x: 1, y: 2 }]
      data: series.data.map((item, index) => {
        let yValue = Number(item[1]); // 'NaN' string -> Number.NaN

        // bail from transform for NaN y-values
        if (Number.isNaN(yValue)) {
          return { x: item[0], y: yValue };
        }

        // calculate `cached` data series from `used`
        if (series.label === 'used' && index in datasets[0].data) {
          const actualused = Number(datasets[0].data[index][1]);
          yValue -= actualused; // yValue becomes `cached` value
        }

        return { x: item[0], y: yValue };
      }),
      ...getSeriesSettings({
        label: series.label,
        fill: true,
        color: colors[series.label],
      }),
    })),
  };
};

/**
 * Transformer for the Named Users and Shiny Connections charts. It converts any
 * y-coordinate 'NaN' strings to Number.NaN. The x-coordinate represents time in
 * milliseconds for both charts and the y-coordinate represents user count for the
 * Named Users chart or active Shiny user count for the Shiny Connections chart.
 *
 * It receives data series as:
 *  [
 *    { label: 'active1day', data: [[1, 3]] },
 *    { label: 'active30day', data: [[1, 2]] },
 *    { label: 'active365day', data: [[1, 5]] },
 *    { label: 'shinyusers', data: [[1, 9]] },
 *  ]
 * and transforms them to:
 *  {
 *    datasets: [
 *      { data: [{ x: 1, y: 3 }], name: 'active1day', ...more settings },
 *      { data: [{ x: 1, y: 2 }], name: 'active30day', ...more settings },
 *      { data: [{ x: 1, y: 5 }], name: 'active365day', ...more settings },
 *      { data: [{ x: 1, y: 9 }], name: 'shinyusers', ...more settings },
 *    ]
 *  }
 *
 * @param {Array} datasets - List of series
 *
 * @returns {Object} - transformed series
 */
const userTransformer = datasets => {
  const colors = {
    active1day: '#1f77b4',
    active30day: '#aec7e8',
    active365day: '#ff7f0e',
    shinyusers: '#1f77b4',
  };

  return {
    datasets: datasets.map(series => ({
      // converting data from [1, 2] to [{ x: 1, y: 2 }]
      data: series.data.map(item => ({
        x: item[0],
        y: Number(item[1]), // 'NaN' string -> Number.NaN
      })),
      ...getSeriesSettings({
        label: series.label,
        fill: false,
        color: colors[series.label],
      }),
    })),
  };
};

/**
 * Applies the appropriate transformer to the data for the chart type
 *
 * @param {string} chartType - The type of chart
 * @param {Array} data - The list of series
 *
 * @returns {Object} - transformed series for the chart type
 */
export const transformData = (chartType, data) => {
  switch (chartType) {
    case 'cpu':
      return cpuTransformer(data);
    case 'ram':
      return ramTransformer(data);
    case 'namedUsers':
      return userTransformer(data);
    case 'shinyConnections':
      return userTransformer(data);
  }
};

/**
 * Builds the request params for the historic data fetch for the chart type.
 * Decides which series to fetch within the specified timeframe.
 *
 * i.e. when called with: ('cpu', { unit: 1, range: 'hours' }), output will be:
 *  {
 *    start: '-3720sec',
 *    end: '-2min', // same for all charts
 *    preserveNan: 1', // same for all charts
 *    xport: ['sys,sys', 'user,user', 'idle,idle'],
 *    data: [
 *      'DEF,sys,system-CPU,sys,AVERAGE',
 *      'DEF,user,system-CPU,user,AVERAGE',
 *      'DEF,idle,system-CPU,idle,AVERAGE',
 *    ],
 *  }
 *
 * @param {string} chartType - The type of chart
 * @param {Object} object - The current timeframe
 * @param {number} object.unit - The timeframe unit (1, 2, 3, 20, etc)
 * @param {string} object.range - The timeframe range (hours, days, weeks, months, years)
 *
 * @returns {Object} - request params for the chart type
 */
export const getHistoricParams = (chartType, { unit, range }) => {
  const charts = {
    cpu: { type: 'system-CPU', series: ['sys', 'user', 'idle'] },
    ram: { type: 'system-RAM', series: ['actualused', 'used'] },
    namedUsers: {
      type: 'license-users',
      series: ['active1day', 'active30day', 'active365day'],
    },
    shinyConnections: { type: 'license-users', series: ['shinyusers'] },
  };

  const { series, type } = charts[chartType];
  const duration = moment.duration(unit, range).asSeconds() + 120;

  const start = `-${duration.toFixed(0)}sec`;
  const xport = series.map(item => `${item},${item}`);
  const data = series.map(item => `DEF,${item},${type},${item},AVERAGE`);

  return { start, end: '-2min', preserveNan: 1, xport, data };
};
