// Copyright (C) 2022 by Posit Software, PBC.

const navigation = {
  goToRStudioWebsite: 'Go to Posit Website',
  proceedToDashboard: `Proceed to @.sys:{'system.displayName'}`,
  menus: {
    users: 'Users',
    groups: 'Groups',
  },
  pagination: {
    temporal: {
      first: 'Newest',
      previous: 'Newer',
      next: 'Older',
      last: 'Oldest',
    },
    fixed: {
      first: 'First',
      previous: 'Previous',
      next: 'Next',
      last: 'Last',
    },
  },
};

export { navigation };
