<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
  This component responsibility is to show the activation token blurb,
  handle websudo re-authentication if needed,
  and display the outcome blurb (success, error, cancelled).
-->

<template>
  <div
    data-automation="activate-ide-token"
  >
    <RestrictedAccessWrapper
      v-slot="{ executeRestrictedApi }"
      :eager="false"
    >
      <h2 class="rsc-lead">
        {{ subtitle }}
      </h2>

      <div
        v-if="isWaitingInput"
      >
        <i18n-t
          tag="p"
          keypath="ideToken.canPublish.details[0]"
        >
          <template #username>
            <span class="emphasize">{{ username }}</span>
          </template>
          <template #package>
            <span class="emphasize">rsconnect</span>
          </template>
        </i18n-t>
        <i18n-t
          tag="p"
          keypath="ideToken.canPublish.details[1]"
        >
          <template #code>
            <code>rsconnect::connectUser</code>
          </template>
        </i18n-t>
        <div class="token-actions">
          <RSButton
            type="secondary"
            data-automation="cancel-token-btn"
            :label="$t('ideToken.actions.cancel')"
            @click.prevent="cancelToken"
          />
          <RSButton
            data-automation="activate-token-btn"
            :label="$t('ideToken.actions.connect')"
            @click.prevent="() => activateToken(executeRestrictedApi)"
          />
        </div>
      </div>

      <!-- Connecting Success -->
      <div
        v-if="tokenActivated"
        data-automation="activation-success-blurb"
      >
        <i18n-t
          tag="p"
          keypath="ideToken.connection.success.details[0]"
        >
          <template #package>
            <span class="emphasize">rsconnect</span>
          </template>
        </i18n-t>
        <i18n-t
          tag="p"
          keypath="ideToken.connection.success.details[1]"
        >
          <template #docslink>
            <a
              target="_blank"
              :href="publishingDocs"
            >
              {{ $t('ideToken.connection.success.linkLabel') }}
            </a>
          </template>
        </i18n-t>
      </div>

      <!-- Connecting Failed -->
      <div
        v-if="activationFailed"
        data-automation="activation-failed-blurb"
      >
        <p>
          {{ $t('ideToken.connection.failed.details') }}
        </p>
      </div>

      <!-- Connection Cancelled -->
      <div
        v-if="activationCancelled"
        data-automation="activation-cancelled-blurb"
      >
        <i18n-t
          tag="p"
          keypath="ideToken.connection.cancelled.details"
        >
          <template #docslink>
            <a
              target="_blank"
              :href="connectingDocs"
            >
              {{ $t('ideToken.connection.cancelled.linkLabel') }}
            </a>
          </template>
        </i18n-t>
      </div>
    </RestrictedAccessWrapper>
  </div>
</template>

<script>
import { docsPath } from '@/utils/paths';
import { connectUserToken } from '@/api/users';
import RestrictedAccessWrapper, {
  ReauthenticationInProgressError,
} from '@/components/RestrictedAccessWrapper';
import RSButton from 'Shared/components/RSButton';

const TOKEN_WAITING = 'waiting';
const TOKEN_FAILED = 'failed';
const TOKEN_ACTIVATED = 'activated';
const TOKEN_CANCELLED = 'cancelled';

export default {
  name: 'ActivateToken',
  components: {
    RSButton,
    RestrictedAccessWrapper,
  },
  props: {
    username: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tokenStatus: TOKEN_WAITING,
    };
  },
  computed: {
    subtitle() {
      switch (this.tokenStatus) {
        case TOKEN_FAILED:
          return this.$t('ideToken.connection.failed.title');
        case TOKEN_ACTIVATED:
          return this.$t('ideToken.connection.success.title');
        case TOKEN_CANCELLED:
          return this.$t('ideToken.connection.cancelled.title');
        default:
          return this.$t('ideToken.canPublish.title');
      }
    },
    isWaitingInput() {
      return this.tokenStatus === TOKEN_WAITING;
    },
    activationCancelled() {
      return this.tokenStatus === TOKEN_CANCELLED;
    },
    activationFailed() {
      return this.tokenStatus === TOKEN_FAILED;
    },
    tokenActivated() {
      return this.tokenStatus === TOKEN_ACTIVATED;
    },
    publishingDocs() {
      return docsPath('user/publishing-overview');
    },
    connectingDocs() {
      return docsPath('user/connecting');
    },
  },
  methods: {
    cancelToken() {
      this.tokenStatus = TOKEN_CANCELLED;
    },
    activateToken(executeRestrictedApi) {
      executeRestrictedApi(connectUserToken(this.token))
        .then(() => {
          this.tokenStatus = TOKEN_ACTIVATED;
          return TOKEN_ACTIVATED;
        })
        .catch(err => {
          if (!(err instanceof ReauthenticationInProgressError)) {
            this.tokenStatus = TOKEN_FAILED;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.token-actions {
  padding: 1rem 0;
  text-align: right;

  & > * {
    margin: 0 0.5rem;
  }
}
</style>
