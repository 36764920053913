<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
    LicenseExpiration

    Renders the license expiration view.
  -->
<template>
  <div
    v-if="display"
    class="broadcastMessage"
  >
    <span class="license-expiration-message">{{ message }}</span>
    <i18n-t
      keypath="notices.licensing.contact"
      tag="span"
    >
      <template #contactLink>
        <!-- <a> tag must remain on one line to avoid whitespace in the rendered output. -->
        <a :href="salesEmailLink">{{ salesEmail }}</a>
      </template>
    </i18n-t>
    <button
      class="messageClose"
      @click="dismiss"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import {
  SERVER_NOTICES_LICENSING_CLOSE
} from '@/store/modules/serverNotices';

import { SALES_EMAIL } from '@/constants/contacts';

import moment from 'moment-mini';

const notificationWindowDays = 15;

export default {
  name: 'LicenseExpiration',
  props: {
    server: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      salesEmail: SALES_EMAIL,
    };
  },
  computed: {
    ...mapState({
      active: state => state.serverNotices.licensing,
    }),
    salesEmailLink() {
      return `mailto:${this.salesEmail}`;
    },
    license() {
      return this.server.license || {};
    },
    daysLeft() {
      return this.license.daysLeft || 0;
    },
    licenseType() {
      if (this.license.status === 'evaluation') {
        return 'trial';
      }
      return 'license';
    },
    enabled() {
      // The server prevents display of this information by including
      // licensing information and indicating that the UI warning is enabled
      // only for publishers and administrators.
      return this.server.licenseExpirationUiWarning === true;
    },
    expired() {
      return this.daysLeft <= 0;
    },
    expiring() {
      return this.daysLeft <= notificationWindowDays;
    },
    display() {
      return this.enabled && this.active && (this.expired || this.expiring);
    },
    message() {
      if (this.expired) {
        return `${this.$t('notices.licensing.expired')} `;
      } else if (this.expiring) {
        const expiresWhen = moment().add(this.daysLeft, 'days');
        return `${this.$t('notices.licensing.expiring', {
          licenseType: this.licenseType,
          expiresWhen: expiresWhen.fromNow(),
        })} `;
      }
      // The "pending" message should never be shown.
      return `${this.$t('notices.licensing.pending')} `;
    }
  },
  methods: {
    ...mapMutations({
      dismiss: SERVER_NOTICES_LICENSING_CLOSE,
    }),
  },
};
// server-notices; serversettings as a prop to to the two types of messages.
</script>
