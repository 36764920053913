<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel__download-example wizard-panel--white">
    <div class="wizard-panel__content">
      <StepHeading :step="step">
        {{ $t('publish.downloadExample') }}
      </StepHeading>

      <div class="wizard-panel__download-example-tile">
        <div class="wizard-panel__download-example-tile-summary">
          <!-- NOTE: the following is nearly identical to what is in
            src/views/content/JumpStart/Example.vue
          -->
          <img
            alt=""
            :src="copyFrom.thumbnailUrl"
            class="wizard-panel__download-example-tile-thumbnail"
          >
          <div class="wizard-panel__download-example-tile-details">
            <div class="wizard-panel__download-example-tile-title">
              {{ copyFrom.title }}
            </div>
            <div class="wizard-panel__download-example-tile-type">
              {{ typeString }}
            </div>
          </div>
        </div>
      </div>

      <div class="wizard-panel__download-example-button">
        <a
          :href="copyFrom.downloadUrl"
          class="rs-button primary"
        >
          <span>{{ $t('publish.downloadLabel') }} {{ copyFrom.name }}.zip</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import PanelMixin from '../PanelMixin';
import StepHeading from '@/views/content/PublishWizard/StepHeading';

export default {
  name: 'DownloadExample',
  components: { StepHeading },
  mixins: [PanelMixin],
};
</script>

<style lang="scss" scoped>
@import 'connect-elements/src/styles/shared/_colors';

.wizard-panel {
  &__download-example {
    background-position: center bottom;
    background-repeat: no-repeat;

    &-tile {
      font-size: 14px;
      padding: 0;
      background-color: transparent;
      margin: 10px 18px 3px 18px;
      border: 2px solid $color-dialog-background;
      border-radius: 3px;
      cursor: pointer;
      text-align: center;

      &-summary {
        display: inline-block;
        width: 240px;
      }

      &-thumbnail {
        max-width: 240px;
        height: 135px;
        background-size: cover;
        background-color: $color-medium-grey;
      }

      &-details {
        padding: 1rem;
        background-color: $color-light-grey;
        * {
          background-color: $color-light-grey;
        }
      }

      &-title {
        margin-bottom: 5px;
        line-height: 1.4em;
      }

      &-type {
        font-size: 0.85em;
      }
    }

    &-button {
      padding-top: 1rem;
      text-align: center;

      .rs-button {
        min-width: 8.75rem;
        margin: 0;
        padding: 0.7rem 1.1rem 0.7rem 40px;
        line-height: 1rem;
        overflow: visible;
        border: none;
        border-radius: 3px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
        cursor: pointer;
        transition-duration: 250ms;
        text-align: center;
        color: $color-white;
        background-color: $color-primary;
        background-image: url(connect-elements/src/images/actionDownloadWhite.svg);
        background-position: 8px center;
        background-repeat: no-repeat;
        background-size: 25px 25px;
        font-size: 13px;
        &:hover {
          text-decoration: none;
        }

        span {
          background-color: $color-primary;
        }
      }
    }
  }
}
</style>
