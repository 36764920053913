<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="content-url">
    <div class="formSection">
      <div
        v-if="canCustomize"
        class="textInputContainer content-url__custom-url"
      >
        <div class="content-url__custom-url-label mb">
          {{ $t('appSettings.access.customUrl.label') }}
        </div>
        <RSInputText
          :value="vanityPath"
          :disabled="!canEditSettings"
          label="custom-content-url"
          :show-label="false"
          :placeholder="$t('appSettings.access.customUrl.placeholder')"
          name="custom-content-url"
          data-automation="content-vanity-path"
          @input="handleInput"
        />
      </div>
      <div class="content-url__custom-url-label">
        {{ $t('appSettings.access.customUrl.effectiveURL') }}
      </div>
      <div
        class="textInputContainer content-url__full-url"
      >
        <div
          ref="fullUrlText"
          role="link"
          :full-url="fullUrl"
          class="content-url__full-url-text"
          aria-label="content-url"
          tabindex="0"
          data-automation="content-vanity-url"
          @click="selectText"
          @keyup.space="selectText"
        >
          {{ fullUrl }}
        </div>
        <CopyButton
          class="smallTextButton content-url__copy-url"
          data-automation="copy-vanity-url"
          :copy-text="fullUrl"
        />
      </div>
    </div>
  </div>
</template>

<script>

import RSInputText from 'Shared/components/RSInputText';
import { contentURL, serverURL } from '@/utils/paths';
import CopyButton from '@/components/CopyButton.vue';

export default {
  name: 'ContentUrl',
  components: {
    RSInputText,
    CopyButton
  },
  props: {
    appGuid: {
      type: String,
      required: true,
    },
    vanityPath: {
      type: String,
      default: '',
    },
    canCustomize: {
      type: Boolean,
      default: false,
    },
    canEditSettings: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showCopied: false,
    };
  },
  computed: {
    fullUrl() {
      return this.vanityPath === '' ? contentURL(this.appGuid) : serverURL(`${this.vanityPath }/`);
    },
  },
  methods: {
    selectText(e) {
      const input = e.target;
      window.getSelection().selectAllChildren(input);
    },
    handleInput(value) {
      this.$emit('update', value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'connect-elements/src/styles/shared/_colors';
@import 'connect-elements/src/styles/shared/_mixins';

.content-url {
  &__custom-url {
    padding-top: 0;

    &-label {
      font-size: 0.9rem;
      line-height: 1.5rem;
      color: #333;
      margin-top: 0.4rem;
    }
  }

  &__full-url {
    display: flex;
    align-items: center;
    justify-content: space-between;

    border: 1px solid #c8c8c8;
    width: 100%;
    max-width: 100%;
    padding: 0 0.6rem 0.4rem 0.6rem;
    background-color: #fff;
    color: rgb(89, 88, 88);
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    margin-top: 0.2rem;

    &-text {
      font-size: 13px;
      line-height: 16px;
      margin-top: 0.5rem;
      min-width: 0;
      overflow-wrap: break-word;
      @include control-visible-focus();
    }
  }

  &__copy-url {
    margin: 5px 0 0 5px;
    padding: 2px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    background-color: $color-button-background;
    transition-property: background-color;
    transition-duration: 0.25s;
    color: $color-button-text;
    font-size: 13px;
    line-height: 30px;
    min-width: unset;

    &:hover {
      background-color: $color-button-background-hover;
      text-decoration: none;
    }
  }
}
.mb {
  margin-bottom: 0.25rem;
}
</style>
