<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div
    class="mt-5"
    :class="{ listBorder: dialogMode }"
  >
    <EnvironmentsListHeader
      ref="EnvironmentsListHeader"
      :readonly="readonly"
      :suppress-title="dialogMode"
      @add-environment="onClickAddEnvironment"
      @filter="onFilterChange"
    />
    <div
      :class="listClass"
    >
      <EnvironmentsTable
        v-if="postFilterEnvironments.length > 0"
        :environments="postFilterEnvironments"
        :active-sort="activeSort"
        :readonly="readonly"
        :dialog-mode="dialogMode"
        :currently-selected-guid="currentlySelectedGuid"
        @delete="onClickDeleteEnvironment"
        @edit="onClickEditEnvironment"
        @sort="onSortChange"
        @click="onRowClick"
      />
      <EmptyEnvironmentsList
        v-if="postFilterEnvironments.length === 0 && initializationComplete"
        :filter="filterEnvironmentstr"
        @reset="resetFilter"
      />
    </div>
    <EnvironmentAddOrEditDialog
      v-if="showEnvironmentAddOrEditDialog"
      ref="EnvironmentAddOrEditDialog"
      data-automation="environment-add-or-edit-dialog"
      :environment="activeEnvironment"
      @close="toggleEnvironmentAddOrEditDialog"
      @add="onProcessAddEnvironment"
      @edit="onProcessEditEnvironment"
    />
    <EnvironmentDeleteDialog
      v-if="showEnvironmentDeleteDialog"
      data-automation="environment-delete-dialog"
      :environment="activeEnvironment"
      @close="toggleEnvironmentDeleteDialog"
      @delete="onProcessDeleteEnvironment"
    />
  </div>
</template>

<script>
import Vue from 'vue';

import EnvironmentsTable from './EnvironmentsTable';
import EmptyEnvironmentsList from './EmptyEnvironmentsList';
import EnvironmentsListHeader from './EnvironmentsListHeader';
import EnvironmentAddOrEditDialog, { ENVIRONMENT_ADD_OR_EDIT_DIALOG_FIELDS_ENUM } from './EnvironmentAddOrEditDialog';
import EnvironmentDeleteDialog from './EnvironmentDeleteDialog';

import ApiErrors from '@/api/errorCodes';
import {
  getEnvironments,
  addEnvironment,
  deleteEnvironment,
  updateEnvironment,
} from '@/api/environments';

import { ReauthenticationInProgressError } from '@/components/RestrictedAccessWrapper';
import { mapMutations, mapActions } from 'vuex';
import {
  SHOW_INFO_MESSAGE,
  SET_ERROR_MESSAGE_FROM_API,
} from '@/store/modules/messages';

export default {
  name: 'EnvironmentList',
  components: {
    EnvironmentsTable,
    EmptyEnvironmentsList,
    EnvironmentsListHeader,
    EnvironmentAddOrEditDialog,
    EnvironmentDeleteDialog,
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    executeRestrictedApi: {
      type: Function,
      default: () => Promise.reject('invalid executeRestrictedApi'),
    },
    dialogMode: {
      type: Boolean,
      default: false,
    },
    currentlySelectedGuid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      postFilterEnvironments: [],
      environments: [],
      activeSort: {
        key: 'Title',
        direction: 'asc',
      },
      activeEnvironment: {},
      showEnvironmentAddOrEditDialog: false,
      showEnvironmentDeleteDialog: false,
      filterEnvironmentstr: '',
      nextInstallationKey: 0,
      initializationComplete: false,
      // testability attributes (not affect functionality)
      initializePromise: null,
      environmentAddedPromise: null,
      environmentDeletedPromise: null,
      environmentUpdatedPromise: null,

    };
  },
  computed: {
    listClass() {
      return this.dialogMode ? 'environment-list__dialog' : 'environment-list__main__content';
    }
  },
  watch: {
    currentlySelectedGuid() {
      this.updateVisibilityStatusForSelection();
    },
    postFilterEnvironments() {
      this.updateVisibilityStatusForSelection();
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    ...mapActions({
      setInfoMessage: SHOW_INFO_MESSAGE,
    }),
    init() {
      this.initializePromise = getEnvironments()
        .then(environments => {
          this.environments = environments;
          this.$emit('environment-count', this.environments.length);

          this.environments.forEach(environment => {
            this.updateInternalAttributes(environment);
          });
          this.filterEnvironments();
          this.initializationComplete = true;
        })
        .catch(err => {
          this.setErrorMessageFromAPI(err);
        });
    },
    updateVisibilityStatusForSelection() {
      if (this.currentlySelectedGuid === '') {
        this.$emit('selectionVisible', false);
      } else {
        const selected = this.postFilterEnvironments.find(
          env => env.guid === this.currentlySelectedGuid
        );
        if (selected === undefined) {
          this.$emit('selectionVisible', false);
        } else {
          this.$emit('selectionVisible', true);
        }
      }
    },
    updateInternalAttributes(environment) {
      // add keys to the installations if they are not present
      environment.installations.forEach(installation => {
        if (installation.key === undefined || installation.key === -1) {
          Vue.set(installation, 'id', this.nextInstallationKey++);
        }
      });

      // always regenerate the installation strings
      Vue.set(environment, 'installationStrings', {
        Python: {
          asc: '',
          desc: '',
        },
        Quarto: {
          asc: '',
          desc: '',
        },
        R: {
          asc: '',
          desc: '',
        },
      });
      const installationTypes = ['Python', 'Quarto', 'R'];
      installationTypes.forEach(installationType => {
        const versions = [];
        environment.installations.forEach(installation => {
          if (installation.type === installationType) {
            versions.push(installation.version);
          }
        });
        versions.sort(this.sortVersions);
        const versionStrings = [];
        versions.forEach(version => {
          versionStrings.push(`${version.major}.${version.minor}.${version.patch}`);
        });
        Vue.set(environment.installationStrings, installationType, {
          asc: versionStrings.join(', '),
          desc: versionStrings.reverse().join(', '),
        });
      });
    },
    // depends on {
    //   major: A,
    //   minor: B,
    //   patch: C,
    // }
    sortVersions(a, b) {
      if (a.major > b.major) {
        return 1;
      }
      if (a.major < b.major) {
        return -1;
      }
      if (a.minor > b.minor) {
        return 1;
      }
      if (a.minor < b.minor) {
        return -1;
      }
      if (a.patch > b.patch) {
        return 1;
      }
      if (a.patch < b.patch) {
        return -1;
      }
      return 0;
    },
    onSortChange(options) {
      this.activeSort.key = options.key;
      this.activeSort.direction = options.direction;
      this.filterEnvironments();
    },
    onFilterChange(filter) {
      this.filterEnvironmentstr = filter;
      this.filterEnvironments();
    },
    onClickAddEnvironment() {
      this.activeEnvironment = {};
      this.toggleEnvironmentAddOrEditDialog();
    },
    onClickEditEnvironment(environment) {
      this.activeEnvironment = environment;
      this.toggleEnvironmentAddOrEditDialog();
    },
    onClickDeleteEnvironment(environment) {
      this.activeEnvironment = environment;
      this.toggleEnvironmentDeleteDialog();
    },
    toggleEnvironmentAddOrEditDialog() {
      this.showEnvironmentAddOrEditDialog = !this.showEnvironmentAddOrEditDialog;
    },
    toggleEnvironmentDeleteDialog() {
      this.showEnvironmentDeleteDialog = !this.showEnvironmentDeleteDialog;
    },
    async onProcessAddEnvironment(environment) {
      this.environmentAddedPromise = this.processEnvironmentAdded(environment)
        .then(() => {
          this.toggleEnvironmentAddOrEditDialog();
          this.activeEnvironment = {};
          this.$emit('environment-count', this.environments.length);
        })
        .catch(errObj => {
          // if we haven't handled the error, then show something
          // and close the dialog. If we have handled, keep it open.
          if (!errObj.handled) {
            this.toggleEnvironmentAddOrEditDialog();
            this.activeEnvironment = {};
            this.setErrorMessageFromAPI(errObj.err);
          }
        });
    },
    onProcessEditEnvironment(environment) {
      this.environmentUpdatedPromise = this.processEnvironmentUpdate(environment)
        .then(() => {
          this.toggleEnvironmentAddOrEditDialog();
          this.activeEnvironment = {};
        })
        .catch(errObj => {
          // if we haven't handled the error, then show something
          // and close the dialog. If we have handled, keep it open.
          if (!errObj.handled) {
            this.toggleEnvironmentAddOrEditDialog();
            this.activeEnvironment = {};
            this.setErrorMessageFromAPI(errObj.err);
          }
        });
    },
    onProcessDeleteEnvironment(environment) {
      this.environmentDeletedPromise = this.processEnvironmentDelete(environment)
        .then(() => {
          this.toggleEnvironmentDeleteDialog();
          this.activeEnvironment = {};
          this.$emit('environment-count', this.environments.length);
        })
        .catch(errObj => {
          // if we haven't handled the error, then show something
          // and close the dialog. If we have handled, keep it open.
          if (!errObj.handled) {
            this.toggleEnvironmentDeleteDialog();
            this.activeEnvironment = {};
            this.setErrorMessageFromAPI(errObj.err);
          }
        });
    },
    updateEnvironmentsort(activeSort) {
      this.activeSort = activeSort;
    },
    processEnvironmentAdded(environment) {
      return this.executeRestrictedApi(
        addEnvironment(environment)
      )
        .then(returnedEnvironment => {
          this.updateInternalAttributes(returnedEnvironment);
          this.environments.push(returnedEnvironment);

          this.setInfoMessage({
            message: this.$t(
              'executionEnvironments.actionMessages.added',
              { title: `${returnedEnvironment.title}` }
            ),
          });
          this.filterEnvironments();
        })
        .catch(err => {
          Object.assign(err, { handled: false });
          if (err instanceof ReauthenticationInProgressError) {
            // notification that the credential dialog has been activated
            // and promise chain is being rejected.
            //
            // consider this error as handled, as this will keep the dialog
            // up for easier resubmission
            err.handled = true;
          } else if (
            // protect against unknown errors
            err.response &&
            err.response.data
          ) {
            const errorField = this.mapErrorToField(err.response.data);

            // Allow dialog to handle server error
            if (this.$refs.EnvironmentAddOrEditDialog.onServerError(errorField)) {
              err.handled = true;
            }
          }
          // re-thrown the error for upstream processing w/ handled attribute
          throw (err);
        });
    },
    mapErrorToField(responseData) {
      const errorField = {
        field: null,
        code: responseData.code,
        msg: responseData.error,
        args: responseData.payload,
      };
      if (responseData.code === ApiErrors.DuplicateEnvironmentName) {
        errorField.field = ENVIRONMENT_ADD_OR_EDIT_DIALOG_FIELDS_ENUM.ENVIRONMENT_NAME;
      }
      return errorField;
    },
    processEnvironmentUpdate(environment) {
      return this.executeRestrictedApi(
        updateEnvironment(environment)
      )
        .then(returnedEnvironment => {
          this.updateInternalAttributes(returnedEnvironment);

          const ndx = this.environments.findIndex(item => item.guid === environment.guid);
          const existingItem = this.environments[ndx];
          existingItem.guid = returnedEnvironment.guid;
          existingItem.createdTime = returnedEnvironment.createdTime;
          existingItem.updatedTime = returnedEnvironment.updatedTime;
          existingItem.title = returnedEnvironment.title;
          existingItem.description = returnedEnvironment.description;
          existingItem.clusterName = returnedEnvironment.clusterName;
          existingItem.environmentType = returnedEnvironment.environmentType;
          existingItem.name = returnedEnvironment.name;
          existingItem.matching = returnedEnvironment.matching;
          existingItem.supervisor = returnedEnvironment.supervisor;
          existingItem.installations = returnedEnvironment.installations;
          existingItem.installationStrings = returnedEnvironment.installationStrings;
          Vue.set(this.environments, ndx, existingItem);

          this.setInfoMessage({
            message: this.$t(
              'executionEnvironments.actionMessages.updated',
              { title: `${returnedEnvironment.title}` }
            ),
          });
          this.filterEnvironments();
        })
        .catch(err => {
          Object.assign(err, { handled: false });
          if (err instanceof ReauthenticationInProgressError) {
            // notification that the credential dialog has been activated
            // and promise chain is being rejected.
            //
            // consider this error as handled, as this will keep the dialog
            // up for easier resubmission
            err.handled = true;
          } else if (
            // protect against unknown errors
            err.response &&
            err.response.data
          ) {
            const errorField = this.mapErrorToField(err.response.data);

            // Allow dialog to handle server error
            if (this.$refs.EnvironmentAddOrEditDialog.onServerError(errorField)) {
              err.handled = true;
            }
          }
          // re-thrown the error for upstream processing w/ handled attribute
          throw (err);
        });
    },
    processEnvironmentDelete(targetEnvironment) {
      return this.executeRestrictedApi(
        deleteEnvironment(targetEnvironment.guid)
      )
        .then(() => {
          this.setInfoMessage({
            message: this.$t(
              'executionEnvironments.actionMessages.deleted',
              { title: `${targetEnvironment.title}` }
            ),
          });
          this.environments = this.environments.filter(environment => {
            return environment.guid !== targetEnvironment.guid;
          });
          this.filterEnvironments();
        })
        .catch(err => {
          Object.assign(err, { handled: false });
          if (err instanceof ReauthenticationInProgressError) {
            // notification that the credential dialog has been activated
            // and promise chain is being rejected.
            //
            // consider this error as handled, as this will keep the dialog
            // up for easier resubmission
            err.handled = true;
          }
          // re-thrown the error for upstream processing w/ handled attribute
          throw (err);
        });
    },
    filterEnvironments() {
      // filter first
      let result = [];
      if (this.filterEnvironmentstr === '') {
        result = this.environments;
      } else {
        const lcFilter = this.filterEnvironmentstr.toLowerCase();
        this.environments.forEach(environment => {
          if (environment.title.toLowerCase().includes(lcFilter)) {
            result.push(environment);
          } else if (environment.guid.toLowerCase().includes(lcFilter)) {
            result.push(environment);
          } else if (environment.description.toLowerCase().includes(lcFilter)) {
            result.push(environment);
          } else if (environment.name.toLowerCase().includes(lcFilter)) {
            result.push(environment);
          } else if (environment.supervisor.toLowerCase().includes(lcFilter)) {
            result.push(environment);
          }
        });
      }

      // then sort
      const key = this.activeSort.key;
      if (key === 'Title') {
        result.sort((a, b) => {
          // case insensitive sorting of title
          const aTitle = a.title.toLowerCase();
          const bTitle = b.title.toLowerCase();

          if (aTitle > bTitle) {
            return 1;
          }
          if (aTitle < bTitle) {
            return -1;
          }
          return 0;
        });
        if (this.activeSort.direction === 'desc') {
          result.reverse();
        }
      } else if (key === 'Python' || key === 'Quarto' || key === 'R') {
        result.sort((a, b) => {
          if (this.activeSort.direction === 'desc') {
            if (a.installationStrings[key].desc === '' && b.installationStrings[key].desc !== '') {
              return -1;
            }
            if (a.installationStrings[key].desc !== '' && b.installationStrings[key].desc === '') {
              return 1;
            }
            if (a.installationStrings[key].desc > b.installationStrings[key].desc) {
              return 1;
            }
            if (a.installationStrings[key].desc < b.installationStrings[key].desc) {
              return -1;
            }
          } else {
            if (a.installationStrings[key].asc === '' && b.installationStrings[key].asc !== '') {
              return -1;
            }
            if (a.installationStrings[key].asc !== '' && b.installationStrings[key].asc === '') {
              return 1;
            }
            if (a.installationStrings[key].asc > b.installationStrings[key].asc) {
              return 1;
            }
            if (a.installationStrings[key].asc < b.installationStrings[key].asc) {
              return -1;
            }
          }
          return 0;
        });

        // can't just use reverse, we want to always preserve 'None' at the bottom
        const nonNoneResults = [];
        const noneResults = [];
        result.forEach(environment => {
          if (environment.installationStrings[key].desc !== '') {
            nonNoneResults.push(environment);
          } else {
            noneResults.push(environment);
          }
        });
        if (this.activeSort.direction === 'desc') {
          nonNoneResults.reverse();
        }
        result = nonNoneResults;
        noneResults.forEach(environment => result.push(environment));
      }
      this.postFilterEnvironments = result;
    },
    resetFilter() {
      this.$refs.EnvironmentsListHeader.clearTextSearch();
    },
    onRowClick(environment) {
      this.$emit('select', environment);
    }
  },
};
</script>
<style lang="scss" scoped>
@import 'connect-elements/src/styles/shared/_colors';

.mt-5 {
  margin-top: 0.5rem;
}
.listBorder {
  border: solid $color-dark-grey-3 1px;
  padding: 5px;
}
.environment-list {
  &__main__content {
    flex: 1;
    overflow-y: unset;
    margin-bottom: 2rem;
  }
  &__dialog {
    flex: 1;
    overflow-y: unset;
  }
}
</style>
