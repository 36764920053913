<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div class="container">
    <div class="detail__row">
      <div class="detail__section-title">
        {{ $t('executionEnvironments.detailed.titles.description') }}
      </div>
      <div class="detail__divider" />
    </div>
    <div
      v-if="!environment.description"
      class="detail__row description"
    >
      <!-- To have this entry match padding/margin/etc w/ the multi-line
           text below, we need to mimic the same div levels -->
      <!-- eslint-disable vue/singleline-html-element-content-newline -->
      <div class="description__empty">{{ $t('executionEnvironments.detailed.empty.description') }}</div>
      <!-- eslint-enable vue/singleline-html-element-content-newline -->
    </div>
    <div
      v-if="environment.description"
      class="detail__row description"
    >
      <!-- maintaining whitespace in class, need to be very careful
           with this html rendering. normal html formatting causes
           extra whitespace to be inserted, so need to keep description
           string packed into html node. (Disabling linting rule to
           allow this to occur without error.) -->
      <!-- eslint-disable vue/singleline-html-element-content-newline -->
      <div class="description__multiline_text">{{ environment.description }}</div>
      <!-- eslint-enable vue/singleline-html-element-content-newline -->
    </div>
    <div class="detail__row installations-section">
      <div class="detail__section-title">
        {{ $t('executionEnvironments.detailed.titles.installations') }}
      </div>
      <div class="detail__divider" />
    </div>
    <div
      v-if="!anyInstallations"
      class="detail__row installation-list-container install-warning"
      :title="$t('executionEnvironments.detailed.tooltips.incomplete')"
      data-automation="incomplete-warning"
    >
      {{ $t('executionEnvironments.detailed.empty.installations') }}
    </div>
    <div
      v-if="anyPythonInstallations"
      class="detail__row installation-list-container"
    >
      <img
        src="/images/python-logo-generic.svg"
        alt="Docker container environment"
        width="100"
        class="python-logo"
      >
      <ul
        class="installation-list"
      >
        <li
          v-for="install in getPythonInstallations"
          :key="install.guid"
          class="installation-entry"
        >
          <div class="installation-entry__container">
            <div class="installation-entry__version">
              {{ 'v' + install.version.major + '.' + install.version.minor + '.' + install.version.patch }}
            </div>
            <div class="installation-entry__path">
              {{ install.path }}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div
      v-if="anyQuartoInstallations"
      class="detail__row installation-list-container"
    >
      <img
        src="/images/logoQuarto.png"
        alt="Docker container environment"
        width="100"
        class="quarto-logo"
      >
      <ul
        class="installation-list"
      >
        <li
          v-for="install in getQuartoInstallations"
          :key="install.guid"
          class="installation-entry"
        >
          <div class="installation-entry__container">
            <div class="installation-entry__version">
              {{ 'v' + install.version.major + '.' + install.version.minor + '.' + install.version.patch }}
            </div>
            <div class="installation-entry__path">
              {{ install.path }}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div
      v-if="anyRInstallations"
      class="detail__row installation-list-container"
    >
      <img
        src="/images/logoR.png"
        alt="Docker container environment"
        width="100"
        class="r-logo"
      >
      <ul
        class="installation-list"
      >
        <li
          v-for="install in getRInstallations"
          :key="install.guid"
          class="installation-entry"
        >
          <div class="installation-entry__container">
            <div class="installation-entry__version">
              {{ 'v' + install.version.major + '.' + install.version.minor + '.' + install.version.patch }}
            </div>
            <div class="installation-entry__path">
              {{ install.path }}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="detail__row mt-1rem">
      <div class="detail__section-title">
        {{ $t('executionEnvironments.detailed.titles.advanced') }}
      </div>
      <div class="detail__divider" />
    </div>
    <div class="detail__row title-value-row">
      <div class="title-value-row__title">
        {{ $t('executionEnvironments.detailed.labels.matching') }}
      </div>
      <div class="title-value-row__value">
        {{ environment.matching }}
      </div>
    </div>
    <div class="detail__row title-value-row">
      <div class="title-value-row__title">
        {{ $t('executionEnvironments.detailed.labels.supervisor') }}
      </div>
      <div class="title-value-row__value">
        {{ supervisorScriptPath }}
      </div>
    </div>
    <div class="detail__row attributes-section">
      <div class="detail__section-title">
        {{ $t('executionEnvironments.detailed.titles.attributes') }}
      </div>
      <div class="detail__divider" />
    </div>
    <div class="detail__row title-value-row">
      <div class="title-value-row__title">
        {{ $t('executionEnvironments.detailed.labels.updated') }}
      </div>
      <div class="title-value-row__value">
        {{ updatedTime }}
      </div>
    </div>
    <div class="detail__row title-value-row">
      <div class="title-value-row__title">
        {{ $t('executionEnvironments.detailed.labels.guid') }}
      </div>
      <div class="title-value-row__value">
        {{ environment.guid }}
        <button
          class="action-button"
          @click="copyGUID(environment.guid)"
        >
          <img
            src="/images/copy.svg"
            :alt="$t('executionEnvironments.detailed.actions.copyGuid.altText')"
            :title="$t('executionEnvironments.detailed.actions.copyGuid.title')"
            class="copy-guid copy-icon"
          >
        </button>
      </div>
    </div>
    <div class="detail__row title-value-row">
      <div class="title-value-row__title">
        {{ $t('executionEnvironments.detailed.labels.imageName') }}
      </div>
      <div class="title-value-row__value">
        {{ environment.name }}
        <button
          class="action-button"
          @click="copyEnvironmentName(environment.name)"
        >
          <img
            src="/images/copy.svg"
            :alt="$t('executionEnvironments.detailed.actions.copyName.altText')"
            :title="$t('executionEnvironments.detailed.actions.copyName.title')"
            class="copy-guid copy-icon"
          >
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-mini';
import { copyToClipboard } from '@/utils/clipboard';
import { mapActions } from 'vuex';
import { SHOW_INFO_MESSAGE } from '@/store/modules/messages';

export default {
  name: 'EnvironmentsTableRowDetails',
  props: {
    environment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    createdTime() {
      const timestamp = moment.utc(this.environment.createdTime)
        .local()
        .format('YYYY/MM/DD h:mm:ss A');
      return timestamp;
    },
    updatedTime() {
      const timestamp = moment.utc(this.environment.updatedTime)
        .local()
        .format('YYYY/MM/DD h:mm:ss A');
      return timestamp;
    },
    anyInstallations() {
      return (
        this.anyQuartoInstallations ||
        this.anyPythonInstallations ||
        this.anyRInstallations
      );
    },
    anyQuartoInstallations() {
      return this.getQuartoInstallations.length > 0;
    },
    getQuartoInstallations() {
      const filtered = this.environment.installations.filter(install => install.type === 'Quarto');
      return filtered.sort(this.sortVersions).reverse();
    },
    anyPythonInstallations() {
      return this.getPythonInstallations.length > 0;
    },
    getPythonInstallations() {
      const filtered = this.environment.installations.filter(install => install.type === 'Python');
      return filtered.sort(this.sortVersions).reverse();
    },
    anyRInstallations() {
      return this.getRInstallations.length > 0;
    },
    getRInstallations() {
      const filtered = this.environment.installations.filter(install => install.type === 'R');
      return filtered.sort(this.sortVersions).reverse();
    },
    supervisorScriptPath() {
      if (this.environment.supervisor) {
        return this.environment.supervisor;
      }
      return this.$t('common.none');
    }
  },
  methods: {
    ...mapActions({
      setInfoMessage: SHOW_INFO_MESSAGE,
    }),
    async copyGUID(guid) {
      await copyToClipboard({ value: guid });
      this.setInfoMessage({
        message: this.$t('executionEnvironments.detailed.actions.copyGuid.successMessage'),
        autoHide: false,
      });
    },
    async copyEnvironmentName(guid) {
      await copyToClipboard({ value: guid });
      this.setInfoMessage({
        message: this.$t('executionEnvironments.detailed.actions.copyName.successMessage'),
        autoHide: false,
      });
    },
    sortVersions(a, b) {
      if (a.version.major > b.version.major) {
        return 1;
      }
      if (a.version.major < b.version.major) {
        return -1;
      }
      if (a.version.minor > b.version.minor) {
        return 1;
      }
      if (a.version.minor < b.version.minor) {
        return -1;
      }
      if (a.version.patch > b.version.patch) {
        return 1;
      }
      if (a.version.patch < b.version.patch) {
        return -1;
      }
      return 0;
    },
  }
};
</script>

<style lang="scss" scoped>
@import 'connect-elements/src/styles/shared/_colors';
@import 'connect-elements/src/styles/shared/_mixins';

.mt-1rem {
  margin-top: 1rem;
}
.copy-icon {
  height: 14px;
  margin: 3px 0 0 1px;
}
.python-logo {
  width: 5rem;
}
.r-logo {
  width: 1.5rem;
}
.quarto-logo {
  width: 5rem;
  margin-top: 0.5rem;
}
.container {
  display: flex;
  flex-direction: column;
  background-color: $color-light-grey;
  border-top: 0;
  padding-bottom: 0.5rem;
}
.detail {
  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &__section-title {
    line-height: 1rem;
    height: 1rem;
    padding: 0 0.5rem 0 0.5rem;
    font-style: italic;
  }
  &__divider {
    flex: 1 1 60%;
    height: 0.4rem;
    border-bottom: 1px solid $color-light-grey;
    margin: 0.25rem 0.5rem 0 0;
  }
}
.description {
  margin: 0.5rem 0.5rem 0 0.5rem;
  white-space: pre-wrap;

  &__multiline_text {
    padding-left: 0.5rem;
    flex: 1 1;
  }

  &__empty {
    padding-left: 0.5rem;
    flex: 1 1;
    font-style: italic;
  }
}
.installations-section {
  margin-top: 1rem;
}
.installation-list-container {
  margin: 0 0 0 0;
  padding: 0.5rem 0 0 1rem;
  flex: 1 1;
  flex-direction: column;
}
.installation-list {
  margin-left: 2rem;
}
.installation-entry {
  list-style-type: square;
  margin-bottom: 0em;

  &__container {
    display: flex;
    flex-direction: row;
  }
  &__version {
    flex: 0 0 6rem;
  }
  &__path {
    flex: 1 1;
  }
}
.title-value-row {
  margin: 0.5rem 0 0 0.5rem;
  flex-wrap: nowrap;

  &__title {
    margin-left: 0.5rem;
    font-weight: normal;
    flex: 0 0 8rem;
  }
  &__value {
    margin: 0 0.5rem;
    /* min-width required to keep flex from overflowing */
    min-width: 0;
  }
}
.attributes-section {
  padding-top: 1rem;
}
.install-warning {
  font-weight: bold;
  font-style: italic;
  cursor: pointer;
  // From rsc-alert.warning
  color: #93691a;
}
.action-button {
  padding: 0;
  background-color: $color-white;
  @include control-visible-focus;
  height: 22px;
  width: 22px;
}
</style>
