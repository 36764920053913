<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
  Renders the runtime tab
-->
<template>
  <div data-automation="app-settings__runtime">
    <ConfirmationPanel
      :enabled="formIsValid"
      :visible="doneLoading && (confirmationVisible || runAsIsChanging)"
      @save="preSaveConfirmation"
      @discard="discard"
    />
    <EmbeddedStatusMessage
      v-if="loading"
      :message="$t('appSettings.runtime.status.loading')"
      :show-close="false"
      type="activity"
      data-automation="loading"
    />
    <RunAsWarning
      v-if="runAs.showWarning"
      :content-type="app.contentType()"
      @close="closeRunAsWarning"
      @save="save"
    />
    <div
      v-if="loadingError"
      class="formSection"
      data-automation="loading-error"
    >
      <p>{{ $t('appSettings.runtime.status.error') }}</p>
    </div>
    <div
      v-if="showNoRuntimeSettings"
      class="formSection"
      data-automation="no-settings"
    >
      <p>{{ noSettingsMsg }}</p>
    </div>
    <div
      v-if="showRuntimeSettings"
      data-automation="has-settings"
    >
      <div
        v-if="disabled"
        class="rs-field"
      >
        <p>{{ $t('appSettings.runtime.readOnly') }}</p>
      </div>

      <!-- Min/Max Process Settings -->
      <div v-if="showProcessSettings">
        <RSInformationToggle
          class="spaceAfter"
        >
          <template #title>
            <!-- override contentPanel styling -->
            <span class="groupHeadings">
              {{ $t('appSettings.runtime.label.processSettings') }}
            </span>
          </template>
          <template #help>
            <div style="padding-top:0.5rem; padding-bottom: 0.5rem;">
              <i18n-t keypath="appSettings.runtime.settingsHelp.info.process.worker" />
            </div>
          </template>
        </RSInformationToggle>
        <RSInputNumberOverrideDefault
          v-model="form.minProcesses"
          :disabled="disabled"
          :message="errorForMinProcesses.message"
          :message-type="errorForMinProcesses.type"
          :help="$tm('appSettings.runtime.minProcesses.info')"
          :label="$t('appSettings.runtime.minProcesses.label')"
          :small="true"
          data-automation="min-processes"
          name="minProcesses"
          :units="$t('appSettings.runtime.units.processes')"
          :default-value="defaultsAndLimits.minProcesses"
          :help-link-obj="helpLinkForProcesses"
          blank-field-help-path="appSettings.runtime.settingsHelp.blankField"
          :zero-description="$t('appSettings.runtime.minProcesses.zero')"
          :readonly="disabled"
          @input="changeHandler"
        />
        <RSInputNumberOverrideDefault
          v-model="form.maxProcesses"
          :disabled="disabled"
          :message="errorForMaxProcesses"
          :help="$tm('appSettings.runtime.maxProcesses.info')"
          :label="$t('appSettings.runtime.maxProcesses.label')"
          :small="true"
          data-automation="max-processes"
          name="maxProcesses"
          :units="$t('appSettings.runtime.units.processes')"
          :default-value="defaultsAndLimits.maxProcesses"
          :help-link-obj="helpLinkForProcesses"
          blank-field-help-path="appSettings.runtime.settingsHelp.blankField"
          :readonly="disabled"
          @input="changeHandler"
        />
        <RSInputNumberOverrideDefault
          v-model="form.maxConnsPerProcess"
          :disabled="disabled"
          :message="errorForMaxConnsPerProcess"
          :help="$tm('appSettings.runtime.maxConnsPerProcess.info')"
          :label="$t('appSettings.runtime.maxConnsPerProcess.label')"
          :small="true"
          data-automation="max-conns-per-process"
          name="maxConnsPerProcess"
          :units="$t('appSettings.runtime.units.connections')"
          :default-value="defaultsAndLimits.maxConnsPerProcess"
          :help-link-obj="helpLinkForProcesses"
          blank-field-help-path="appSettings.runtime.settingsHelp.blankField"
          :readonly="disabled"
          @input="changeHandler"
        />
        <RSInputNumberOverrideDefault
          v-model="form.loadFactor"
          :disabled="disabled"
          :message="errorForLoadFactor"
          :help="$tm('appSettings.runtime.loadFactor.info')"
          :label="$t('appSettings.runtime.loadFactor.label')"
          :small="true"
          data-automation="load-factor"
          name="loadFactor"
          :default-value="defaultsAndLimits.loadFactor"
          :allow-decimal="true"
          :help-link-obj="helpLinkForProcesses"
          blank-field-help-path="appSettings.runtime.settingsHelp.blankField"
          :readonly="disabled"
          @input="changeHandler"
        />

        <!-- Timeout Settings -->
        <hr class="rs-divider">
        <RSInformationToggle
          class="spaceAfter"
        >
          <template #title>
            <!-- override contentPanel styling -->
            <span class="groupHeadings">
              {{ $t('appSettings.runtime.label.timeoutSettings') }}
            </span>
          </template>
          <template #help>
            <div style="padding-top:0.5rem; padding-bottom: 0.5rem;">
              <i18n-t keypath="appSettings.runtime.settingsHelp.info.process.worker" />
            </div>
          </template>
        </RSInformationToggle>
        <RSInputNumberOverrideDefault
          v-model="form.initTimeout"
          :disabled="disabled"
          :message="errorForInitTimeout"
          :help="$t('appSettings.runtime.initTimeout.info')"
          :label="$t('appSettings.runtime.initTimeout.label')"
          :small="true"
          data-automation="init-timeout"
          name="initTimeout"
          :default-value="defaultsAndLimits.initTimeout"
          :units="$t('appSettings.runtime.units.seconds')"
          :help-link-obj="helpLinkForTimeouts"
          blank-field-help-path="appSettings.runtime.settingsHelp.blankField"
          :readonly="disabled"
          @input="changeHandler"
        />
        <RSInputNumberOverrideDefault
          v-model="form.idleTimeout"
          :disabled="disabled"
          :message="errorForIdleTimeout"
          :help="$tm('appSettings.runtime.idleTimeout.info')"
          :label="$t('appSettings.runtime.idleTimeout.label')"
          :small="true"
          data-automation="idle-timeout"
          name="idleTimeout"
          :default-value="defaultsAndLimits.idleTimeout"
          :units="$t('appSettings.runtime.units.seconds')"
          :help-link-obj="helpLinkForTimeouts"
          blank-field-help-path="appSettings.runtime.settingsHelp.blankField"
          :readonly="disabled"
          @input="changeHandler"
        />
        <RSInputNumberOverrideDefault
          v-model="form.connectionTimeout"
          :disabled="disabled"
          :message="errorForConnectionTimeout"
          :help="$tm('appSettings.runtime.connectionTimeout.info')"
          :label="$t('appSettings.runtime.connectionTimeout.label')"
          :small="true"
          data-automation="connection-timeout"
          name="connectionTimeout"
          :default-value="defaultsAndLimits.connectionTimeout"
          :units="$t('appSettings.runtime.units.seconds')"
          :help-link-obj="helpLinkForTimeouts"
          blank-field-help-path="appSettings.runtime.settingsHelp.blankField"
          :zero-description="$t('appSettings.runtime.connectionTimeout.zero')"
          :readonly="disabled"
          @input="changeHandler"
        />
        <RSInputNumberOverrideDefault
          v-model="form.readTimeout"
          :disabled="disabled"
          :message="errorForReadTimeout"
          :help="$tm('appSettings.runtime.readTimeout.info')"
          :label="$t('appSettings.runtime.readTimeout.label')"
          :small="true"
          data-automation="read-timeout"
          name="readTimeout"
          :default-value="defaultsAndLimits.readTimeout"
          :units="$t('appSettings.runtime.units.seconds')"
          :help-link-obj="helpLinkForTimeouts"
          blank-field-help-path="appSettings.runtime.settingsHelp.blankField"
          :zero-description="$t('appSettings.runtime.readTimeout.zero')"
          :readonly="disabled"
          @input="changeHandler"
        />
        <hr class="rs-divider">
      </div>

      <!-- RunAs & Service Account -->
      <div v-if="showExecutableSettings">
        <RSInformationToggle
          class="spaceAfter"
        >
          <template #title>
            <span class="groupHeadings">
              {{ $t('appSettings.access.sections.processExecution.title') }}
            </span>
          </template>
          <template #help>
            <div
              v-for="msg in $tm('appSettings.access.sections.processExecution.help')"
              :key="msg"
              class="spaceAfter"
            >
              {{ $rt(msg) }}
            </div>
          </template>
        </RSInformationToggle>

        <RunAs
          :is-admin="currentUser.isAdmin()"
          :default-run-as-user="runAs.defaultUser"
          :run-as-current-allowed="runAs.currentUserAllowed"
        />

        <RSInputSelect
          v-if="serviceAccount.loaded"
          name="as-service-accounts"
          data-automation="content-service-account"
          :label="$t('appSettings.runtime.serviceAccount.dropdownLabel')"
          :disabled="readonlyServiceAccount"
          :value="serviceAccount.active"
          :options="serviceAccount.all"
          @change="onServiceAccountSelectionChange"
        >
          <template #help>
            <p>{{ $t('appSettings.runtime.serviceAccount.intro') }}</p>
            <i18n-t
              keypath="appSettings.runtime.serviceAccount.defaultAccountDetails"
              tag="p"
            />
            <p
              v-if="showServiceAccountSelectionDisabledMsg"
            >
              {{ $t('appSettings.runtime.serviceAccount.infoConfDisabled') }}
            </p>
          </template>
        </RSInputSelect>
        <MessageBox
          v-if="!serviceAccount.recognized && currentUser.isAdmin()"
          alert
          small
        >
          <i18n-t
            :keypath="serviceAccountWarnAdmin.path"
            tag="span"
          >
            <strong>
              "{{ serviceAccount.initial }}"
            </strong>
            <a
              :href="serviceAccountWarnAdmin.docsHref"
              target="_blank"
            >
              {{ serviceAccountWarnAdmin.troubleshootLinkLabel }}
            </a>
          </i18n-t>
        </MessageBox>
        <MessageBox
          v-if="!serviceAccount.recognized && !currentUser.isAdmin()"
          alert
          small
        >
          <i18n-t
            :keypath="serviceAccountWarnPublisher.path"
            tag="span"
          >
            <strong>
              "{{ serviceAccount.initial }}"
            </strong>
            <a
              :href="serviceAccountWarnPublisher.docsHref"
              target="_blank"
            >
              {{ serviceAccountWarnPublisher.troubleshootLinkLabel }}
            </a>
          </i18n-t>
        </MessageBox>
        <hr class="rs-divider">
      </div>

      <!-- Execution Environment -->
      <div v-if="showExecutionEnvironment">
        <RSInformationToggle
          class="spaceAfter"
          data-automation="executionEnvironment-help"
        >
          <template #title>
            <span class="groupHeadings">
              {{ $t('appSettings.runtime.executionEnvironment.title') }}
            </span>
          </template>
          <template #help>
            <div class="spaceBefore spaceAfter">
              <div class="spaceAfter">
                {{ $t('appSettings.runtime.executionEnvironment.help.p1') }}
              </div>
              <div class="spaceAfter">
                {{ $t('appSettings.runtime.executionEnvironment.help.p2') }}
              </div>
              <div class="spaceAfter">
                <i18n-t keypath="appSettings.runtime.executionEnvironment.help.p3.t">
                  <template #adminGuide>
                    <a
                      :href="applicationSettingsDocumentation"
                      target="_blank"
                    >{{
                      $t('appSettings.runtime.executionEnvironment.help.p3.guide')
                    }}</a>
                  </template>
                </i18n-t>
              </div>
              <div class="spaceAfter">
                {{ $t('appSettings.runtime.executionEnvironment.help.p4') }}
              </div>
              <div class="spaceAfter">
                {{ $t('appSettings.runtime.executionEnvironment.help.p5') }}
              </div>
            </div>
          </template>
        </RSInformationToggle>

        <DefaultExecutionEnvironment
          ref="defaultExecutionEnvironment"
          :image-name="executionEnvironment.imageName"
          :valid="executionEnvironment.valid"
          :image-last-time="executionEnvironment.last"
          :show-error-message="!executionEnvironment.valid"
          :allow-selection="executionEnvironmentSelectionAllowed"
          :read-only="disabled"
          @changeName="onDefaultImageNameChange"
          @changeOption="changeHandler"
          @valid="onDefaultImageNameValidChange"
        />
        <hr class="rs-divider">
      </div>

      <!-- CPU & RAM Settings -->
      <div
        v-if="showNewRuntimeSettings"
        class="rs-field"
      >
        <RSInformationToggle
          class="spaceAfter"
        >
          <template #title>
            <span
              v-if="showOffHostSpecificResourceSettings && (appHasWorker || appIsRendered)"
              class="groupHeadings"
            >
              {{ $t('appSettings.runtime.label.cpuAndRamSettings') }}
            </span>
            <span
              v-if="!showOffHostSpecificResourceSettings && (appHasWorker || appIsRendered)"
              class="groupHeadings"
            >
              {{ $t('appSettings.runtime.label.ramSettings') }}
            </span>
          </template>
          <template #help>
            <!-- have to use style here or will need to extend scoped styles within RSInformationToggle -->
            <div style="padding-top:0.5rem; padding-bottom: 0.5rem;">
              <i18n-t
                v-if="appHasWorker"
                keypath="appSettings.runtime.settingsHelp.info.cpuRam.worker"
              />
              <i18n-t
                v-if="appIsRendered"
                keypath="appSettings.runtime.settingsHelp.info.cpuRam.rendered"
              />
            </div>
          </template>
        </RSInformationToggle>
        <RSInputNumberOverrideDefault
          v-if="showOffHostSpecificResourceSettings"
          v-model="form.cpuRequest"
          :disabled="disabled"
          :message="errorForCPURequest"
          :help="$tm('appSettings.runtime.cpuRequest.info')"
          :label="$t('appSettings.runtime.cpuRequest.label')"
          :small="true"
          data-automation="initial-num-cpus"
          name="cpuRequest"
          :units="$t('appSettings.runtime.units.cpus')"
          :default-value="defaultsAndLimits.cpuRequest"
          :allow-decimal="true"
          :help-link-obj="helpLinkforScheduler"
          blank-field-help-path="appSettings.runtime.settingsHelp.blankField"
          :zero-description="$t('appSettings.runtime.cpuRequest.zero')"
          :readonly="disabled"
          @input="changeHandler"
        />
        <RSInputNumberOverrideDefault
          v-if="showOffHostSpecificResourceSettings"
          v-model="form.cpuLimit"
          :disabled="disabled"
          :message="errorForCPULimit"
          :help="$tm('appSettings.runtime.cpuLimit.info')"
          :label="$t('appSettings.runtime.cpuLimit.label')"
          :small="true"
          data-automation="max-num-cpus"
          name="cpuLimit"
          :units="$t('appSettings.runtime.units.cpus')"
          :default-value="defaultsAndLimits.cpuLimit"
          :allow-decimal="true"
          :help-link-obj="helpLinkforScheduler"
          blank-field-help-path="appSettings.runtime.settingsHelp.blankField"
          :zero-description="$t('appSettings.runtime.cpuLimit.zero')"
          :readonly="disabled"
          @input="changeHandler"
        />
        <RSInputNumberOverrideDefault
          v-if="showOffHostSpecificResourceSettings"
          v-model="form.memoryRequest"
          :disabled="disabled"
          :message="errorForMemoryRequest"
          :help="$tm('appSettings.runtime.memoryRequest.info')"
          :label="$t('appSettings.runtime.memoryRequest.label')"
          :small="true"
          data-automation="initial-ram-gib-per-process"
          name="memoryRequest"
          :default-value="defaultsAndLimits.memoryRequest"
          :allow-decimal="true"
          :units="$t('appSettings.runtime.units.gibs')"
          :help-link-obj="helpLinkforScheduler"
          blank-field-help-path="appSettings.runtime.settingsHelp.blankField"
          :zero-description="$t('appSettings.runtime.memoryRequest.zero')"
          :readonly="disabled"
          @input="changeHandler"
        />
        <!-- Always shown, if RAM is shown -->
        <RSInputNumberOverrideDefault
          v-model="form.memoryLimit"
          :disabled="disabled"
          :message="errorForMemoryLimit"
          :help="$tm('appSettings.runtime.memoryLimit.info')"
          :label="$t('appSettings.runtime.memoryLimit.label')"
          :small="true"
          data-automation="max-ram-gib-per-process"
          name="memoryLimit"
          :default-value="defaultsAndLimits.memoryLimit"
          :allow-decimal="true"
          :units="$t('appSettings.runtime.units.gibs')"
          :help-link-obj="helpLinkforScheduler"
          blank-field-help-path="appSettings.runtime.settingsHelp.blankField"
          :zero-description="$t('appSettings.runtime.memoryLimit.zero')"
          :readonly="disabled"
          @input="changeHandler"
        />
        <hr class="rs-divider">
      </div>

      <!-- AMD and NVIDIA GPUs -->
      <div v-if="(showAMDGPUSettings || showNvidiaGPUSettings) && (appHasWorker || appIsRendered)">
        <RSInformationToggle
          class="spaceAfter"
        >
          <template #title>
            <!-- override contentPanel styling -->
            <span class="groupHeadings">
              {{ $t('appSettings.runtime.label.gpuSettings') }}
            </span>
          </template>
          <template #help>
            <div style="padding-top:0.5rem; padding-bottom: 0.5rem;">
              <i18n-t
                v-if="appHasWorker"
                keypath="appSettings.runtime.settingsHelp.info.cpuRam.worker"
              />
              <i18n-t
                v-if="appIsRendered"
                keypath="appSettings.runtime.settingsHelp.info.cpuRam.rendered"
              />
            </div>
          </template>
        </RSInformationToggle>
        <RSInputNumberOverrideDefault
          v-if="showAMDGPUSettings"
          v-model="form.amdGpuLimit"
          :disabled="disabled"
          :message="errorForAMDGPULimit"
          :help="$tm('appSettings.runtime.gpuLimit.info')"
          :label="$t('appSettings.runtime.gpuLimit.amd.label')"
          :small="true"
          data-automation="amd-gpu-limit"
          name="amdGpu"
          :units="$t('appSettings.runtime.units.gpus')"
          :default-value="defaultsAndLimits.amdGpuLimit"
          :help-link-obj="helpLinkForGPUs"
          blank-field-help-path="appSettings.runtime.settingsHelp.blankField"
          :zero-description="$t('appSettings.runtime.gpuLimit.zero')"
          :readonly="disabled"
          @input="changeHandler"
        />
        <RSInputNumberOverrideDefault
          v-if="showNvidiaGPUSettings"
          v-model="form.nvidiaGpuLimit"
          :disabled="disabled"
          :message="errorForNvidiaGPULimit"
          :help="$tm('appSettings.runtime.gpuLimit.info')"
          :label="$t('appSettings.runtime.gpuLimit.nvidia.label')"
          :small="true"
          data-automation="nvidia-gpu-limit"
          name="nvidiaGpu"
          :units="$t('appSettings.runtime.units.gpus')"
          :default-value="defaultsAndLimits.nvidiaGpuLimit"
          :help-link-obj="helpLinkForGPUs"
          blank-field-help-path="appSettings.runtime.settingsHelp.blankField"
          :zero-description="$t('appSettings.runtime.gpuLimit.zero')"
          :readonly="disabled"
          @input="changeHandler"
        />
        <hr class="rs-divider">
      </div>

      <!-- Environment Management -->
      <div v-if="showNewRuntimeSettings">
        <DefaultEnvironmentManagement
          ref="defaultEnvironmentManagementPy"
          v-model="envManagement.python.appDefault"
          data-automation="python-env-management"
          app-settings-key="python"
          :initial-value="envManagement.python.initialValue"
          :server-default="envManagement.python.serverDefault"
          :last-restore="envManagement.python.last"
          :show-selection="showEnvManagementSelection"
          :read-only="disabled"
          @input="changeHandler"
        />
        <hr class="rs-divider">
        <DefaultEnvironmentManagement
          ref="defaultEnvironmentManagementR"
          v-model="envManagement.r.appDefault"
          data-automation="r-env-management"
          app-settings-key="r"
          :initial-value="envManagement.r.initialValue"
          :server-default="envManagement.r.serverDefault"
          :last-restore="envManagement.r.last"
          :show-selection="showEnvManagementSelection"
          :read-only="disabled"
          @input="changeHandler"
        />
        <hr class="rs-divider">
      </div>
    </div>
  </div>
</template>

<script>
import RSInformationToggle from 'Shared/components/RSInformationToggle.vue';
import RSInputNumberOverrideDefault from '@/components/RSInputNumberOverrideDefault.vue';
import MessageBox from '@/components/MessageBox.vue';
import RSInputSelect from 'Shared/components/RSInputSelect.vue';
import DefaultEnvironmentManagement from './DefaultEnvironmentManagement.vue';
import DefaultExecutionEnvironment from './DefaultExecutionEnvironment.vue';
import RunAs from './RunAs.vue';
import RunAsWarning from './RunAsWarning.vue';

import { updateApp, updateRunAs } from '@/api/app';
import {
  getApplicationsSettings,
  getRuntimeDefaultsAndLimits,
  ExecutionTypeK8S,
} from '@/api/serverSettings';
import { getServiceAccounts } from '@/api/system';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage.vue';
import { docsPath } from '@/utils/paths';
import ConfirmationPanel from '@/views/content/settings/ConfirmationPanel';

import {
  importAppData,
  exportAppData,
  errorForMinValueZero,
  errorForTimeoutSettings,
  importDefaultsAndLimits,
  validateResourceRequest,
  validateResourceLimit,
  validateMinProcesses,
  validateMaxProcesses,
  internalError,
  isEmptyValue,
  maxGibibytes,
} from './runtime';

import { mapState, mapMutations, mapActions } from 'vuex';
import {
  ACCESS_SETTINGS_UPDATE_PRIMARY_MODE,
  ACCESS_SETTINGS_UPDATE_SECONDARY_MODE,
  ACCESS_SETTINGS_UPDATE_ALTERNATE_USER,
  RunAsModes,
} from '@/store/modules/accessSettings';
import {
  LOAD_CONTENT_VIEW,
  SET_CONTENT_FRAME_RELOADING,
} from '@/store/modules/contentView';
import {
  SHOW_INFO_MESSAGE,
  SHOW_ERROR_MESSAGE,
  SET_ERROR_MESSAGE_FROM_API,
  CLEAR_STATUS_MESSAGE,
} from '@/store/modules/messages';

export default {
  name: 'RuntimeSettingsWithRAMCPU',
  components: {
    EmbeddedStatusMessage,
    ConfirmationPanel,
    DefaultEnvironmentManagement,
    DefaultExecutionEnvironment,
    MessageBox,
    RSInformationToggle,
    RSInputNumberOverrideDefault,
    RSInputSelect,
    RunAs,
    RunAsWarning,
  },
  data() {
    return {
      loading: true,
      loadingError: false,
      disabled: true,
      appSettings: {},
      apiValues: {}, // app defaults (scheduler overrides); string values and converted to seconds
      defaultsAndLimits: {}, // scheduler defaults; string values and converted to seconds
      form: {
        // string values, required by input component
        cpuRequest: null,
        cpuLimit: null,
        memoryRequest: null,
        memoryLimit: null,
        amdGpuLimit: null,
        nvidiaGpuLimit: null,
        minProcesses: null,
        maxProcesses: null,
        maxConnsPerProcess: null,
        loadFactor: null,
        initTimeout: null,
        connectionTimeout: null,
        idleTimeout: null,
        readTimeout: null,
      },
      envManagement: {
        r: {
          appDefault: null,
        },
        python: {
          appDefault: null,
        },
      },
      executionEnvironment: {
        imageName: null,
        valid: true,
      },
      serviceAccount: {
        apiResponse: null,
        enabled: true,
        all: [],
        loaded: false,
        recognized: true,
        default: 'default',
        initial: '',
        active: '',
      },
      runAs: {
        initial: {
          primaryMode: null,
          secondaryMode: null,
          alternateUser: null,
        },
        defaultUser: '',
        currentUserAllowed: false,
        showWarning: false,
      },
      confirmationVisible: false,
      initPromise: new Promise(() => {}),
    };
  },
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      currentUser: state => state.currentUser.user,
      serverSettings: state => state.server.settings,
      runAsActiveState: state => state.accessSettings.runAs
    }),
    helpLinkforScheduler() {
      return {
        i18nPath: 'appSettings.runtime.docHelp.settingLink',
        anchor: {
          href: this.schedulerDocumentation,
          text: this.$t('appSettings.runtime.docHelp.adminGuide'),
          place: 'docName',
        }
      };
    },
    helpLinkForProcesses() {
      return {
        i18nPath: 'appSettings.runtime.docHelp.settingLink',
        anchor: {
          href: this.userProcessDocumentation,
          text: this.$t('appSettings.runtime.docHelp.userGuide'),
          place: 'docName',
        },
      };
    },
    helpLinkForTimeouts() {
      return {
        i18nPath: 'appSettings.runtime.docHelp.settingLink',
        anchor: {
          href: this.userTimeoutDocumentation,
          text: this.$t('appSettings.runtime.docHelp.userGuide'),
          place: 'docName',
        },
      };
    },
    helpLinkForGPUs() {
      return {
        i18nPath: 'appSettings.runtime.docHelp.settingLink',
        anchor: {
          href: this.gpuDocumentation,
          text: this.$t('appSettings.runtime.docHelp.userGuide'),
          place: 'docName',
        },
      };
    },
    applicationSettingsDocumentation() {
      return docsPath('admin/appendix/configuration/#Applications.Settings');
    },
    schedulerDocumentation() {
      return docsPath('admin/appendix/configuration/#Scheduler.Settings');
    },
    userTimeoutDocumentation() {
      return docsPath('user/content-settings/#timeout-configurations');
    },
    userProcessDocumentation() {
      return docsPath('user/content-settings/#process-configurations');
    },
    gpuDocumentation() {
      return docsPath('user/content-settings/#gpu-configurations');
    },
    doneLoading() {
      return !this.loading && !this.loadingError;
    },
    showNoRuntimeSettings() {
      if (this.doneLoading) {
        if (this.appIsRendered && !this.serverSettings.enableRuntimeConstraints) {
          return true;
        }
        return !this.app?.hasRuntimeSettings();
      }
      return false;
    },
    showRuntimeSettings() {
      return this.doneLoading && this.app?.hasRuntimeSettings();
    },
    showNewRuntimeSettings() {
      return this.doneLoading && this.serverSettings.enableRuntimeConstraints;
    },
    showOffHostSpecificResourceSettings() {
      return (
        this.doneLoading
        && this.serverSettings.executionType === ExecutionTypeK8S
        && this.serverSettings.enableRuntimeConstraints
      );
    },
    showProcessSettings() {
      return this.doneLoading && this.appHasWorker;
    },
    showExecutableSettings() {
      return this.doneLoading && this.app.isExecutable();
    },
    showServiceAccountSelectionDisabledMsg() {
      return this.isAdmin && !this.serviceAccount.enabled;
    },
    showEnvManagementSelection() {
      return (
        this.doneLoading
        && this.serverSettings.defaultEnvironmentManagementSelection
      );
    },
    showExecutionEnvironment() {
      return (
        this.doneLoading
        && this.app.isExecutable()
        && (this.currentUser.isAdmin() || this.currentUser.isPublisher())
        && this.serverSettings.enableImageManagement
        && this.serverSettings.executionType === ExecutionTypeK8S
      );
    },
    executionEnvironmentSelectionAllowed() {
      return (
        (this.currentUser.isAdmin() || this.currentUser.isPublisher())
        && this.serverSettings.defaultImageSelectionEnabled
      );
    },
    readonlyServiceAccount() {
      return !this.currentUser.isAdmin() || !this.serviceAccount.enabled;
    },
    showAMDGPUSettings() {
      return (
        this.showOffHostSpecificResourceSettings
        && (this.defaultsAndLimits.maxAmdGpuLimit > 0 || this.app.amdGpuLimit !== null)
      );
    },
    showNvidiaGPUSettings() {
      return (
        this.showOffHostSpecificResourceSettings
        && (this.defaultsAndLimits.maxNvidiaGpuLimit > 0 || this.app.nvidiaGpuLimit !== null)
      );
    },
    noSettingsMsg() {
      if (this.serverSettings.enableRuntimeConstraints) {
        return this.$t('appSettings.runtime.noSettingsMsgWithConstraints');
      }
      return this.$t('appSettings.runtime.noSettingsMsgWithoutConstraints');
    },
    appHasWorker() {
      return this.doneLoading && this.app?.hasWorker();
    },
    appIsRendered() {
      return this.doneLoading && this.app?.isRenderable();
    },
    errorForCPULimit() {
      return validateResourceLimit({
        input: {
          form: this.form.cpuLimit,
          limit: this.defaultsAndLimits.maxCpuLimit,
        },
        minimum: {
          form: this.form.cpuRequest,
          default: this.defaultsAndLimits.cpuRequest,
          errorPath: 'appSettings.runtime.errors.minLimit.cpuLimit'
        }
      });
    },
    errorForCPURequest() {
      return validateResourceRequest({
        input: {
          form: this.form.cpuRequest,
          limit: this.defaultsAndLimits.maxCpuRequest,
        },
        maximum: {
          form: this.form.cpuLimit,
          default: this.defaultsAndLimits.cpuLimit,
          errorPath: 'appSettings.runtime.errors.maxLimit.cpuRequest'
        }
      });
    },
    errorForMemoryLimit() {
      // when Kubernetes is enabled, validate in terms of memoryRequest.
      // otherwise, don't compare to memoryRequest as that field is hidden and
      // can't be changed. The easiest way to do this is just declare
      // memoryRequest to be unlimited.
      let minimum = {
        form: this.form.memoryRequest,
        default: this.defaultsAndLimits.memoryRequest,
        errorPath: 'appSettings.runtime.errors.minLimit.memoryLimit'
      };
      if (!this.showOffHostSpecificResourceSettings) {
        minimum = {
          form: 0, // unlimited
        };
      }

      return validateResourceLimit({
        input: {
          form: this.form.memoryLimit,
          limit: this.defaultsAndLimits.maxMemoryLimit,
          safeLimit: maxGibibytes,
          unit: 'GiB',
        },
        minimum,
      });
    },
    errorForMemoryRequest() {
      return validateResourceRequest({
        input: {
          form: this.form.memoryRequest,
          limit: this.defaultsAndLimits.maxMemoryRequest,
          safeLimit: maxGibibytes,
          unit: 'GiB',
        },
        maximum: {
          form: this.form.memoryLimit,
          default: this.defaultsAndLimits.memoryLimit,
          errorPath: 'appSettings.runtime.errors.maxLimit.memoryRequest'
        }
      });
    },
    errorForAMDGPULimit() {
      // Note: we are using validateResourceRequest here even though we are validating gpu_limit
      // because GPU resource limits behave more like a resource requests than a limits
      // see also: https://kubernetes.io/docs/tasks/manage-gpus/scheduling-gpus/#using-device-plugins
      return validateResourceRequest({
        input: {
          form: this.form.amdGpuLimit,
          limit: this.defaultsAndLimits.maxAmdGpuLimit,
          // safeLimit is required, otherwise 0 is interpreted as unlimited
          safeLimit: this.defaultsAndLimits.maxAmdGpuLimit,
          unit: 'GPUs',
        },
        maximum: {
          form: this.defaultsAndLimits.maxAmdGpuLimit,
        }
      });
    },
    errorForNvidiaGPULimit() {
      // Note: we are using validateResourceRequest here even though we are validating gpu_limit
      // because GPU resource limits behave more like a resource requests than a limits
      // see also: https://kubernetes.io/docs/tasks/manage-gpus/scheduling-gpus/#using-device-plugins
      return validateResourceRequest({
        input: {
          form: this.form.nvidiaGpuLimit,
          limit: this.defaultsAndLimits.maxNvidiaGpuLimit,
          // safeLimit is required, otherwise 0 is interpreted as unlimited
          safeLimit: this.defaultsAndLimits.maxNvidiaGpuLimit,
          unit: 'GPUs',
        },
        maximum: {
          form: this.defaultsAndLimits.maxNvidiaGpuLimit,
        }
      });
    },
    errorForMinProcesses() {
      const errStr = validateMinProcesses(
        this.form.minProcesses,
        this.defaultsAndLimits.minProcessesLimit,
        this.form.maxProcesses,
        this.defaultsAndLimits.maxProcesses
      );
      if (errStr !== null) {
        return {
          message: errStr,
          type: 'error',
        };
      }
      // special guidance, if no error already
      if (this.form.minProcesses > 5) {
        return {
          message: this.$t('appSettings.runtime.warnings.minProcesses'),
          type: 'warning',
        };
      }
      return {
        message: null,
        type: null,
      };
    },
    errorForMaxProcesses() {
      return validateMaxProcesses(
        this.form.maxProcesses,
        this.defaultsAndLimits.maxProcessesLimit,
        this.form.minProcesses,
        this.defaultsAndLimits.minProcesses
      );
    },
    errorForMaxConnsPerProcess() {
      return errorForMinValueZero(this.form.maxConnsPerProcess);
    },
    errorForLoadFactor() {
      if (isEmptyValue(this.form.loadFactor)) {
        return null;
      }
      const numValue = Number(this.form.loadFactor);
      if (isNaN(numValue)) {
        return internalError(`errorForLoadFactor: this.form.loadFactor=${this.form.loadFactor} isNaN`);
      }
      if (numValue < 0 || numValue > 1) {
        return this.$t('appSettings.runtime.errors.rangeError', { lowValue: 0, highValue: 1 });
      }
      return null;
    },
    errorForIdleTimeout() {
      return errorForTimeoutSettings(this.form.idleTimeout);
    },
    errorForInitTimeout() {
      return errorForTimeoutSettings(this.form.initTimeout);
    },
    errorForConnectionTimeout() {
      return errorForTimeoutSettings(this.form.connectionTimeout);
    },
    errorForReadTimeout() {
      return errorForTimeoutSettings(this.form.readTimeout);
    },
    serviceAccountWarnAdmin() {
      return {
        path: 'appSettings.runtime.serviceAccount.notRecognizedWarningAdmin',
        troubleshootLinkLabel: this.$t('appSettings.runtime.serviceAccount.troubleshootLinkLabel'),
        docsHref: docsPath('admin/appendix/off-host/service-accounts/#troubleshooting'),
      };
    },
    serviceAccountWarnPublisher() {
      return {
        path: 'appSettings.runtime.serviceAccount.notRecognizedWarningPublisher',
        troubleshootLinkLabel: this.$t('appSettings.runtime.serviceAccount.troubleshootLinkLabel'),
        docsHref: docsPath('admin/appendix/off-host/service-accounts/#troubleshooting'),
      };
    },
    runAsIsChanging() {
      return this.runAs.initial.primaryMode !== this.runAsActiveState.primaryMode
        || this.runAs.initial.secondaryMode !== this.runAsActiveState.secondaryMode
        || this.runAs.initial.alternateUser !== this.runAsActiveState.alternateUser;
    },
    // eslint-disable-next-line complexity
    formIsValid() {
      // This list must be kept up with all fields within this panel
      // if they have the opportunity to be invalid.

      // If a setting is hidden, we should not prevent saving even if it has an
      // error because the user will not be able to see what is wrong or correct
      // it. Consider: an allowed CPU limit is set; later the admin lowers the max
      // CPU limit; later still they disable Kubernetes support. Now the apps
      // runtime settings can never be updated, because the existing CPU limit is
      // illegal but is also hidden. So we ignore hidden settings when deciding
      // whether saving is allowed.
      return (
        (!this.showOffHostSpecificResourceSettings || this.errorForCPULimit === null) &&
        (!this.showOffHostSpecificResourceSettings || this.errorForCPURequest === null) &&
        (!this.showNewRuntimeSettings || this.errorForMemoryLimit === null) &&
        (!this.showOffHostSpecificResourceSettings || this.errorForMemoryRequest === null) &&
        (!this.showAMDGPUSettings || this.errorForAMDGPULimit === null) &&
        (!this.showNvidiaGPUSettings || this.errorForNvidiaGPULimit === null) &&
        (this.errorForMinProcesses.type !== 'error') &&
        (this.errorForMaxProcesses === null) &&
        (this.errorForMaxConnsPerProcess === null) &&
        (this.errorForLoadFactor === null) &&
        (this.errorForIdleTimeout === null) &&
        (this.errorForInitTimeout === null) &&
        (this.errorForConnectionTimeout === null) &&
        (this.errorForReadTimeout === null) &&
        (this.executionEnvironment.valid)
      );
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions({
      reloadContent: LOAD_CONTENT_VIEW,
      setInfoMessage: SHOW_INFO_MESSAGE,
      setErrorMessage: SHOW_ERROR_MESSAGE,
    }),
    ...mapMutations({
      runAsUpdatePrimary: ACCESS_SETTINGS_UPDATE_PRIMARY_MODE,
      runAsUpdateSecondary: ACCESS_SETTINGS_UPDATE_SECONDARY_MODE,
      runAsUpdateAlternateUser: ACCESS_SETTINGS_UPDATE_ALTERNATE_USER,
      reloadFrame: SET_CONTENT_FRAME_RELOADING,
      clearStatusMessage: CLEAR_STATUS_MESSAGE,
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    init() {
      this.loading = true;
      this.loadingError = false;
      this.clearStatusMessage();
      this.initPromise = this.getData()
        .then(this.populate)
        .catch(e => {
          this.loadingError = true;
          this.setErrorMessageFromAPI(e);
        })
        .finally(() => (this.loading = false));
    },
    getData() {
      return getApplicationsSettings()
        .then(appSettings => {
          this.appSettings = appSettings;
          this.apiValues = importAppData(this.app);
          this.disabled = !this.currentUser.canEditAppSettings(this.app);

          if (this.app?.hasWorker() || this.app?.isRenderable()) {
            const mode = this.app.appMode;
            return getRuntimeDefaultsAndLimits(mode)
              .then(runtimeDefaultsAndLimits => {
                this.defaultsAndLimits = importDefaultsAndLimits(
                  runtimeDefaultsAndLimits
                );
              });
          }
        })
        .then(() => {
          const isAdminOrPublisher = this.currentUser.isAdmin() || this.currentUser.isPublisher();
          const inK8S = this.serverSettings.executionType === ExecutionTypeK8S;
          if (isAdminOrPublisher && inK8S) {
            return getServiceAccounts()
              .then(serviceAccounts => {
                this.serviceAccount.apiResponse = serviceAccounts;
              });
          }
        });
    },
    populate() {
      if (!this.app) {
        return;
      }

      this.populateForm();
      this.populateEnvManagement();
      this.populateExecutionEnvironment();
      this.populateServiceAccount();
      this.populateRunAs();
    },
    populateForm() {
      this.form = {
        ...this.apiValues,
      };
    },
    populateEnvManagement() {
      this.envManagement.r.appDefault = this.app.defaultREnvironmentManagement;
      this.envManagement.r.initialValue = this.app.defaultREnvironmentManagement;
      this.envManagement.r.serverDefault = this.serverSettings.defaultREnvironmentManagement;
      this.envManagement.r.last = this.app.rEnvironmentManagement;

      this.envManagement.python.appDefault = this.app.defaultPyEnvironmentManagement;
      this.envManagement.python.initialValue = this.app.defaultPyEnvironmentManagement;
      this.envManagement.python.serverDefault = this.serverSettings.defaultPyEnvironmentManagement;
      this.envManagement.python.last = this.app.pyEnvironmentManagement;
    },
    populateExecutionEnvironment() {
      this.executionEnvironment.imageName = this.app.defaultImageName;
      this.executionEnvironment.last = this.app.imageName;
      this.executionEnvironment.valid = true;
    },
    populateServiceAccount() {
      if (this.serviceAccount.apiResponse) {
        this.serviceAccount.enabled = this.serviceAccount.apiResponse.enabled;
        this.serviceAccount.default = this.serviceAccount.apiResponse.default;
        this.serviceAccount.all = this.serviceAccount.apiResponse.accounts;

        let initAccount = this.serviceAccount.default;
        const contentAccount = this.app.serviceAccountName;
        // If service accounts feature not enabled. Finish the required assignments.
        if (this.serviceAccount.enabled && contentAccount) {
          initAccount = contentAccount;
          // If content has an unrecognized service account label it and include it within
          // the options for users to view the actual value, regardless of validity,
          // let's be transparent with what we have in the DB record.
          const accountExists = this.serviceAccount.all
            .find(ac => ac.value === initAccount);
          if (!accountExists) {
            const label = this.serviceAccount.apiResponse.notRecognizedLabel(initAccount);
            this.serviceAccount.recognized = false;
            this.serviceAccount.all = [
              ...this.serviceAccount.all,
              { label, value: initAccount }
            ];
          } else {
            this.serviceAccount.recognized = true;
          }
        }
        this.serviceAccount.initial = initAccount;
        this.serviceAccount.active = initAccount;
        this.serviceAccount.loaded = true;
      }
    },
    populateRunAs() {
      this.runAs.defaultUser = this.appSettings.runAs;
      this.runAs.currentUserAllowed = (this.appSettings.runAsCurrentUser
        && this.app.isRunnableAsCurrentUser());
      this.runAs.initial.primaryMode = RunAsModes.DEFAULT;
      this.runAs.initial.secondaryMode = RunAsModes.DEFAULT;
      this.runAs.initial.alternateUser = '';
      if (this.app.runAs) {
        this.runAs.initial.primaryMode = RunAsModes.ALTERNATE;
        this.runAs.initial.secondaryMode = RunAsModes.ALTERNATE;
        this.runAs.initial.alternateUser = this.app.runAs;
      }
      if (this.runAs.currentUserAllowed && this.app.runAsCurrentUser) {
        this.runAs.initial.primaryMode = RunAsModes.CURRENT;
      }
      this.runAsUpdatePrimary(this.runAs.initial.primaryMode);
      this.runAsUpdateSecondary(this.runAs.initial.secondaryMode);
      this.runAsUpdateAlternateUser(this.runAs.initial.alternateUser);
    },
    changeHandler() {
      this.confirmationVisible = true;
    },
    onDefaultImageNameChange(value) {
      if (this.executionEnvironment.imageName !== value) {
        this.executionEnvironment.imageName = value;
        this.confirmationVisible = true;
      }
    },
    onDefaultImageNameValidChange(value) {
      this.executionEnvironment.valid = value;
    },
    onServiceAccountSelectionChange(value) {
      this.serviceAccount.active = value;
      this.confirmationVisible = true;
    },
    closeRunAsWarning() {
      this.runAs.showWarning = false;
    },
    preSaveConfirmation() {
      if (this.runAsIsChanging) {
        this.runAs.showWarning = true;
      } else {
        return this.save();
      }
    },
    save() {
      // bail if form is not valid
      if (!this.formIsValid) {
        return Promise.resolve();
      }
      const result = exportAppData(this.form, this.app.id);
      if (result.error !== null) {
        this.setErrorMessage({ mesage: result.error });
        return Promise.reject(result.error);
      }
      this.runAs.showWarning = false;
      this.clearStatusMessage();

      // update environment management fields if they were modified
      if (this.app.defaultREnvironmentManagement !== this.envManagement.r.appDefault) {
        result.data.defaultREnvironmentManagement = this.envManagement.r.appDefault;
      }
      if (this.app.defaultPyEnvironmentManagement !== this.envManagement.python.appDefault) {
        result.data.defaultPyEnvironmentManagement = this.envManagement.python.appDefault;
      }

      // update default execution environment selection if it was modified
      if (this.app.defaultImageName !== this.executionEnvironment.imageName) {
        result.data.defaultImageName = this.executionEnvironment.imageName;
      }

      // update service account selection if it was modified
      if (this.serviceAccount.initial !== this.serviceAccount.active) {
        // Submit "null" as service account when the configured default service account is selected.
        // This, for content to be able to use any configured default service account if it changes.
        //   ( Content items DB records with an existing service_account_name will always use that one,
        //   for this use case we want to make sure there is nothing preventing content to pick up
        //   new service accounts defined within Connect's config )
        result.data.serviceAccountName = null;
        if (this.serviceAccount.active !== this.serviceAccount.default) {
          result.data.serviceAccountName = this.serviceAccount.active;
        }
      }

      return this.saveRunAs()
        .then(() => {
          return updateApp(this.app.id, result.data);
        })
        .then(() => {
          return this.reloadContent({
            appIdOrGuid: this.app.guid,
          });
        })
        .then(() => {
          // updating the raw data
          this.apiValues = importAppData(this.app);
          this.confirmationVisible = false;
          this.setInfoMessage({ message: this.$t('appSettings.runtime.status.saved') });
          this.populate();
          if (this.appHasWorker) {
            // we need to cause app to restart. If static, no need.
            this.reloadFrame(true);
          }
        })
        .catch(this.setErrorMessageFromAPI);
    },
    saveRunAs() {
      if (this.runAsIsChanging) {
        const { primaryMode, secondaryMode, alternateUser } = this.runAsActiveState;
        const data = {
          runAs: primaryMode === RunAsModes.ALTERNATE ||
          (primaryMode === RunAsModes.CURRENT && secondaryMode === RunAsModes.ALTERNATE)
            ? alternateUser
            : null,
          runAsCurrentUser: primaryMode === RunAsModes.CURRENT,
        };
        return updateRunAs(this.app.id, data);
      }
      return Promise.resolve();
    },

    discard() {
      this.populate();
      Object.keys(this.$refs)
        .forEach(ref => {
          if (this.$refs[ref].resetState) {
            this.$refs[ref].resetState();
          }
        });
      this.confirmationVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
  @import 'connect-elements/src/styles/shared/_colors';
  .lightText {
    color: $color-dark-grey !important;
  }
  .letterSpacing {
  letter-spacing: .1em;
  }
  .smallerFont {
  font-size: .9em;
  }
  .largerFont {
  font-size: 1.2em;
  }
  .uppercase {
  text-transform: uppercase;
  }
  .groupHeadings {
    color: $color-heading;
    letter-spacing: .1em;
    font-size: 1em;
    text-transform: uppercase;
    margin-bottom: 0.5em;
  }
  .spaceAfter {
    margin-bottom: 0.5rem;
  }
</style>
