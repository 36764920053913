<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    :class="parentErrorClass"
    class="rs-horizontal rsc-metrics__timeframe"
  >
    <label
      class="rs-field"
      for="metrics-units"
    >{{
      $t('admin.metrics.showHistoryFor')
    }}</label>
    <RSInputNumber
      v-model.number="unit"
      :label="$t('admin.metrics.unitLabel')"
      :show-label="false"
      :small="true"
      data-automation="metrics-unit"
      name="metrics-unit"
      min="1"
      @input="changed"
    />
    <RSInputSelect
      v-model="range"
      :label="$t('admin.metrics.rangeLabel')"
      :show-label="false"
      :options="rangeOptions"
      :class="errorClass"
      data-automation="metrics-range"
      name="metrics-range"
      @change="changed"
    />
    <div
      v-show="v$.$invalid"
      class="rs-field__error"
    >
      <span v-show="!v$.unit.required">{{
        $t('admin.metrics.errors.timeframe.required')
      }}</span>
      <span v-show="!v$.unit.minimum">{{
        $t('admin.metrics.errors.timeframe.minimum')
      }}</span>
      <span v-show="!v$.unit.tooEarly">{{
        $t('admin.metrics.errors.timeframe.tooEarly')
      }}</span>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import * as Validators from '@vuelidate/validators';
import moment from 'moment-mini';
import RSInputNumber from 'Shared/components/RSInputNumber';
import RSInputSelect from 'Shared/components/RSInputSelect';
import { debounce } from '@/utils/debounce';

const NINTEEN_EIGHTY = '1980-01-01T00:00:00.000Z';

const isAfter1980 = period => {
  const start = moment().subtract(period.unit || 1, period.range);
  if (!start.isValid() || isNaN(start.toDate())) {
    // Our date manipulation resulted in an invalid moment or underlying JS
    // date; moment does not always tell us when it becomes invalid:
    // https://github.com/rstudio/connect/issues/12981#issuecomment-482684417
    // https://github.com/moment/moment/issues/5075
    return false;
  }

  // We have a valid start measurement and want to compare to the start of
  // 1980. rrdtool only supports dates after 1980-01-01.
  return start.isAfter(NINTEEN_EIGHTY);
};

export default {
  name: 'TimeframeSelector',
  components: { RSInputNumber, RSInputSelect },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      // unit and range defaults must match defaults in the consumer component
      unit: 1,
      range: 'days',
      rangeOptions: [
        { label: this.$t('admin.metrics.range.hours'), value: 'hours' },
        { label: this.$t('admin.metrics.range.days'), value: 'days' },
        { label: this.$t('admin.metrics.range.weeks'), value: 'weeks' },
        { label: this.$t('admin.metrics.range.months'), value: 'months' },
        { label: this.$t('admin.metrics.range.years'), value: 'years' },
      ],
    };
  },
  computed: {
    errorClass() {
      return this.v$.$invalid ? 'error' : '';
    },
    parentErrorClass() {
      return this.v$.$invalid ? 'rsc-metrics__timeframe--error' : '';
    },
  },
  methods: {
    changed: debounce(300, function() {
      this.actualChanged();
    }),
    actualChanged() {
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      }
      this.$emit('change', { unit: this.unit, range: this.range });
    },
  },
  validations: {
    unit: {
      required: Validators.required,
      minimum: Validators.minValue(1),
      tooEarly: (value, vm) => isAfter1980({ unit: value, range: vm.range }),
    },
  },
};
</script>

<style scoped lang="scss">
@import 'connect-elements/src/styles/shared/_colors';
.rsc-metrics {
  &__timeframe {
    input {
      width: 5rem;
      height: 32px;
    }

    select {
      font-size: 0.85rem;
      padding: 0.25rem 0.4rem;
      font-family: 'Lato', sans-serif;
      font-weight: normal;
    }

    &--error {
      .rs-input {
        border-color: $color-error;
      }
    }

    .rs-field:not(:last-child) {
      margin: 0 0.3rem 0 0;
    }
  }
}
</style>
