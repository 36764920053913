<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    :class="messageClass"
    v-bind="$attrs"
  >
    <figure
      v-if="useIcon"
      class="rsc-message-box__icon"
    />
    <button
      v-if="closeable"
      title="Close"
      class="rsc-message-box__close"
      @click="close"
    />
    <p>
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  name: 'MessageBox',
  props: {
    alert: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    useIcon: {
      type: Boolean,
      default: false,
    },
    closeable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    messageClass() {
      const mClass = ['rsc-message-box', 'rsc-alert'];
      if (this.alert) {
        mClass.push('warning');
      } else {
        mClass.push('info');
      }
      if (this.small) {
        mClass.push('rsc-message-box--small');
      }
      if (this.useIcon) {
        mClass.push('rsc-message-box--use-icon');
      }
      return mClass;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  }
};
</script>

<style scoped lang="scss">
@import 'connect-elements/src/styles/shared/_colors';
.rsc-message-box {
  position: relative;
  font-size: 1rem;

  &__icon {
    background: center center no-repeat url("../../images/alert.svg");
    background-size: auto 100%;
    display: none;
    left: 0.8rem;
    padding: 0.6rem;
    position: absolute;
    top: 1rem;
    vertical-align: top;
  }

  &__close {
    background: url('connect-elements/src/images/close.svg') no-repeat center center;
    background-size: 1.4rem;
    cursor: pointer;
    height: 1.4rem;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 1.4rem;

    &:hover {
      background-color: transparent;
    }
  }

  &--use-icon {
    padding-left: 2.44rem;
  }

  &--use-icon &__icon {
    display: block;
  }

  &--small {
    font-size: 0.8rem;
  }

  a {
    color: $color-secondary-inverse;
    text-decoration: underline;
  }
}

.rsc-alert {
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 4px;

  &.warning {
    border: 1px solid $color-warning-border;
    background-color: $color-warning-background;
    color: $color-warning;
  }
  &.info {
    border: 1px solid $color-info-border;
    background-color: $color-info-background;
    color: $color-info;
  }
  &.danger {
    border: 1px solid $color-error-border;
    background-color: $color-error-background;
    color: $color-error;
  }
  &__close {
    float: right;
    padding: 0;
    border: 0;
    min-width: inherit;
    line-height: 1;
    font-size: 21px;
    background-color: transparent;
    color: #000;
    text-shadow: 0 1px 0 $color-white;
    opacity: 0.2;
    &:focus, &:hover {
      opacity: 0.5;
    }
  }
}
</style>
