<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel__install-quarto wizard-panel--white">
    <div class="wizard-panel__content">
      <StepHeading :step="step">
        {{ $t('publish.installQuarto.title') }}
      </StepHeading>
      <p>{{ $t('publish.installQuarto.body.reason') }}</p>
      <p>{{ $t('publish.installQuarto.body.rstudio') }}</p>
      <i18n-t
        keypath="publish.installQuarto.body.install"
        tag="p"
      >
        <a
          ref="instructionsLink"
          place="instructions"
          href="https://quarto.org/docs/get-started/"
          target="_blank"
        >{{ $t('publish.installQuarto.instructions') }}</a>
      </i18n-t>
      <p>
        <a
          ref="learnMoreLink"
          href="https://quarto.org/"
          target="_blank"
        >{{ $t('publish.installQuarto.learnMore') }}</a>
      </p>
    </div>
  </div>
</template>

<script>
import PanelMixin from '../PanelMixin';
import StepHeading from '@/views/content/PublishWizard/StepHeading';

export default {
  name: 'InstallQuarto',
  components: { StepHeading },
  mixins: [PanelMixin],
};
</script>
