<!-- Copyright (C) 2022 by Posit Software, PBC. -->
<template>
  <div>
    <RSInputText
      :value="value"
      :label="$t('content.deploymentDialog.enterRepository.label')"
      name="git-repository-url"
      data-automation="git-repository-url"
      @input="$emit('input', $event);"
    />

    <ul class="validation">
      <li
        v-for="(detail, i) in details"
        :key="i"
        class="validation__detail"
        :class="`validation__detail--${iconClass(detail)}`"
      >
        <span
          class="validation__detail-text"
        >{{ detail.text }}</span>
        <div
          v-if="detail.additional"
          class="validation__detail-additional"
        >
          {{ detail.additional }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import RSInputText from 'Shared/components/RSInputText';

import { getRepoUsername } from '@/api/git';
import ApiErrors from '@/api/errorCodes';
import { safeAPIErrorCode } from '@/api/error';

export default {
  name: 'GitRepositoryURLInput',
  components: {
    RSInputText,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  emits: ['input', 'valid'],
  data() {
    return {
      result: {},
      errors: {},
    };
  },
  computed: {
    dirty() {
      return this.value.length > 0;
    },
    parsedURL() {
      // Return the input value as a parsed URL. JavaScript URL parsing is
      // very lenient; URLs like "http:github.com" can be parsed without
      // error. This is in contrast to the URL parsing we perform on the
      // server, which is much more strict.
      if (this.dirty) {
        try {
          return new URL(this.value);
        } catch {
          return {};
        }
      }
      return {};
    },
    accessType() {
      if (this.result.username) {
        return this.$t('content.deploymentDialog.enterRepository.details.urlCredentials.additional.username', {
          username: this.result.username,
        });
      } else if (this.result.suggestedUrl) {
        return this.$t('content.deploymentDialog.enterRepository.details.urlCredentials.additional.suggested', {
          username: this.result.suggestedUsername,
          url: this.result.suggestedUrl,
        });
      } else if (this.result.any === true) {
        return this.$t('content.deploymentDialog.enterRepository.details.urlCredentials.additional.unmatched');
      } else if (this.result.any === false) {
        return this.$t('content.deploymentDialog.enterRepository.details.urlCredentials.additional.none');
      }
      return this.$t('content.deploymentDialog.enterRepository.details.urlCredentials.additional.pending');
    },
    details() {
      const url = this.parsedURL;
      const urlProvided = url && url.protocol && !this.errors.invalidURL;
      const urlSimple = !(url.hash ||
                          url.search ||
                          url.username ||
                          url.password ||
                          this.errors.unsupportedParameters);
      const urlHTTPorHTTPS = ((url.protocol === 'http:' || url.protocol === 'https:') &&
                              !this.errors.unsupportedProtocol);
      return [
        {
          text: this.$t('content.deploymentDialog.enterRepository.details.urlProvided.text'),
          valid: urlProvided,
          required: true,
          requested: false,
        }, {
          text: this.$t('content.deploymentDialog.enterRepository.details.urlSimple.text'),
          valid: urlProvided && urlSimple,
          required: true,
          requested: false,
        }, {
          text: this.$t('content.deploymentDialog.enterRepository.details.urlHTTPorHTTPS.text'),
          valid: urlProvided && urlHTTPorHTTPS,
          required: true,
          requested: false,
        }, {
          text: this.$t('content.deploymentDialog.enterRepository.details.urlHTTPS.text'),
          valid: urlProvided && url.protocol === 'https:',
          required: false,
          requested: true,
        }, {
          text: this.$t('content.deploymentDialog.enterRepository.details.urlCredentials.text'),
          valid: url && this.result.username,
          required: false,
          requested: false,
          additional: this.accessType,
        }
      ];
    },
    valid() {
      if (!this.dirty) {
        return false;
      }

      // Collectively, the computed details are valid only when every required
      // entry is itself valid.
      return this.details.every(detail => {
        if (detail.required) {
          return detail.valid;
        }
        // Not required; always seen as valid.
        return true;
      });
    },
  },
  watch: {
    parsedURL: {
      handler(newValue) {
        // The parsedURL may be "valid" according to 'new URL(...)' because JS
        // uses a lenient parser, but may still fail URL parsing validations
        // on the server.
        if (newValue.username ||
            newValue.password ||
            newValue.search ||
            newValue.hash) {
          // URL contains unsupported components.
          this.result = {};
          this.errors = {};
        } else if (newValue.protocol ||
                   newValue.host ||
                   newValue.port) {
          // Our URL has changed, and we have something that looks valid, at
          // least according to the JS URL parser.
          this.checkURL();
        } else {
          // Our URL has changed to something invalid.
          this.result = {};
          this.errors = {};
        }
      },
      immediate: true,
    },
    valid: {
      handler(newValue) {
        this.$emit('valid', newValue);
      },
      immediate: true,
    },
  },
  methods: {
    iconClass(detail) {
      if (!this.dirty) {
        return 'pending';
      } else if (detail.valid) {
        return 'valid';
      } else if (detail.required) {
        return 'error';
      } else if (detail.requested) {
        return 'warning';
      }
      return 'info';
    },
    checkURL() {
      getRepoUsername(this.value).then(result => {
        this.result = result;
        this.errors = {};
      })
        .catch(err => {
          const errorCode = safeAPIErrorCode(err);
          this.result = {};
          this.errors = {
            invalidURL: errorCode === ApiErrors.InvalidParameter,
            unsupportedParameters: errorCode === ApiErrors.GitUnsupportedParameters,
            unsupportedProtocol: errorCode === ApiErrors.GitUnsupportedProtocol,
          };
        // NOTE: other errors are not handled.
        });
    }
  },
};
</script>

<style lang="scss" scoped>
@import 'connect-elements/src/styles/shared/_colors';

.validation__detail {
    margin-bottom: 0;
    padding-left: 32px;

    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: left 5px;

    &--valid {
        background-image: url('connect-elements/src/images/messageGood.svg');
    }

    &--pending {
        background-image: url('connect-elements/src/images/actionRemove.svg');
    }

    &--info {
        background-image: url('connect-elements/src/images/messageInfo.svg');
    }

    &--error {
        background-image: url('connect-elements/src/images/messageUrgent.svg');
    }
    &--warning {
        background-image: url('connect-elements/src/images/messageImportant.svg');
    }
    &-text, &-additional {
      background-image: none!important;
      background-color: $color-white;
    }
}

.validation__detail-additional {
    opacity: 0.75;
    font-style: italic;
    /* min-height: 50px; Safari has a repaint problem if we specify min-height. */
}
</style>
