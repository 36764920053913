<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
  Username and/or Email completion screen for mostly first-time login users.
  This screen is brought up under two scenarios:
  1. A user did not have a username associated to them while prior to v1.7.2 w/OAuth2
  2. An invalid email was specified when creating the user via API or given by an auth provider
-->
<template>
  <div class="band pushFooter">
    <div class="bandContent mainPage">
      <!-- Updating a user is a restricted action - it may require reauthentication -->
      <!-- Delay re-authentication till the user submits the full-page form
          (re-authentication is an unlikely scenario as the user will have just signed in) -->
      <RestrictedAccessWrapper
        v-slot="{ executeRestrictedApi }"
        :eager="false"
      >
        <div
          v-if="loaded"
          class="fullPageFormContainer"
        >
          <div class="formTitle">
            {{ $t('authentication.userCompletion.title') }}
          </div>
          <form
            novalidate
            @submit.prevent="onSubmit(executeRestrictedApi)"
          >
            <div class="rs-field">
              <p>{{ $t('authentication.userCompletion.description') }}</p>
            </div>

            <fieldset :disabled="form.disabled">
              <RSInputText
                v-if="form.requireUsername"
                v-model.lazy="form.username"
                :label="$t('authentication.label.username')"
                :message="errorMessage('username')"
                :disabled="!readableUsername"
                name="uc-username"
                data-automation="uc-username"
              />

              <RSInputText
                v-if="form.requireEmail"
                v-model.lazy="form.email"
                :label="$t('authentication.label.email')"
                :message="errorMessage('email')"
                :disabled="!readableEmail"
                name="uc-email"
                type="email"
                data-automation="uc-email"
              />

              <div class="rs-field actions">
                <RSButton
                  :disabled="submitDisabled"
                  :label="$t('navigation.proceedToDashboard')"
                  type="primary"
                  data-automation="uc-proceed"
                />
              </div>
            </fieldset>

            <div
              v-if="authenticationNotice"
              class="rs-field"
              data-automation="uc-notice"
            >
              {{ authenticationNotice }}
            </div>
          </form>
        </div>
      </RestrictedAccessWrapper>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import RSInputText from 'Shared/components/RSInputText';
import RSButton from 'Shared/components/RSButton';

import { getPermissions, updateUser } from '@/api/users';
import RestrictedAccessWrapper, {
  ReauthenticationInProgressError,
} from '@/components/RestrictedAccessWrapper';
import {
  emailValidator,
  isValidEmail,
  usernameValidator,
} from '@/utils/validators';
import { mapMutations, mapActions, mapState } from 'vuex';
import { CURRENT_USER_LOAD } from '@/store/modules/currentUser';
import {
  SET_ERROR_MESSAGE_FROM_API,
  SET_ACTIVITY_MESSAGE,
  CLEAR_ACTIVITY_MESSAGE,
} from '@/store/modules/messages';

export default {
  name: 'UserCompletion',
  components: { RestrictedAccessWrapper, RSButton, RSInputText },
  setup() {
    return { v$: useVuelidate() };
  },
  data: () => ({
    loaded: false,
    api: {
      permissions: null,
    },
    form: {
      disabled: true,
      requireUsername: false,
      requireEmail: false,
      username: null,
      email: null,
    },
  }),
  validations() {
    return this.formValidations();
  },
  computed: {
    authenticationNotice() {
      return this.serverSettings?.authentication.notice;
    },
    readableUsername() {
      return this.api.permissions.username.readable;
    },
    readableEmail() {
      return this.api.permissions.email.readable;
    },
    submitDisabled() {
      const enabled =
        // either fields are editable
        (this.form.requireEmail && this.readableEmail) ||
        (this.form.requireUsername && this.readableUsername);
      return !enabled;
    },
    ...mapState({
      currentUser: state => state.currentUser.user,
      serverSettings: state => state.server.settings,
    }),
  },
  async created() {
    try {
      const permissions = await getPermissions(this.currentUser.guid);
      this.api.permissions = permissions;
      this.redirectOrPrepareForm();
      this.form.disabled = false;
    } catch (error) {
      this.setErrorMessageFromAPI(error);
    }
    this.loaded = true;
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
      setActivityMessage: SET_ACTIVITY_MESSAGE,
      clearActivityMessage: CLEAR_ACTIVITY_MESSAGE,
    }),
    ...mapActions({
      loadCurrentUser: CURRENT_USER_LOAD,
    }),

    // if profile fields are valid, continue to dashboard, else prepare the form for user input.
    redirectOrPrepareForm() {
      // check if username and email profile fields need user input
      const usernameAndEmail = validateProfileFields(this.currentUser, this.serverSettings);

      if (usernameAndEmail.valid()) {
        // nothing needed from the user (profile is filled out to our satisfaction)
        this.continueToDashboard();
        return;
      }

      // user input is required
      this.form.requireUsername = !usernameAndEmail.username;
      this.form.requireEmail = !usernameAndEmail.email;

      if (this.form.requireUsername && this.readableUsername) {
        this.form.username = generateUsername(this.currentUser);
      }
      if (this.form.requireEmail && this.readableEmail) {
        this.form.email = this.currentUser.email;
      }
    },

    async continueToDashboard() {
      if (!this.loaded) {
        return;
      }

      // if present:
      // - url will be an absolute url (e.g. solo view, vanity url)
      // - target will be a routable location in the dashboard
      const { url, target } = this.$route.query;
      await this.loadCurrentUser();
      if (url) {
        // go to url
        window.location.href = url;
      } else if (target) {
        await this.$router.push(target);
      } else {
        this.$router.push({ name: 'root' });
      }
    },

    errorMessage(field) {
      const fieldCapitalized = field[0].toUpperCase() + field.slice(1);
      if (
        this.form[`require${fieldCapitalized}`] &&
        !this[`readable${fieldCapitalized}`]
      ) {
        // required field but not readable
        return this.api.permissions[field].helperMessage;
      }

      const fieldValidators = this.v$.form[field];

      if (!fieldValidators.$error) {
        // no error
        return null;
      }

      // return the first failed validator
      const errorKey = fieldValidators.$errors[0].$validator;
      return this.$t(
        `authentication.validation.${field}.${errorKey}`
      );
    },

    onSubmit(executeRestrictedApi) {
      this.v$.form.$touch();
      if (this.v$.form.$invalid) {
        // invalid form, bail
        return Promise.resolve();
      }

      // determine what to send
      const data = {};
      if (this.form.requireUsername) {
        data.username = this.form.username;
      }
      if (this.form.requireEmail) {
        data.email = this.form.email;
      }

      const activityPage = this.$route.name;
      this.form.disabled = true;
      const updatingMsgTimeout = setTimeout(() => {
        this.setActivityMessage({
          page: activityPage,
          message: this.$t('authentication.userCompletion.updating.message'),
        });
      }, 250);

      return executeRestrictedApi(updateUser(this.currentUser.guid, data))
        .then(() => {
          return this.continueToDashboard();
        })
        .catch(err => {
          if (!(err instanceof ReauthenticationInProgressError)) {
            this.setErrorMessageFromAPI(err);
          }
        })
        .finally(() => {
          this.form.disabled = false;
          clearTimeout(updatingMsgTimeout);
          this.clearActivityMessage();
        });
    },
    formValidations() {
      const { requireEmail, requireUsername } = this.form;
      const validations = { form: {} };

      if (requireEmail) {
        validations.form.email = emailValidator();
      }

      if (requireUsername) {
        validations.form.username = usernameValidator(this.serverSettings);
      }

      return validations;
    },
  },
};

// generate a username - it takes into account any existing username, email,
// first name, and last name.
export function generateUsername(user) {
  let newUsername = user.username.trim();
  if (newUsername.length > 0) {
    // username is set
    return newUsername;
  }

  newUsername = user.email.split('@')[0].split('+')[0];
  if (newUsername.length > 0) {
    // username guessed from email
    return newUsername.toLowerCase();
  }

  if (user.firstName && user.lastName) {
    // firstName[0] + lastName
    return `${user.firstName[0]}${user.lastName}`.trim().toLowerCase();
  }

  // firstName || lastName
  return `${user.firstName}${user.lastName}`.trim().toLowerCase();
}

// validateProfileFields checks to see if the username and email fields of the
// user profile are valid and editable.
export function validateProfileFields(user, serverSettings) {
  const {
    authentication: { usernameEditableBy, emailEditableBy },
  } = serverSettings;

  const fields = {
    username: true,
    email: true,
    valid() {
      return fields.username && fields.email;
    },
  };

  if (
    user.username.trim() === '' &&
    (usernameEditableBy === 'adminandself' ||
      (user.isAdmin() && usernameEditableBy === 'admin'))
  ) {
    // empty username and editable by the logged-in user
    fields.username = false;
  }

  if (
    !isValidEmail(user.email) &&
    (emailEditableBy === 'adminandself' ||
      (user.isAdmin() && emailEditableBy === 'admin'))
  ) {
    // invalid email and editable by the logged-in user
    fields.email = false;
  }

  return fields;
}
</script>
