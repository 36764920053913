<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel__publish-ide wizard-panel--white">
    <div class="wizard-panel__content">
      <StepHeading :step="step">
        {{ $t('publish.publishIDE.title') }}
      </StepHeading>
      <i18n-t
        keypath="publish.publishIDE.body.pressPublish"
        tag="p"
      >
        <template #publish>
          <span class="emphasize">{{ $t('publish.publishIDE.body.publish') }}</span>
        </template>
      </i18n-t>
      <p class="wizard-panel__publish-ide-screenshot">
        <img
          :alt="$t('publish.publishIDE.screenshotAlt')"
          src="./screenshot.png"
        >
      </p>
      <p class="wizard-panel__publish-ide-server-address-notice">
        {{ $t('publish.publishIDE.body.connectURL') }}
      </p>

      <div class="wizard-panel__copy">
        <pre class="wizard-panel__copy-code"><code>{{ serverAddress }}</code></pre>
        <CopyButton
          class="wizard-panel__copy-button"
          :copy-text="serverAddress"
        />
      </div>

      <p class="wizard-panel__notice">
        <strong>{{ $t('publish.publishIDE.body.warning.note') }}</strong>
        {{ $t('publish.publishIDE.body.warning.text') }}
      </p>

      <p class="wizard-panel__learn-more">
        <a
          :href="learnMoreLink"
          target="_blank"
        >{{ $t('publish.publishIDE.learnMore') }}</a>
      </p>
    </div>
  </div>
</template>

<script>
import CopyButton from '@/components/CopyButton';
import { serverURL, docsPath } from '@/utils/paths';
import StepHeading from '@/views/content/PublishWizard/StepHeading';
import PanelMixin from '../PanelMixin';

export default {
  name: 'PublishIDE',
  components: {
    CopyButton,
    StepHeading,
  },
  mixins: [PanelMixin],
  computed: {
    serverAddress() {
      return serverURL('/');
    },
    learnMoreLink() {
      return docsPath('user/publishing-overview');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'connect-elements/src/styles/shared/_colors';
@import 'connect-elements/src/styles/shared/_mixins';

.wizard-panel {
  &__publish-ide {
    &-screenshot {
      margin-bottom: 0.5rem;
    }
  }

  &__copy {
    display: flex;

    &-code {
      flex-grow: 1;
       margin-bottom: 0;
    }

    &-button {
      align-self: center;
      margin-left: 0.5rem;
    }
  }

  &__learn-more {
    margin-top: 1rem;
  }
}

@include for-small-screens() {
  .wizard-panel__publish-ide-server-address-copy {
    min-width: auto !important;
  }
}
</style>
