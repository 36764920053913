<!-- Copyright (C) 2023 by Posit Software, PBC. -->
<template>
  <RSModal
    :active="true"
    :subject="$t('parametersPanel.legacy.saveAsModal.title')"
    data-automation="params-save-as-modal"
    @close="closeModal"
  >
    <template #content>
      <div>
        <RSInputText
          v-model.trim="name"
          :label="$t('parametersPanel.legacy.saveAsModal.name.title')"
          :maxlength="MAX_LENGTH"
          name="name"
        />
        <RSRadioGroup
          v-model="visibility"
          :title="$t('parametersPanel.legacy.saveAsModal.visibility.title')"
          name="visibility"
          :options="visibilityOptions"
        />
      </div>
    </template>

    <template #controls>
      <RSButton
        :label="$t('parametersPanel.legacy.saveAsModal.button')"
        :disabled="disabled"
        @click="submit"
      />
    </template>
  </RSModal>
</template>

<script>
import RSButton from 'Shared/components/RSButton';
import RSInputText from 'Shared/components/RSInputText';
import RSRadioGroup from 'Shared/components/RSRadioGroup';
import RSModal from 'Shared/components/RSModal';
import {
  LEGACY_PARAMS_SET_SAVING_VARIANT,
  LEGACY_PARAMS_SAVE_AS_VARIANT,
} from '@/store/modules/legacyParams';
import { mapMutations, mapActions } from 'vuex';
import { SHOW_INFO_MESSAGE, SHOW_ERROR_MESSAGE } from '@/store/modules/messages';
import { VISIBILITY_PRIVATE, VISIBILITY_PUBLIC } from '@/constants/variants';

export default {
  name: 'SaveAsModal',
  components: {
    RSButton,
    RSInputText,
    RSRadioGroup,
    RSModal,
  },
  data() {
    return {
      name: '',
      visibility: VISIBILITY_PRIVATE,
      MAX_LENGTH: 50,
    };
  },
  computed: {
    disabled() {
      return !this.name.length;
    },
    visibilityOptions() {
      return [
        {
          value: VISIBILITY_PRIVATE,
          label: this.$t('parametersPanel.legacy.saveAsModal.visibility.private'),
        },
        {
          value: VISIBILITY_PUBLIC,
          label: this.$t('parametersPanel.legacy.saveAsModal.visibility.public'),
        },
      ];
    }
  },
  methods: {
    ...mapMutations({
      setModalState: LEGACY_PARAMS_SET_SAVING_VARIANT,
    }),
    ...mapActions({
      saveAs: LEGACY_PARAMS_SAVE_AS_VARIANT,
      setInfoMessage: SHOW_INFO_MESSAGE,
      setErrorMessage: SHOW_ERROR_MESSAGE,
    }),
    submit() {
      if (this.disabled) {
        return;
      }

      const saveSuccessMsg = this.$t('parametersPanel.legacy.saveSuccess');
      const saveErrMsg = this.$t('parametersPanel.legacy.saveError');
      return this.saveAs({ name: this.name, visibility: this.visibility })
        .then(variant => {
          this.setInfoMessage({ message: saveSuccessMsg });
          return this.$router.push({ params: { id: variant.id } });
        })
        .catch(err => {
          console.error(err);
          this.setErrorMessage({ message: saveErrMsg });
        })
        .finally(() => {
          this.closeModal();
        });
    },
    closeModal() {
      this.setModalState(false);
    },
  },
};
</script>
