// Copyright (C) 2022 by Posit Software, PBC.

const welcome = {
  tagline: 'Welcome to Posit Connect',
  description: 'Distribute data science across your organization',
  getStarted: 'Get Started',
  oneDeploymentButton: 'One button deployment of',
  apps: {
    shiny: 'Shiny applications',
    python: 'Python applications built with',
    r: 'R Markdown documents',
    jupyter: 'Jupyter Notebooks',
    static: 'Static plots and graphs',
  },
  features: {
    manageAccess: 'Manage and control access to the work you\'ve shared with others - and easily see the work they\'ve shared with you',
    handsFree: '"Hands free" scheduling of updates to your documents and automatic email distribution',
  },
  moreInfo: 'For more information on running Posit Connect in your organization please visit',
  errors: {
    login: `There was a problem logging you in. Please try again.<br>If the problem persists, please ask your @.sys:{'system.displayName'} administrator for assistance.`,
    policy: `Your username is not allowed because it is prohibited by policy. Please ask your @.sys:{'system.displayName'} administrator for assistance.`,
    default: 'An unexpected error has occurred'
  }
};

export { welcome };
