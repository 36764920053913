<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div
    :data-automation="dataAutomation"
    class="rs-field"
  >
    <RSInformationToggle
      class="spaceAfter"
      data-automation="environment-management-help"
    >
      <template #title>
        <span class="groupHeadings">
          {{ $t('appSettings.runtime.environmentManagement.title', settings()) }}
        </span>
      </template>
      <template #help>
        <div class="spaceBefore spaceAfter">
          <div class="spaceAfter">
            <i18n-t keypath="appSettings.runtime.environmentManagement.help.p1.t">
              <template #packageManager>
                <code class="code">
                  {{ settings().packageManager }}
                </code>
              </template>
              <template #adminGuide>
                <slot name="adminGuide" />
                <a
                  :href="adminGuide"
                  target="_blank"
                >{{
                  $t('appSettings.runtime.environmentManagement.help.p1.guide')
                }}</a>
              </template>
            </i18n-t>
          </div>
          <div class="spaceAfter">
            {{ $t('appSettings.runtime.environmentManagement.help.p2', settings()) }}
          </div>
          <div class="spaceAfter">
            <i18n-t keypath="appSettings.runtime.environmentManagement.help.p3.t">
              <template #userGuideCli>
                <a
                  :href="userGuideCli"
                  target="_blank"
                >{{
                  $t('appSettings.runtime.environmentManagement.help.p3.guide')
                }}</a>
              </template>
            </i18n-t>
          </div>
          <div class="spaceAfter">
            <i18n-t keypath="appSettings.runtime.environmentManagement.help.p4.t">
              <template #userGuideUi>
                <a
                  :href="userGuideUi"
                  target="_blank"
                >{{
                  $t('appSettings.runtime.environmentManagement.help.p4.guide')
                }}</a>
              </template>
            </i18n-t>
          </div>
        </div>
      </template>
    </RSInformationToggle>
    <div
      data-automation="form-section"
    >
      <div class="sub-section-label">
        {{ $t('appSettings.runtime.environmentManagement.labels.lastRestore') }}
      </div>
      <div
        class="detail"
        data-automation="last-restore-value"
      >
        {{ computedLastRestore }}
      </div>
      <div v-if="showSelection">
        <RSInputSelect
          :value="computedValue"
          :label="$t('appSettings.runtime.environmentManagement.labels.nextTime')"
          :options="options"
          :disabled="readOnly"
          :name="`${appSettingsKey}EnvManagementSelect`"
          class="mt-small"
          :data-automation="`${dataAutomation}-select`"
          @change="onSelectionChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RSInputSelect from 'Shared/components/RSInputSelect';
import RSInformationToggle from 'Shared/components/RSInformationToggle';
import { appSettings } from '../../../../i18n/en/appSettings';
import { docsPath } from '@/utils/paths';

export const OptionsValues = {
  enabled: 'enabled',
  disabled: 'disabled',
  unset: 'unset',
};

export default {
  name: 'DefaultEnvironmentManagement',
  components: {
    RSInputSelect,
    RSInformationToggle,
  },
  props: {
    dataAutomation: {
      type: String,
      required: true,
    },
    appSettingsKey: {
      type: String,
      required: true,
    },
    serverDefault: {
      type: Boolean,
      required: true,
    },
    value: {
      type: Boolean,
      default: null,
    },
    initialValue: {
      type: Boolean,
      default: null,
    },
    lastRestore: {
      type: Boolean,
      default: null,
    },
    showSelection: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: [
        {
          value: OptionsValues.enabled,
          label: this.$t('appSettings.runtime.environmentManagement.options.enabled'),
        },
        {
          value: OptionsValues.disabled,
          label: this.$t('appSettings.runtime.environmentManagement.options.disabled'),
        },
        {
          value: OptionsValues.unset,
          label: this.$t(
            'appSettings.runtime.environmentManagement.options.unset',
            { serverDefault: this.boolToString(this.serverDefault) }
          ),
        },
      ],
    };
  },
  computed: {
    adminGuide() {
      return docsPath(this.settings().adminGuide);
    },
    userGuideCli() {
      return docsPath(this.settings().userGuideCli);
    },
    userGuideUi() {
      return docsPath(this.settings().userGuideUi);
    },
    computedLastRestore() {
      return this.boolToTemplateString(this.lastRestore);
    },
    computedValue() {
      return this.boolToString(this.value);
    }
  },
  methods: {
    // called from parent
    resetState() {
      this.$emit('input', this.initialValue);
    },
    settings() {
      return appSettings.runtime.environmentManagement[this.appSettingsKey];
    },
    // converts a nullable-boolean into the correct template string value
    boolToTemplateString(b) {
      const value = b
        ? this.$t('appSettings.runtime.environmentManagement.options.enabled')
        : this.$t('appSettings.runtime.environmentManagement.options.disabled');
      return b !== null
        ? value
        : this.$t('appSettings.runtime.environmentManagement.lastRestoreUnknown');
    },
    // converts the string value 'enabled' or 'disabled' to the correct boolean
    stringToBool(s) {
      if (s === 'enabled') {
        return true;
      } else if (s === 'disabled') {
        return false;
      }
      return null;
    },
    // converts the boolean value to the correct string ('enabled', 'disabled', or 'unset')
    boolToString(b) {
      if (b !== null) {
        return b
          ? 'enabled'
          : 'disabled';
      }
      return 'unset';
    },
    onSelectionChange(value) {
      this.$emit('input', this.stringToBool(value));
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'connect-elements/src/styles/shared/_colors';

.code {
  background-color: $color-light-grey-2;
}
.sub-section-label {
  font-size: 0.9rem;
  line-height: 1.5rem;
  color: $color-secondary-inverse;
  margin-top: 0.4rem;
  margin-bottom: 0.25rem;
}
.detail {
  border: 1px solid $color-medium-grey;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0.4rem 0.6rem;
  background-color: #fff;
  color: rgb(89, 88, 88);
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  font-size: 13px;
  line-height: 16px;
}
.mt-small {
  margin-top: 0.4rem;
}
.groupHeadings {
  color: $color-heading;
  letter-spacing: .1em;
  font-size: 1em;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}
.spaceBefore {
  margin-top: 0.5rem;
}
.spaceAfter {
  margin-bottom: 0.5rem;
}
</style>
