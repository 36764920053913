<!-- Copyright (C) 2023 by Posit Software, PBC. -->
<template>
  <div>
    <div class="formSection">
      <div class="actions flex">
        <button
          v-if="canEdit"
          class="auxAction"
          :class="{ disabled: !isSaveEnabled }"
          :disabled="!isSaveEnabled"
          data-automation="params-save-btn"
          @click="onSave"
        >
          {{ saveButtonLabel }}
        </button>
        <button
          :class="{ disabled: !isRunEnabled }"
          :disabled="!isRunEnabled"
          data-automation="params-run-btn"
          @click="onRun"
        >
          {{ $t('parametersPanel.legacy.actions.run') }}
        </button>
      </div>
    </div>

    <div class="formSection tight">
      <div class="actionBar panelActionBar">
        <button
          v-if="isNewEnabled && canEdit"
          class="action new"
          data-automation="params-clone-variant-btn"
          @click="onNew"
        >
          {{ $t('parametersPanel.legacy.actions.new') }}
        </button>
        <button
          v-if="isRenameEnabled && canEdit"
          class="action rename"
          data-automation="params-rename-btn"
          @click="onRename"
        >
          {{ $t('parametersPanel.legacy.actions.rename') }}
        </button>
        <button
          v-if="isDeleteEnabled && canEdit"
          class="action delete"
          data-automation="params-delete-btn"
          @click="confirmDelete = true"
        >
          {{ $t('parametersPanel.legacy.actions.delete') }}
        </button>
        <button
          v-if="isRevertEnabled"
          class="action revert"
          data-automation="params-revert-btn"
          @click="onRevert"
        >
          {{ $t('parametersPanel.legacy.actions.revert') }}
        </button>
      </div>
    </div>

    <ConfirmModal
      v-if="confirmDelete"
      :subject="$t('parametersPanel.legacy.deleteConfirmModal.subject')"
      :confirmation="$t('parametersPanel.legacy.deleteConfirmModal.confirmation')"
      data-automation="legacy-params__confirm-delete"
      @confirm="onDelete"
      @cancel="confirmDelete = false"
    >
      <EmbeddedStatusMessage
        type="warning"
        class="legacy-params__delete-warning"
        :message="$t('parametersPanel.legacy.deleteConfirmModal.warning')"
        :show-close="false"
      />

      <i18n-t
        keypath="parametersPanel.legacy.deleteConfirmModal.details"
        tag="p"
      >
        <code>{{ currentVariantName }}</code>
      </i18n-t>
    </ConfirmModal>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import {
  LEGACY_PARAMS_RUN_REPORT,
  LEGACY_PARAMS_SET_RENAMING_VARIANT,
  LEGACY_PARAMS_SET_SAVING_VARIANT,
  LEGACY_PARAMS_REVERT_CHANGES,
  LEGACY_PARAMS_NEW,
  LEGACY_PARAMS_SAVE_VARIANT,
  LEGACY_PARAMS_DELETE_VARIANT,
} from '@/store/modules/legacyParams';
import {
  LOAD_RENDERINGS_HISTORY,
  TOGGLE_PANELS,
  PARAMETERS_PANEL,
  CLOSE_PANEL,
} from '@/store/modules/contentView';
import { SHOW_INFO_MESSAGE, SHOW_ERROR_MESSAGE } from '@/store/modules/messages';
import ConfirmModal from '@/components/ConfirmModal';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage.vue';
import { isEmpty } from 'lodash';

export default {
  name: 'ParametersActions',
  components: {
    ConfirmModal,
    EmbeddedStatusMessage,
  },
  data: () => ({
    confirmDelete: false,
  }),
  computed: {
    ...mapState({
      currentUser: state => state.currentUser.user,
      app: state => state.contentView.app,
      currentVariant: state => state.parameterization.currentVariant,
      formLoaded: state => state.legacyParams.form.loaded,
      isDirty: state => state.legacyParams.form.dirty,
      isBusy: state => state.legacyParams.isBusy,
      isNew: state => state.legacyParams.isNew,
      adHocRun: state => state.legacyParams.adHocRun,
    }),
    isDefault() {
      return this.currentVariant.isDefault;
    },
    canEdit() {
      return this.currentUser.isAppEditor(this.app);
    },
    isNewEnabled() {
      return !this.isBusy && this.formLoaded;
    },
    isRenameEnabled() {
      return !this.isDirty && !this.isNew;
    },
    isDeleteEnabled() {
      return !this.isDefault && !this.isDirty;
    },
    isRevertEnabled() {
      return this.isDirty;
    },
    isSaveEnabled() {
      return this.isNew ?
        !isEmpty(this.adHocRun) :
        !isEmpty(this.adHocRun) && this.isDirty;
    },
    isRunEnabled() {
      return !this.isBusy;
    },
    saveButtonLabel() {
      return this.isDefault || this.isNew ? this.$t('parametersPanel.legacy.actions.saveAs') : this.$t('parametersPanel.legacy.actions.save');
    },
    currentVariantName() {
      return this.currentVariant.name;
    },
  },
  methods: {
    ...mapMutations({
      openRenameVariant: LEGACY_PARAMS_SET_RENAMING_VARIANT,
      openSaveVariant: LEGACY_PARAMS_SET_SAVING_VARIANT,
    }),
    ...mapActions({
      run: LEGACY_PARAMS_RUN_REPORT,
      save: LEGACY_PARAMS_SAVE_VARIANT,
      revertChanges: LEGACY_PARAMS_REVERT_CHANGES,
      createVariant: LEGACY_PARAMS_NEW,
      deleteVariant: LEGACY_PARAMS_DELETE_VARIANT,
      togglePanels: TOGGLE_PANELS,
      loadRenderings: LOAD_RENDERINGS_HISTORY,
      setInfoMessage: SHOW_INFO_MESSAGE,
      setErrorMessage: SHOW_ERROR_MESSAGE,
    }),
    async onNew() {
      await this.createVariant();
      this.$router.replace({
        params: {
          id: this.currentVariant.id,
        },
      });
    },
    onDelete() {
      this.confirmDelete = false;

      return this.deleteVariant()
        .then(() => {
          return this.loadRenderings(this.currentVariant.id);
        })
        .then(() => {
          this.$router.push({ params: { id: this.currentVariant.id } });
          this.setInfoMessage({ message: this.$t('parametersPanel.legacy.deleteSuccess') });
          return this.togglePanels({
            panel: PARAMETERS_PANEL,
            action: CLOSE_PANEL,
          });
        })
        .catch(() => {
          this.setErrorMessage({ message: this.$t('parametersPanel.legacy.deleteError') });
        });
    },
    onRename() {
      this.openRenameVariant(true);
    },
    onRevert() {
      this.revertChanges();
    },
    onSave() {
      if (this.isNew || this.isDefault) {
        this.openSaveVariant(true);
      } else {
        this.save()
          .then(() => {
            this.setInfoMessage({ message: this.$t('parametersPanel.legacy.saveSuccess') });
          })
          .catch(err => {
            console.error(err);
            this.setErrorMessage({ message: this.$t('parametersPanel.legacy.saveError') });
          });
      }
    },
    onRun() {
      this.run(this.currentVariant.id);
    },
  }
};
</script>

<style lang="scss" scoped>
.legacy-params__delete-warning {
  margin-bottom: 1rem;
}
</style>
