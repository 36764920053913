/* istanbul ignore file */

// Copyright (C) 2022 by Posit Software, PBC.

/**
 * Ignoring coverage for this file as it serves as an entry point
 */

// Initialize Vue.js runtime environment
import { createApp } from 'vue';
import flatPickr from 'vue-flatpickr-component';

// Initialize Axios for our Vue.js app
import '@/api/axiosSetup';

// Redux and angular router plugins
import VueIframeBus from '@/plugins/iframeBus';

// Vuex store
import { store } from '@/store';

// Vue router
import router from '@/router';

// Initialize / Configure vue-i18n
import { vueI18n as i18n } from '@/i18n/index';

import App from '@/App';

const app = createApp(App);
app
  .use(VueIframeBus)
  .use(router)
  .use(store)
  .use(i18n)
  .component('flat-pickr', flatPickr)
  .mount('#connectui');
