// Copyright (C) 2022 by Posit Software, PBC.

const confirmUser = {
  awaitingConfirmationTitle: 'En espera de confirmación',
  leadMessage: 'Debe confirmar tu cuenta antes de continuar.',
  leadMessageInstructions: 'Deberías haber recibido un correo electrónico con un enlace que te permitirá confirmar tu cuenta.',
  timeEmailArrivalMessage: 'El correo electrónico puede tardar unos minutos en llegar; también es posible que tenga que revisar la carpeta de correos no deseados.',
  resendConfirmationEmail: 'Reenviar correo de confirmación',
  requiresConfirmationTitle: 'Requiere confirmación',
  askYourAdminMessagePart1: `Pide a tu administrador de @.sys:{'system.displayName'} que visite tu perfil de usuario y obtenga un <strong>enlace de confirmación de cuenta</strong> para ti. Tu puedes`,
  askYourAdminMessagePart2: `de tu perfil de usuario y páseselo a un administrador.`,
  copyLink: 'copiar este enlace',
  copyLinkMessage: 'Se ha copiado en el portapapeles un enlace a su perfil de usuario. Asegúrate de pasárselo a un administrador sin visitarlo.',
  confirmationEmailSent: 'Correo electrónico de confirmación reenviado.'
};

export { confirmUser };
