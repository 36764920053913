<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="band rsc-content">
    <div
      data-automation="content-list"
      class="bandContent mainPage requiresAuth rsc-content-list"
    >
      <div
        :class="{ 'rsc-content-list__main--fill': !isOptionsVisible }"
        class="rsc-content-list__main"
      >
        <ContentListHeader
          v-if="initialDataLoaded"
          :user-role="currentUser.userRole"
          :has-content="hasContent"
          @refresh="fetchContent"
          @view-change="fetchContent"
        />

        <EmbeddedStatusMessage
          v-if="initialDataLoaded"
          :message="removedTagsMessage"
          type="warning"
          class="rsc-content-list__warning"
          @close="removedTagsMessage = ''"
        />

        <EmbeddedStatusMessage
          v-show="isLoading"
          :show-close="false"
          :message="$t('content.list.labels.loading')"
          type="activity"
        />

        <div
          v-show="!isLoading"
          class="rsc-content-list__main__content"
          tabindex="-1"
        >
          <MainContent
            v-if="initialDataLoaded"
            :content="content"
            @fetch="fetchContent"
          />
          <EmptyContent
            v-if="noContent"
            :is-viewer="currentUser.isViewer()"
            :no-tags-found="noTagsFound"
            @reset="fetchContent"
          />
        </div>
      </div>
      <ContentOptionsPanel
        v-if="initialDataLoaded"
        v-show="isOptionsVisible"
        ref="optionsPanel"
        :current-user="currentUser"
        @filter="fetchContent"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState, mapMutations, mapActions } from 'vuex';
import ContentOptionsPanel from './ContentOptionsPanel';
import MainContent from './MainContent';
import EmptyContent from './EmptyContent';
import { getContent, buildFilters } from '@/api/content';
import { ExecutionTypeK8S } from '@/api/serverSettings';
import { getTagsTree } from '@/api/tags';
import AppRoles from '@/api/dto/appRole';
import {
  CONTENT_LIST_UPDATE_VIEW_TYPE,
  CONTENT_LIST_UPDATE_FILTER,
  CONTENT_LIST_UPDATE_START_PAGE,
  CONTENT_LIST_UPDATE_TAGS_TREE,
} from '@/store/modules/contentList';
import {
  SHOW_ERROR_MESSAGE,
  SET_ERROR_MESSAGE_FROM_API,
} from '@/store/modules/messages';
import { pushParamsToUrl, handleUrlFilters } from './contentListUtils';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import ContentListHeader from './ContentListHeader';
import {
  getEnvironments,
} from '@/api/environments';

export default {
  name: 'ContentListView',
  components: {
    ContentListHeader,
    ContentOptionsPanel,
    MainContent,
    EmbeddedStatusMessage,
    EmptyContent,
  },
  data() {
    return {
      content: { applications: [] },
      initialDataLoaded: false,
      showLoadingMessage: false,
      isFetching: false,
      removedTagsMessage: '',
      noTagsFound: false,
      initialized: null, // promise chain initialized during create (easy unit testing)
    };
  },
  computed: {
    ...mapState({
      contentList: state => state.contentList,
      isOptionsVisible: state => state.contentList.isOptionsVisible,
      currentUser: state => state.currentUser.user,
      serverSettings: state => state.server.settings,
    }),
    isLoading() {
      return !this.initialDataLoaded || this.showLoadingMessage;
    },
    noContent() {
      return Boolean(
        this.initialDataLoaded &&
          !this.isFetching &&
          !this.content.applications.length
      );
    },
    hasContent() {
      return Boolean(this.content.applications.length);
    },
  },
  watch: {
    isOptionsVisible(isVisible) {
      if (isVisible) {
        this.$nextTick(() => this.$refs.optionsPanel.focus());
      }
    },
  },
  created() {
    this.cancelation = null;
    this.init();
  },
  methods: {
    ...mapMutations({
      updateViewType: CONTENT_LIST_UPDATE_VIEW_TYPE,
      updateFilter: CONTENT_LIST_UPDATE_FILTER,
      updateStartPage: CONTENT_LIST_UPDATE_START_PAGE,
      updateTagsTree: CONTENT_LIST_UPDATE_TAGS_TREE,
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    ...mapActions({
      setErrorMessage: SHOW_ERROR_MESSAGE,
    }),
    init() {
      let viewType;
      this.initialized = getTagsTree()
        .then(tags => {
          const storeFilters = JSON.parse(
            JSON.stringify(this.contentList.filters)
          );
          if (this.contentList.viewType) {
            viewType = this.contentList.viewType;
          } else {
            viewType = this.serverSettings.defaultContentListView;
          }
          const {
            filters,
            tagsTree,
            removedTagsMsg,
            noTagsFound,
            viewSetting,
          } = handleUrlFilters(tags, storeFilters, viewType);
          this.noTagsFound = noTagsFound;
          filters.forEach(this.updateFilter);
          this.updateTagsTree(tagsTree);
          this.updateViewType(viewSetting.value);
          this.removedTagsMessage = removedTagsMsg;

          // only fetch content on init if all tags from the URL were not deleted
          if (!noTagsFound) {
            return this.fetchContent();
          }
        })
        .then(async() => {
          if (
            this.serverSettings.enableImageManagement &&
            this.serverSettings.executionType === ExecutionTypeK8S
          ) {
            const environments = await getEnvironments();
            if (environments.length === 0) {
              this.setErrorMessage({
                message: this.$t('content.noOffHostExecutionImages'),
              });
            }
          }
        })
        .catch(this.setErrorMessageFromAPI)
        .finally(() => (this.initialDataLoaded = true));
    },
    resetLoaders() {
      this.showLoadingMessage = false;
      this.isFetching = false;
      this.cancelation = null;
    },
    fetchContent({ count, cont } = {}) {
      this.isFetching = true;
      const timeoutId = setTimeout(() => (this.showLoadingMessage = true), 300);
      const {
        search,
        visibility,
        contentType,
        tags,
      } = this.contentList.filters;
      const start = this.contentList.startPage;
      const deployedContent = AppRoles.isViewer(visibility);
      const ownedByUser = AppRoles.isEditor(visibility) ? this.currentUser.guid : null;
      const filters = buildFilters({
        minVisibilityAppRole: visibility,
        ownedByUser,
        contentType,
        tags: Object.keys(tags).map(key => tags[key].filter),
        deployedContent,
      });
      const viewType = this.contentList.viewType;

      pushParamsToUrl({ viewType, ...this.contentList.filters });

      return this.getData({ filters, search, count, start, cont })
        .then(data => {
          this.content = data;
          this.resetLoaders();
        })
        .catch(err => {
          // skip error handling & resetting loaders for cancelled requests
          if (!axios.isCancel(err)) {
            this.setErrorMessageFromAPI(err);
            this.resetLoaders();
          }
        })
        .finally(() => clearTimeout(timeoutId));
    },
    getData({ filters, search, count, start, cont }) {
      if (this.cancelation) {
        this.cancelation.cancel(); // cancel in-progress request
      }

      this.cancelation = axios.CancelToken.source();
      const myCancelation = this.cancelation;
      const { token: cancelToken } = this.cancelation;
      const params = { filters, search, count, start, cont, cancelToken };

      return getContent(params).then(data => {
        // use incoming response only if this is the latest request, otherwise toss it
        if (myCancelation === this.cancelation) {
          // when we receive an invalid page (i.e. start > total), refetch data using start = 0
          if (!data.applications.length && start) {
            this.updateStartPage(0); // reset back to the first page
            return getContent({ ...params, start: 0 });
          }

          return data;
        }

        // since this is not the latest request, ignore stale incoming response
        // and use existing data instead
        return this.content;
      });
    },
  },
};
</script>
