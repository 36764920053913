<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    class="access"
    data-automation="app-settings__access"
  >
    <div
      v-if="loadingError"
      class="formSection"
      data-automation="loading-error"
    >
      <p>{{ $t('appSettings.access.status.error') }}</p>
    </div>

    <ConfirmationPanel
      :enabled="enableConfirmation"
      :visible="showConfirmation"
      @save="save"
      @discard="discard"
    />
    <MessageBox
      v-if="requestByToken"
      small
      :closeable="requestMsgCanBeClosed"
      @close="clearPermissionRequest"
    >
      <i18n-t
        :keypath="requestByTokenMsg.path"
        tag="span"
      >
        <strong>
          {{ requestByTokenMsg.displayName }}
        </strong>
        <strong>
          {{ requestByTokenMsg.role }}
        </strong>
      </i18n-t>
    </MessageBox>

    <!-- Sharing -->
    <div>
      <RSInformationToggle
        class="spaceAfter"
      >
        <template #title>
          <span class="groupHeadings">
            {{ $t('appSettings.access.sections.sharing.title') }}
          </span>
        </template>
        <template #help>
          <div class="spaceBefore spaceAfter">
            <p>{{ $t('appSettings.access.sections.sharing.help.lead') }}</p>
            <p>{{ $t('appSettings.access.sections.sharing.help.specificUsers') }}</p>
            <p v-if="anonymousServersHelp">
              <i18n-t keypath="appSettings.access.sections.sharing.help.license.anonymousServers">
                <template #adminGuide>
                  <a
                    :href="licensingDocumentation"
                    target="_blank"
                  >{{
                    $t('appSettings.access.sections.sharing.help.license.learnMore')
                  }}</a>
                </template>
              </i18n-t>
            </p>
            <p v-if="anonymousBrandingHelp">
              <i18n-t keypath="appSettings.access.sections.sharing.help.license.anonymousBranding">
                <template #adminGuide>
                  <a
                    :href="licensingDocumentation"
                    target="_blank"
                  >{{
                    $t('appSettings.access.sections.sharing.help.license.learnMore')
                  }}</a>
                </template>
              </i18n-t>
            </p>
          </div>
        </template>
      </RSInformationToggle>

      <div class="formSection">
        <AccessType
          v-if="showAccessType && type"
          :read-only="!canEditSettings"
          :visible-types="visibleAccessTypes"
          :is-worker-app="isWorkerApp"
          :anonymous-servers="anonymousServers"
          :anonymous-branding="anonymousBranding"
        />

        <SearchBox
          v-if="showSearchBox"
          :label="$t('appSettings.access.labels.shareWith')"
          :server-settings="serverSettings"
          :exclusion-set="new Set([ownerGuid])"
          :remote-lookup="false"
          data-automation="as-search"
          name="as-add-access"
          type="all"
          @select="addPrincipal"
        >
          <template #help>
            <strong>{{ $t('appSettings.access.messages.cantFindSomething.label') }}</strong>
            <i18n-t
              :keypath="cantFindSomethingMsg"
              tag="span"
            >
              <router-link :to="{ name: 'people.users' }">
                {{ $t('common.users') }}
              </router-link>
              <router-link :to="{ name: 'people.groups' }">
                {{ $t('common.groups') }}
              </router-link>
            </i18n-t>
          </template>
        </SearchBox>

        <PrincipalList
          v-if="showPrincipalList && owner"
          :principals="activePrincipals"
          :can-edit-permissions="canEditPermissions"
          :current-user-guid="currentUser.guid"
          :owner="owner"
          :content-type="contentType"
          @updatePrincipal="updatePrincipal"
          @removePrincipal="removePrincipal"
        />
      </div>
    </div>

    <!-- Vanity URL -->
    <div>
      <RSInformationToggle>
        <template #title>
          <span class="groupHeadings">
            {{ $t('appSettings.access.sections.contentUrl.title') }}
          </span>
        </template>
        <template #help>
          <p>{{ $t('appSettings.access.sections.contentUrl.help.intro') }}</p>
          <p>
            <i18n-t keypath="appSettings.access.sections.contentUrl.help.details">
              <template #userGuide>
                <a
                  :href="contentUrlDocumentation"
                  target="_blank"
                >{{
                  $t('appSettings.access.sections.contentUrl.help.userGuide')
                }}</a>
              </template>
            </i18n-t>
          </p>
          <p>{{ $t('appSettings.access.sections.contentUrl.help.admin') }}</p>
        </template>
      </RSInformationToggle>

      <ContentUrl
        v-if="showContentUrl"
        :app-guid="app.guid"
        :can-customize="canAddCustomUrl"
        :can-edit-settings="canEditSettings"
        :vanity-path="activeCustomUrl"
        @update="updateCustomUrl"
      />
    </div>

    <!-- RunAs & Service Account help text -->
    <div
      v-if="doneLoading && app && app.isExecutable()"
      data-automation="runas-moved-help"
    >
      <span class="groupHeadings">
        {{ $t('appSettings.access.sections.processExecution.title') }}
      </span>
      <p class="spaceBefore">
        {{ $t('appSettings.access.sections.processExecution.moved') }}
      </p>
      <hr class="rs-divider">
    </div>

    <!-- Execution Environment help text -->
    <div
      v-if="showExecutionEnvironment"
      data-automation="exec-env-moved-help"
    >
      <span class="groupHeadings">
        {{ $t('appSettings.runtime.executionEnvironment.title') }}
      </span>
      <p class="spaceBefore">
        {{ $t('appSettings.runtime.executionEnvironment.moved') }}
      </p>
      <hr class="rs-divider">
    </div>

    <!-- Supportive popups and messages -->
    <EmbeddedStatusMessage
      v-if="loading"
      :message="$t('appSettings.access.status.loading')"
      :show-close="false"
      type="activity"
      data-automation="loading"
    />
    <BecomeViewerWarning
      v-if="showBecomeViewerWarning"
      :is-admin="isAdmin"
      @close="closeBecomeViewerWarning"
      @confirm="confirmBecomeViewer"
    />
    <RemovePrincipalWarning
      v-if="showRemovePrincipalWarning"
      :is-admin="isAdmin"
      @close="closeRemovePrincipalWarning"
      @confirm="confirmRemovePrincipal"
    />
  </div>
</template>

<script>
import ContentUrl from './ContentUrl';
import SearchBox from './SearchBox';
import AccessType from './AccessType';
import PrincipalList from './PrincipalList';
import BecomeViewerWarning from './BecomeViewerWarning';
import RemovePrincipalWarning from './RemovePrincipalWarning';
import ConfirmationPanel from '@/views/content/settings/ConfirmationPanel';

import RSInformationToggle from 'Shared/components/RSInformationToggle';
import {
  updateContent,
  addUser,
  addGroup,
  removeUser,
  removeGroup,
} from '@/api/app';
import { createCustomUrl, updateCustomUrl, deleteCustomUrl } from '@/api/customUrl';
import { addNewRemoteUser, getUser } from '@/api/users';
import { addNewRemoteGroup } from '@/api/groups';
import { getApplicationsSettings, ExecutionTypeK8S } from '@/api/serverSettings';
import { joinPaths, docsPath, getHashQueryParameter } from '@/utils/paths';
import { mapState, mapMutations, mapActions } from 'vuex';
import {
  ACCESS_SETTINGS_UPDATE_TYPE,
  ACCESS_SETTINGS_UPDATE_MODE,
  ModeType,
} from '@/store/modules/accessSettings';
import {
  LOAD_CONTENT_VIEW,
  SET_CONTENT_FRAME_RELOADING,
} from '@/store/modules/contentView';
import {
  SHOW_ERROR_MESSAGE,
  SET_ERROR_MESSAGE_FROM_API,
  CLEAR_STATUS_MESSAGE,
} from '@/store/modules/messages';
import AccessTypes from '@/api/dto/accessType';
import { User } from '@/api/dto/user';
import AppRoles from '@/api/dto/appRole';
import ApiErrors from '@/api/errorCodes';
import { getRequestAccessByToken } from '@/api/permissions';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage.vue';
import MessageBox from '@/components/MessageBox';

export default {
  name: 'AccessSettings',
  components: {
    ContentUrl,
    ConfirmationPanel,
    SearchBox,
    MessageBox,
    AccessType,
    PrincipalList,
    BecomeViewerWarning,
    RemovePrincipalWarning,
    EmbeddedStatusMessage,
    RSInformationToggle,
  },
  data() {
    return {
      loading: true,
      loadingError: false,
      canAddCustomUrl: false,
      canEditSettings: false,
      canEditPermissions: false,
      contentType: '',
      owner: null,
      isAdmin: false,
      isPublisher: false,
      isWorkerApp: false,
      anonymousServers: false,
      anonymousBranding: false,
      anonymousServersHelp: false,
      anonymousBrandingHelp: false,
      visibleAccessTypes: [],
      ownerGuid: null,
      isExecutable: false,
      initial: {
        type: null,
        mode: null,
        principals: [],
        customUrl: null,
      },
      activePrincipals: [],
      activeCustomUrl: null,
      initialized: new Promise(() => {}),
      isSaving: false,
      showBecomeViewerWarning: false,
      showRemovePrincipalWarning: false,
      pendingBecomeViewerPrincipal: null,
      pendingRemovePrincipal: null,
      requestByToken: null,
    };
  },
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      currentUser: state => state.currentUser.user,
      activeState: state => state.accessSettings,
      serverSettings: state => state.server.settings,
      currentVariant: state => state.parameterization.currentVariant,
      type: state => state.accessSettings.type,
    }),
    doneLoading() {
      return !this.loading && !this.loadingError;
    },
    showAccessType() {
      return this.doneLoading;
    },
    showSearchBox() {
      return this.doneLoading && this.canEditPermissions;
    },
    showPrincipalList() {
      return this.doneLoading;
    },
    showContentUrl() {
      return this.doneLoading && this.app;
    },
    typeIsChanging() {
      return this.initial.type !== this.activeState.type;
    },
    customUrlIsChanging() {
      return this.initial.customUrl?.pathPrefix !== this.activeCustomUrl;
    },
    listIsChanging() {
      return this.activePrincipals.some(this.isChanged);
    },
    showExecutionEnvironment() {
      if (!this.app) {
        return false;
      }

      return (
        this.doneLoading
        && this.app.isExecutable()
        && (this.currentUser.isAdmin() || this.currentUser.isPublisher())
        && this.serverSettings.enableImageManagement
        && this.serverSettings.executionType === ExecutionTypeK8S
      );
    },
    showConfirmation() {
      return this.doneLoading && (
        this.typeIsChanging ||
        this.listIsChanging ||
        this.runAsIsChanging ||
        this.customUrlIsChanging
      );
    },
    enableConfirmation() {
      return this.showConfirmation && !this.isSaving;
    },
    cantFindSomethingMsg() {
      const canAddGroups =
        this.currentUser.canCreateGroup(this.serverSettings) ||
        this.currentUser.canAddRemoteGroup(this.serverSettings);
      const canAddUsers =
        this.currentUser.canAddNewUser(this.serverSettings) ||
        this.currentUser.canAddRemoteUser(this.serverSettings);
      let msgPath = 'appSettings.access.messages.cantFindSomething.forNoPermissions';
      if (this.isAdmin || (canAddGroups && canAddUsers)) {
        msgPath = 'appSettings.access.messages.cantFindSomething.forAdmin';
      } else if (canAddUsers) {
        msgPath = 'appSettings.access.messages.cantFindSomething.forUsersPermissions';
      } else if (canAddGroups) {
        msgPath = 'appSettings.access.messages.cantFindSomething.forGroupsPermissions';
      }
      return msgPath;
    },
    requestByTokenMsg() {
      const msg = {
        path: '',
        displayName: '',
        role: '',
      };
      if (this.requestByToken) {
        const roleSlug = this.getRequestRoleSlug();
        if (this.requestByToken.approved) {
          msg.path = 'appSettings.access.messages.principalAlreadyAdded';
        } else {
          msg.path = 'appSettings.access.messages.addPrincipalByToken';
        }
        msg.displayName = this.requestByToken.user.displayName;
        msg.role = this.$t(`appSettings.access.labels.${roleSlug}`);
      }
      return msg;
    },
    requestMsgCanBeClosed() {
      return this.requestByToken && this.requestByToken.approved;
    },
    licensingDocumentation() {
      return docsPath('admin/licensing/');
    },
    contentUrlDocumentation() {
      return docsPath('user/content-settings/#custom-url');
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations({
      updateType: ACCESS_SETTINGS_UPDATE_TYPE,
      updateMode: ACCESS_SETTINGS_UPDATE_MODE,
      reloadFrame: SET_CONTENT_FRAME_RELOADING,
      clearStatusMessage: CLEAR_STATUS_MESSAGE,
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    ...mapActions({
      resetApp: LOAD_CONTENT_VIEW,
      setErrorMessage: SHOW_ERROR_MESSAGE,
    }),
    init() {
      // When admin self-grants access via embedded content
      // Re-fetch this component's data to update the ACL.
      this.$onAdminSelfGrantAccessDo(this.reloadAccessData);
      this.loading = true;
      this.loadingError = false;
      this.clearStatusMessage();
      this.initialized = this.getData()
        .then(this.prefillPermissionRequestIfAny)
        .catch(e => {
          this.loadingError = true;
          this.setErrorMessageFromAPI(e);
        })
        .finally(() => (this.loading = false));
    },
    getData() {
      // TODO: getting applications settings can be done in
      // within the store action resolving the content data -> LOAD_CONTENT_VIEW
      return getApplicationsSettings()
        .then(appSettings => {
          const { app, currentUser, serverSettings } = this;
          if (!app) {
            return;
          }

          this.ownerGuid = app.ownerGuid;
          this.isExecutable = app.isExecutable();
          this.contentType = app.contentType();
          this.canEditSettings = currentUser.canEditAppSettings(app);
          this.canEditPermissions = currentUser.canEditAppPermissions(app);
          this.canAddCustomUrl = currentUser.canAddVanities();
          this.isAdmin = currentUser.isAdmin();
          this.isPublisher = currentUser.isPublisher();

          this.visibleAccessTypes = appSettings.accessTypes;

          this.isWorkerApp = this.app.hasWorker();
          this.anonymousServers = serverSettings.license.anonymousServers;
          this.anonymousBranding = serverSettings.license.anonymousBranding;
          this.anonymousServersHelp = this.isWorkerApp && !this.anonymousServers;
          this.anonymousBrandingHelp = !this.isWorkerApp && this.anonymousBranding;

          // Get app owner. Viewers are not allowed to call getUser, and we only need fields
          // that we already have available on `app`, so just create a User DTO from those.
          // If ViewersCanOnlySeeThemselves is true then owner information has been stripped;
          // use a placeholder name.
          if (app.ownerUsername === '' &&
            app.ownerFirstName === '' &&
            app.ownerLastName === '') {
            app.ownerLastName = this.$t('appSettings.access.labels.undisclosedOwner');
          }
          this.owner = new User({
            firstName: app.ownerFirstName,
            lastName: app.ownerLastName,
            username: app.ownerUsername,
            guid: app.ownerGuid,
            email: app.ownerEmail,
            locked: app.ownerLocked,
          });

          this.initial.mode =
            app.accessType === AccessTypes.Acl
              ? ModeType.VIEWER
              : ModeType.OWNER;

          this.initial.type = app.accessType;

          // Sort existing principals by name. Newly added principals should appear
          // at the top of the list, so sorting should not be repeated.
          this.initial.principals = [...app.users, ...app.groups].sort((a, b) => {
            const aname = a.displayName ? a.displayName : a.name;
            const bname = b.displayName ? b.displayName : b.name;
            if (aname < bname) {
              return -1;
            }
            if (aname > bname) {
              return 1;
            }
            return 0;
          });

          if (app.vanities && app.vanities.length > 0) {
            this.initial.customUrl = app.vanities[0];
          } else {
            // it's necessary to reset this here because we may be reloading
            // after deleting an existing custom url
            this.initial.customUrl = { pathPrefix: '' };
          }
        })
        .then(this.resetActive);
    },
    async prefillPermissionRequestIfAny() {
      const queryFound = getHashQueryParameter('permission_request');
      if (!queryFound) {
        return;
      }
      try {
        const [requestToken] = queryFound;
        const request = await getRequestAccessByToken({
          contentGUID: this.app.guid,
          requestToken,
        });
        const requester = await getUser(request.requesterGuid);
        this.requestByToken = { ...request, user: requester };
        if (request.approved) {
          return;
        }
        this.addPrincipal({
          principal: requester,
          mode: request.requestedRole,
          highlight: true,
        });
      } catch (err) {
        if (err.response && err.response.status !== 404) {
          this.setErrorMessageFromAPI(err);
        }
      }
    },
    resetActive() {
      this.updateType(this.initial.type);
      this.updateMode(this.initial.mode);

      this.activePrincipals = this.initial.principals.map(principal => ({
        ...principal,
        type: principal instanceof User ? 'user' : 'group',
        added: false,
        changed: false,
        deleted: false,
      }));

      this.activeCustomUrl = this.initial.customUrl?.pathPrefix;
      this.requestByToken = null;
    },
    isChanged(principal) {
      return principal.deleted || principal.changed;
    },
    containsCurrentUser(principal) {
      // Returns true if principal is the current user, or is a group containing the current user.
      return principal.guid === this.currentUser.guid ||
        principal.members && principal.members.some(m => m.guid === this.currentUser.guid);
    },
    resolvePrincipal(principal) {
      // If the principal does not have a guid, it is a remote user that hasn't been
      // created in Connect yet, so try to add it. The returned object will have a guid.
      if (principal.guid) {
        return Promise.resolve(principal);
      } else if (principal instanceof User) {
        return addNewRemoteUser(principal.tempTicket);
      }
      return addNewRemoteGroup(principal.tempTicket);
    },
    getRequestRoleSlug() {
      let roleSlug = this.requestByToken.requestedRole;
      if (this.requestByToken.approved) {
        // Because the app role requested could be different than the role applied
        const approvedPrincipal = this.activePrincipals.find(
          p => p.guid === this.requestByToken.requesterGuid
        );
        roleSlug = AppRoles.stringOf(approvedPrincipal.appRole);
      }
      if (roleSlug === ModeType.OWNER) {
        roleSlug = 'collaborator';
      }
      return roleSlug;
    },
    addPrincipal({ principal, mode, highlight = false }) {
      const role = AppRoles.of(mode);

      // Ensure the principal exists in Connect before adding
      return this.resolvePrincipal(principal)
        .then(resolvedPrincipal => {
          const existingPrincipal = this.activePrincipals
            .find(({ guid }) => guid === resolvedPrincipal.guid);

          if (existingPrincipal) {
            if (existingPrincipal.appRole !== role) {
              // role is changing
              this.updatePrincipal({ principal: existingPrincipal, mode });
            } else {
              // maybe they deleted and then changed their mind
              existingPrincipal.deleted = false;
            }
          } else {
            this.activePrincipals.unshift({
              ...resolvedPrincipal,
              type: resolvedPrincipal instanceof User ? 'user' : 'group',
              appRole: role,
              added: true,
              changed: true,
              deleted: false,
              highlight,
            });
          }
        })
        .catch(this.setErrorMessageFromAPI);
    },
    updatePrincipal({ principal, mode }) {
      const role = AppRoles.of(mode);
      // If a collaborator is becoming a viewer, ask for confirmation
      if (
        this.containsCurrentUser(principal) &&
        AppRoles.isOwner(principal.appRole) &&
        AppRoles.isViewer(role)
      ) {
        this.pendingBecomeViewerPrincipal = principal;
        this.showBecomeViewerWarning = true;
      } else {
        principal.changed = true;
        principal.deleted = false;
        principal.appRole = role;
      }
    },
    closeBecomeViewerWarning() {
      this.showBecomeViewerWarning = false;
      this.pendingBecomeViewerPrincipal = null;
    },
    confirmBecomeViewer() {
      this.showBecomeViewerWarning = false;
      const principal = this.pendingBecomeViewerPrincipal;
      principal.changed = true;
      principal.deleted = false;
      principal.appRole = AppRoles.Viewer;
      this.pendingBecomeViewerPrincipal = null;
    },
    removePrincipal(principal) {
      if (this.containsCurrentUser(principal)) {
        // If a user is removing themself, ask for confirmation
        this.pendingRemovePrincipal = principal;
        this.showRemovePrincipalWarning = true;
      } else if (principal.added) {
        // Deleting a principal that was never saved will throw an error.
        // Pull them from the list instead.
        const idx = this.activePrincipals.findIndex(({ guid }) => guid === principal.guid);
        this.activePrincipals.splice(idx, 1);
      } else {
        principal.deleted = true;
      }
    },
    closeRemovePrincipalWarning() {
      this.showRemovePrincipalWarning = false;
      this.pendingRemovePrincipal = null;
    },
    confirmRemovePrincipal() {
      this.showRemovePrincipalWarning = false;
      // This looks weird, but this.pendingRemovePrincipal is a reference to a principal in
      // this.activePrincipals. So we change it, and then remove the temporary reference.
      this.pendingRemovePrincipal.deleted = true;
      this.pendingRemovePrincipal = null;
    },
    updateCustomUrl(value) {
      this.activeCustomUrl = value;
    },
    saveCustomUrl() {
      if (this.customUrlIsChanging) {
        // normalize new path---make sure it starts and ends with a single `/`
        const path = joinPaths([`/${ this.activeCustomUrl }/`]);

        if (this.initial.customUrl.id) {
          // existing custom url should be updated or deleted
          if (this.activeCustomUrl === '') {
            return deleteCustomUrl(this.initial.customUrl.id);
          }
          return updateCustomUrl(this.initial.customUrl.id, path);
        }
        // create a new custom url
        return createCustomUrl(this.app.id, path);
      }
      return Promise.resolve();
    },
    saveAccessTypeAndEnvironment() {
      const attributes = {};
      if (this.typeIsChanging) {
        attributes.accessType = this.activeState.type;
      }
      if (Object.keys(attributes).length > 0) {
        return updateContent(this.app.guid, attributes);
      }
      return Promise.resolve();
    },
    savePrincipals() {
      if (this.listIsChanging) {
        return Promise.all(
          this.activePrincipals.filter(this.isChanged).map(principal => {
            if (principal.deleted) {
              if (principal.type === 'user') {
                return removeUser(this.app.id, principal.guid);
              }
              return removeGroup(this.app.id, principal.guid);
            } else if (principal.type === 'user') {
              return addUser(this.app.id, principal.guid, AppRoles.stringOf(principal.appRole));
            }
            return addGroup(this.app.id, principal.guid, AppRoles.stringOf(principal.appRole));
          })
        );
      }
      return Promise.resolve();
    },
    save() {
      return this.saveConfirmation();
    },
    saveConfirmation() {
      const savingTimeout = setTimeout(() => {
        this.isSaving = true;
      }, 300);

      this.clearStatusMessage();

      // Return the user to the content listing if they would no longer be able to view this:
      // * access type is ACL
      // * current user is not the owner or an admin
      // * no remaining active principals include the user
      const closeRequired = this.activeState.type === AccessTypes.Acl &&
        this.currentUser.guid !== this.ownerGuid &&
        !this.isAdmin &&
        !this.activePrincipals
          .filter(p => !p.deleted)
          .some(this.containsCurrentUser);

      // Reload the whole page after saving if current user is changing roles
      const reloadRequired =
        this.activePrincipals.some(p => this.containsCurrentUser(p) && p.changed);

      // What appears to the user as a single action is really (potentially) a batch of updates:
      // * POST, PUT, or DELETE custom url
      // * update app accessType
      // * POST or DELETE users and groups, one at a time
      //
      // We will attempt them all at once and report the first error, if any---unfortunately
      // without a batch API available on the server we cannot avoid the possibility of getting
      // in a half-updated state.
      return Promise.all([
        this.saveCustomUrl(),
        this.saveAccessTypeAndEnvironment(),
        this.savePrincipals(),
      ])
        .then(() => {
          if (closeRequired) {
            this.$router.push({ name: 'contentList' });
            return null;
          }

          if (reloadRequired) {
            window.location.reload();
            return null;
          }

          // clear the permission request, if any
          this.clearPermissionRequest();
          return this.reloadAccessData();
        })
        .catch(e => {
          this.setSaveErrorMessage(e);
          // try to make sure the panel data is up-to-date, but if there are
          // additional errors don't hide the primary one we just displayed
          return this.reloadAccessData(true);
        })
        .finally(() => {
          clearTimeout(savingTimeout);
          this.isSaving = false;
        });
    },
    async reloadAccessData(keepErr = false) {
      await this.resetApp({
        appIdOrGuid: this.app.guid,
        variantId: this.currentVariant.id,
      });
      try {
        await this.getData();
      } catch (err) {
        if (!keepErr) {
          this.setErrorMessageFromAPI(err);
        }
      }
      this.reloadFrame(true);
    },
    discard() {
      this.resetActive();
    },
    setSaveErrorMessage(e) {
      if (
        e &&
        e.response &&
        e.response.data &&
        e.response.data.code === ApiErrors.VanityPathInUse
      ) {
        // If this is a conflicting vanity URL message, display conflicts
        const notShown = e.response.data.payload.NotShown || 0;
        const conflicts = e.response.data.payload.Details.length || 0;
        let message;
        if (conflicts > 0 && notShown === 0) {
          message = this.$t('appSettings.access.messages.customUrlConflict.visible');
        } else if (conflicts === 0 && notShown > 0) {
          message = this.$t('appSettings.access.messages.customUrlConflict.notVisible', {
            notShown,
          });
        } else {
          // case where (conflicts > 0 && notShown > 0)
          const total = notShown + conflicts;
          message = this.$t('appSettings.access.messages.customUrlConflict.mixed', {
            total,
            notShown,
          });
        }
        if (conflicts > 0) {
          message += e.response.data.payload.Details
            .map(conflict => conflict.url)
            .join(', ');
        }
        message = this.$t('common.errors.withString', {
          errorMsg: message,
        });
        this.setErrorMessage({ message });
        return;
      }

      this.setErrorMessageFromAPI(e);
    },
    clearPermissionRequest() {
      this.requestByToken = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'connect-elements/src/styles/shared/_colors';

// Make different section titles all match (several different elements are used
// which have slightly different default styles.)
.access {
  margin-bottom: 15px;
  padding-bottom: 15px;

  .rs-radiogroup__title {
    color: $color-secondary-inverse;
  }

  .rs-help-toggler__label {
    font-size: 0.9rem;
  }
}
.groupHeadings {
  color: $color-heading;
  letter-spacing: .1em;
  font-size: 1em;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}
.spaceBefore {
  margin-top: 0.5rem;
}
.spaceAfter {
  margin-bottom: 0.5rem;
}
</style>
