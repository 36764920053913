// Copyright (C) 2022 by Posit Software, PBC.

// Initialize Vue-i18n library
// with configuration and language resource objects

import { createI18n } from 'vue-i18n';
import { i18nModifier } from './systemConstants';
import { en } from './en/index';
import { es } from './es/index';

const languageConfig = {
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: en,
    es: es,
  },
  modifiers: {
    sys: i18nModifier,
  },
};

const vueI18n = createI18n(languageConfig);

export { vueI18n };
