// Copyright (C) 2024 by Posit Software, PBC.

// A catalog of constants which values are re-defined at runtime.
// Using vue-i18n custom modifiers:
// https://vue-i18n.intlify.dev/guide/essentials/syntax.html#custom-modifiers
// E.g: systemDisplayName has a default value of 'Posit Connect'
//      but will be re-defined when server settings are pulled.

// HOW TO:
// Any constant registered below, can be called form locale strings
// and the proper value will be picked.
// E.g:
//  `Warning: You are accessing @.sys:{'system.displayName'} over an insecure HTTP connection.`

export const DISPLAY_NAME = 'displayName';

// Adding or removing constants just need to be done in below catalog.
const catalog = {
  // constant key - default value
  [DISPLAY_NAME]: 'Posit Connect',
};

export const systemMessages = () => {
  const messages = {};
  Object.keys(catalog).forEach(c => { messages[c] = c; return c; });
  return messages;
};

export const defineI18nConstant = (constantKey, value) => {
  catalog[constantKey] = value;
};

export const i18nModifier = str => {
  return catalog[str];
};

