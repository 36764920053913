<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel--white">
    <div class="wizard-panel__content">
      <StepHeading :step="step">
        {{ $t('publish.pythonDependencies.title') }}
      </StepHeading>

      <i18n-t
        keypath="publish.pythonDependencies.body"
        tag="p"
      >
        <template #zip>
          <code>{{ zip }}</code>
        </template>
      </i18n-t>

      <div class="wizard-panel__section">
        <h3 class="wizard-panel__subtitle">
          {{ $t('publish.pythonDependencies.install') }}
        </h3>

        <div class="python-dependencies__install">
          <pre class="python-dependencies__install-code"><code>{{ installCode }}</code></pre>

          <CopyButton
            class="python-dependencies__install-copy"
            :copy-text="installCode"
          />
        </div>
      </div>

      <ul class="wizard-panel__links">
        <li>
          <a
            href="https://pip.pypa.io/en/stable/"
            target="_blank"
          >{{ $t('publish.pythonDependencies.links.pip') }}</a>
        </li>
        <li>
          <a
            href="https://pip.pypa.io/en/stable/user_guide/#requirements-files"
            target="_blank"
          >{{ $t('publish.pythonDependencies.links.requirements') }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import PanelMixin from '../PanelMixin';
import StepHeading from '@/views/content/PublishWizard/StepHeading';
import CopyButton from '@/components/CopyButton';

export default {
  name: 'PythonDependencies',
  components: {
    CopyButton,
    StepHeading,
  },
  mixins: [PanelMixin],
  computed: {
    installCode() {
      return 'pip install -r requirements.txt';
    }
  }
};
</script>

<style lang="scss" scoped>
  .python-dependencies {
    &__install {
      display: flex;

      &-code {
        flex-grow: 1;
        margin-bottom: 0;
      }

      &-copy {
        margin-left: 0.25rem;
      }
    }
  }
</style>
