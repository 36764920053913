<!-- Copyright (C) 2023 by Posit Software, PBC. -->
<template>
  <RSModal
    :active="true"
    :subject="$t('parametersPanel.legacy.renameModal.title')"
    data-automation="params-rename-modal"
    @close="closeModal"
  >
    <template #content>
      <div>
        <RSInputText
          v-model.trim="newName"
          :label="$t('parametersPanel.legacy.renameModal.label')"
          :maxlength="MAX_LENGTH"
          name="rename-variant-input"
        />
      </div>
    </template>

    <template #controls>
      <RSButton
        :label="$t('parametersPanel.legacy.renameModal.button')"
        :disabled="disabled"
        @click="submit"
      />
    </template>
  </RSModal>
</template>

<script>
import RSButton from 'Shared/components/RSButton';
import RSInputText from 'Shared/components/RSInputText';
import RSModal from 'Shared/components/RSModal';
import { PARAMETERIZATION_RENAME_VARIANT } from '@/store/modules/parameterization';
import { LEGACY_PARAMS_SET_RENAMING_VARIANT } from '@/store/modules/legacyParams';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'LegacyRenameVariantModal',
  components: {
    RSButton,
    RSInputText,
    RSModal,
  },
  data() {
    return {
      newName: '',
      MAX_LENGTH: 50,
    };
  },
  computed: {
    ...mapState({
      currentVariantName: state => state.parameterization.currentVariant.name,
    }),
    disabled() {
      // Somehow the v-model trim modifier does not do what is expected
      // with the latest Vue compat build.
      return !this.newName.trim().length;
    },
  },
  created() {
    // Copy current variant name for input value
    this.newName = this.currentVariantName;
  },
  methods: {
    ...mapMutations({
      setModalState: LEGACY_PARAMS_SET_RENAMING_VARIANT,
    }),
    ...mapActions({
      renameVariant: PARAMETERIZATION_RENAME_VARIANT,
    }),
    submit() {
      if (this.disabled) {
        return;
      }

      // Somehow the v-model trim modifier does not do what is expected
      // with the latest Vue compat build.
      return this.renameVariant(this.newName.trim())
        .then(() => {
          this.closeModal();
        });
    },
    closeModal() {
      this.setModalState(false);
    },
  },
};
</script>
