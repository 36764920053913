<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div>
    <p
      class="rsc-lead"
      data-automation="no-assigned-tags-message"
    >
      {{ $t('appSettings.tags.cantAssign.message') }}
    </p>
    <p
      v-if="isAdmin"
      data-automation="create-tags-message"
    >
      {{ $t('appSettings.tags.cantAssign.whenAdmin.create') }}
      <router-link
        data-automation="tag-editor-link"
        :to="{ name: 'admin.tag_editor' }"
      >
        {{ $t('appSettings.tags.cantAssign.tagEditor') }}
      </router-link>.
      {{ $t('appSettings.tags.cantAssign.whenAdmin.onlyAdmins') }}
    </p>
    <p v-else>
      {{ $t('appSettings.tags.cantAssign.notAdmin') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'EmptyTagsMessage',
  props: {
    isAdmin: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
