<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <aside
    ref="optionsPanel"
    :style="style"
    class="rsc-content-list__options"
    aria-labelledby="header-title"
  >
    <div class="rsc-options-panel__header">
      <h2
        id="header-title"
        class="rsc-options-panel__header-title"
      >
        {{ $t('common.options.title') }}
      </h2>
      <button
        :aria-label="$t('common.options.hideOptionsPanel')"
        class="paneCloser"
        @click="toggleOptions"
      />
    </div>

    <div class="rsc-content-list__options-search">
      <RSInputSearch
        ref="searchInput"
        :show-label="false"
        :value="filters.search"
        :label="$t('content.list.labels.searchForContent')"
        :placeholder="$t('content.list.labels.searchForContentName')"
        :small="true"
        :show-clear="Boolean(filters.search)"
        name="cop-search"
        data-automation="cop-search"
        icon="search"
        @input="onSearchChange"
        @clear="clearSearch"
      />
    </div>

    <div
      v-if="hasVisibilityFilter"
      class="rs-field"
    >
      <RSRadioGroup
        :title="visibilityTitle"
        :options="visibilityOptions"
        :value="visibilityOptionValue"
        name="cop-visibility"
        data-automation="visibility-filter"
        @change="onVisibilityChange"
      />
    </div>

    <div class="rsc-content-list__options-tags rs-field">
      <TagsCatalogSelector
        :selected="selectedTags"
        :prefetched="true"
        @change="onTagChange"
      />
    </div>

    <div class="rs-field">
      <RSRadioGroup
        :title="$t('content.list.labels.contentType')"
        :options="contentTypeOptions"
        :value="filters.contentType"
        name="cop-content-type"
        data-automation="content-type-filter"
        @change="onContentTypeChange"
      />
    </div>
  </aside>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import RSRadioGroup from 'Shared/components/RSRadioGroup';
import RSInputSearch from 'Shared/components/RSInputSearch';

import OptionsPanel from '@/components/OptionsPanel';
import { debounce } from '@/utils/debounce';
import TagsCatalogSelector from '@/components/TagsCatalogSelector';
import { tagQueryFilterToObject } from './contentListUtils';
import {
  CONTENT_LIST_UPDATE_FILTER,
  CONTENT_LIST_UPDATE_START_PAGE,
  CONTENT_LIST_TOGGLE_OPTIONS,
  FilterType,
} from '@/store/modules/contentList';
import AppRoles from '@/api/dto/appRole';

const visibilityType = {
  viewer: 'viewer',
  editor: 'editor',
  owner: 'owner',
};

const visibilityValues = {
  admin: [
    { value: visibilityType.viewer, label: 'visibleToYou' },
    { value: visibilityType.editor, label: 'contentYouOwn' },
    { value: visibilityType.owner, label: 'allServerContent' },
  ],
  publisher: [
    { value: visibilityType.viewer, label: 'all' },
    { value: visibilityType.editor, label: 'you' },
  ],
};

const contentTypeValues = [
  'all',
  'application',
  'document',
  'plot',
  'pin',
  'api',
];

export default {
  name: 'ContentOptionsPanel',
  components: { RSRadioGroup, RSInputSearch, TagsCatalogSelector },
  extends: OptionsPanel,
  props: {
    currentUser: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      filters: state => state.contentList.filters,
    }),
    hasVisibilityFilter() {
      return !this.currentUser.isViewer();
    },
    contentTypeOptions() {
      return contentTypeValues.map(value => ({
        label: this.$t(`content.list.contentType.${value}`),
        value,
      }));
    },
    visibilityOptions() {
      if (this.currentUser.isPublisher()) {
        return visibilityValues.publisher.map(this.mapVisibilityOptions);
      }

      return visibilityValues.admin.map(this.mapVisibilityOptions);
    },
    visibilityTitle() {
      return this.currentUser.isPublisher()
        ? this.$t('content.list.labels.owner')
        : this.$t('content.list.labels.visibility');
    },
    visibilityOptionValue() {
      return AppRoles.stringOf(this.filters.visibility);
    },
    selectedTags() {
      const { tags } = this.filters;
      const tagsToSet = Object.keys(tags).flatMap(key => tags[key].selected);
      return new Set(tagsToSet);
    },
  },
  methods: {
    ...mapMutations({
      updateFilter: CONTENT_LIST_UPDATE_FILTER,
      updateStartPage: CONTENT_LIST_UPDATE_START_PAGE,
      toggleOptions: CONTENT_LIST_TOGGLE_OPTIONS,
    }),
    // Called externally!
    focus() {
      this.$nextTick(() => this.$refs.searchInput.focusElement());
    },
    mapVisibilityOptions({ value, label }) {
      return {
        label: this.$t(`content.list.visibility.${label}`),
        value,
      };
    },
    clearSearch() {
      this.updateFilter({ type: FilterType.SEARCH, value: '' });
      this.updateStartPage(0); // reset back to the first page
      this.$emit('filter');
    },
    onSearchChange: debounce(300, function(value) {
      this.updateFilter({ type: FilterType.SEARCH, value });
      this.updateStartPage(0); // reset back to the first page
      this.$emit('filter');
    }),
    onVisibilityChange(value) {
      this.updateFilter({
        type: FilterType.VISIBILITY,
        value: AppRoles.of(value),
      });
      this.updateStartPage(0); // reset back to the first page
      this.$emit('filter');
    },
    onContentTypeChange(value) {
      this.updateFilter({
        type: FilterType.CONTENT_TYPE,
        value,
      });
      this.updateStartPage(0); // reset back to the first page
      this.$emit('filter');
    },
    onTagChange({ categoryId, categoryFilter, categoryName, selectedTags }) {
      const tags = JSON.parse(JSON.stringify(this.filters.tags));
      const categoryIsInStore = Boolean(tags[categoryId]);
      const tag = tagQueryFilterToObject(
        categoryFilter,
        categoryName,
        selectedTags
      );

      if (!categoryFilter && categoryIsInStore) {
        // remove tag category
        delete tags[categoryId];
      } else {
        // add or update tag category
        tags[categoryId] = tag;
      }

      this.updateFilter({ type: FilterType.TAGS, value: tags });
      this.updateStartPage(0); // reset back to the first page
      this.$emit('filter');
    },
  },
};
</script>
