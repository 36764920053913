<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div>
    <div
      class="flex"
      data-automation="unpublished-title"
    >
      <h1
        ref="title"
        class="sectionTitle focusedTitle"
        tabindex="-1"
      >
        {{ $t('admin.unpublishedContent.title') }}
      </h1>
    </div>

    <EmbeddedStatusMessage
      v-show="!loaded"
      :show-close="false"
      :message="$t('admin.unpublishedContent.fetching')"
      type="activity"
    />

    <div v-show="loaded">
      <p>
        {{ $t('admin.unpublishedContent.description') }}
      </p>

      <div v-if="hasUnpublishedContent">
        <RSTable :columns="tableHeaders">
          <RSTableRow
            v-for="app in unpublishedContent"
            :key="app.id"
          >
            <!-- Content name w/ icon -->
            <RSTableCell
              :cell-id="`${app.id}-name`"
              :has-icon="true"
              :fill="true"
              data-automation="content-row-icon"
            >
              <router-link
                :to="{ name: 'apps.access', params: { idOrGuid: app.guid } }"
                :title="$t(`content.list.tooltip.${app.contentType()}`)"
                class="content-table__content-name"
                data-automation="content-row-title"
              >
                <div>
                  <i class="rs-icon rs-icon--large typeUnpublished" />
                </div>
                <div
                  class="content-table__display-name"
                >
                  {{ app.displayName || $t('content.untitled') }}
                </div>
              </router-link>
            </RSTableCell>

            <!-- Content type -->
            <RSTableCell data-automation="content-row-type">
              {{ $t(`content.type.description.${app.contentType()}`) }}
            </RSTableCell>

            <!-- Content author (owner info is available) -->
            <RSTableCell
              v-if="hasOwner(app)"
              :cell-id="`${app.id}-author`"
              data-automation="content-row-author-cell"
            >
              <router-link
                :to="{ name: 'people.users.profile', params: { guid: app.ownerGuid } }"
                :title="$t('users.action.navigateOwner')"
                data-automation="content-row-author"
              >
                {{ ownerName(app) }}
              </router-link>
            </RSTableCell>
            <!-- Content author (empty cell b/c owner info is missing) -->
            <RSTableCell
              v-else
              data-automation="content-row-author-cell"
            />

            <!-- Content last deployed date -->
            <RSTableCell data-automation="content-row-updated">
              {{ lastDeployedTime(app) }} {{ fromGit(app.git) }}
            </RSTableCell>
          </RSTableRow>
        </RSTable>

        <RSPager
          v-if="showPagination"
          :disable-left-actions="disablePagingLeft"
          :disable-right-actions="disablePagingRight"
          :labels="pagerLabels"
          @first-page="goToFirstPage"
          @previous-page="goToPage"
          @next-page="goToPage"
          @last-page="goToLastPage"
        />
      </div>

      <div
        v-if="!hasUnpublishedContent"
        class="emptyListMessage"
      >
        {{ $t('admin.unpublishedContent.noContent') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import { getUnpublishedContent } from '@/api/content';
import RSTable from 'Shared/components/RSTable';
import RSTableRow from 'Shared/components/RSTableRow';
import RSTableCell from 'Shared/components/RSTableCell';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import { activeTime } from '@/utils/activeTime.filter';
import { truncate } from '@/utils/truncate.filter';
import { hasOwner, fromGit, displayOwnerName } from '@/utils/contentList';
import RSPager from 'Shared/components/RSPager';

// number of records to display at a time
const maxRecords = 10;

export default {
  name: 'UnpublishedContentView',
  components: {
    EmbeddedStatusMessage,
    RSTable,
    RSTableRow,
    RSTableCell,
    RSPager,
  },
  data() {
    return {
      unpublishedContent: [],
      loaded: false,
      continuation: '',
      start: 0,
      count: 0,
      total: 0,
    };
  },
  computed: {
    hasUnpublishedContent() {
      return Boolean(this.unpublishedContent.length);
    },
    showPagination() {
      return this.total > maxRecords;
    },
    disablePagingLeft() {
      return this.start === 0;
    },
    disablePagingRight() {
      return this.start + this.count === this.total;
    },
    tableHeaders() {
      return ['name', 'type', 'author', 'lastDeployedTime'].map(header => {
        const label = this.$t(`content.list.headers.${header}`);
        if (header === 'name') {
          return { label, width: '100%' };
        }
        return label;
      });
    },
    pagerLabels() {
      return {
        first: this.$t('navigation.pagination.temporal.first'),
        previous: this.$t('navigation.pagination.temporal.previous'),
        next: this.$t('navigation.pagination.temporal.next'),
        last: this.$t('navigation.pagination.temporal.last'),
      };
    }
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$refs.title.focus();
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    fetchData() {
      const { start, continuation: cont } = this;
      this.loaded = false;
      return getUnpublishedContent({ count: maxRecords, start, cont })
        .then(data => {
          this.unpublishedContent = data.applications || [];
          this.continuation = data.continuation;
          this.count = data.count;
          this.total = data.total;
        })
        .catch(this.setErrorMessageFromAPI)
        .finally(() => {
          this.loaded = true;
        });
    },
    fromGit,
    hasOwner,
    ownerName(app) {
      const ownerName = displayOwnerName(app);
      return truncate(ownerName, 18);
    },
    lastDeployedTime(app) {
      return activeTime(app.lastDeployedTime);
    },
    goToPage(direction) {
      this.start =
        direction === 'next'
          ? this.start + maxRecords
          : this.start - maxRecords;
      this.fetchData();
    },
    goToFirstPage() {
      this.start = 0;
      this.fetchData();
    },
    goToLastPage() {
      this.start = Math.floor(this.total / maxRecords) * maxRecords;
      this.fetchData();
    },
  },
};
</script>
