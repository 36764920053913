<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    :class="componentClass"
    role="button"
    tabindex="-1"
    @click.stop="handleSelection(true)"
    @keypress.stop="handleSelection(true)"
  >
    <div class="tags-category__name">
      <input
        ref="mainInput"
        v-model="categoryLabel"
        :class="mainInputClass"
        aria-label="Tag Category Name"
        :data-automation="`tag-category-${category.id}-input`"
        @focus="handleSelection(false)"
        @blur="handleBlur($event, submitUpdateTag)"
        @keypress.enter.prevent="submitUpdateTag"
      >
      <span
        v-if="!isEditing"
        role="button"
        tabindex="-1"
        class="tags-category__name-label"
        :data-automation="`tag-category-${category.id}-label`"
        @click="focusOnEditAttempt"
        @keypress.stop="focusOnEditAttempt"
      >
        {{ category.name }}
      </span>
    </div>
    <button
      v-if="isSelected"
      ref="delete"
      :aria-label="$t('admin.tags.deleteCategory', { category: category.name })"
      class="tags-category__delete"
      :data-automation="`tag-category-${category.id}-delete-btn`"
      @blur="handleBlur"
      @click="submitDeleteTag"
      @keypress.stop
    />
    <div
      v-for="tag in category.children"
      :key="`tag-${tag.id}`"
      class="tags-category__child"
    >
      <TagsGroup
        v-if="tag.children.length"
        :main-tag="tag"
        @delete="submitDeleteTag(tag)"
      />
      <TagItem
        v-else
        :label="tag.name"
        :add-subtag-label="$t('admin.tags.addSubtag', { tag: tag.name })"
        :delete-label="$t('admin.tags.deleteTag', { tag: tag.name })"
        @extend="submitNewTag({ ...$event, parent: tag })"
        @update="submitUpdateTag({ name: $event, tag })"
        @delete="submitDeleteTag(tag)"
      />
    </div>
    <AddItemInput
      ref="addSubtagInput"
      v-model="newTagName"
      :placeholder="$t('admin.tags.newTagIn', { category: category.name })"
      @submit="submitNewTag"
      @blur="submitNewTag"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import {
  CREATE_NEW_TAG,
  UPDATE_TAG,
  SET_DELETE_REQUEST,
  RESET_FOCUS_FLAG,
} from '@/store/modules/adminTags';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import AddItemInput from '@/components/AddItemInput';
import TagSelectableBase from './TagSelectableBase';
import TagsGroup from './TagsGroup';
import TagItem from './TagItem';

const tagsCategoryClass = {
  base: 'tags-category',
  selected: 'tags-category--selected',
  mainInput: 'tags-category__name-input',
  editingInput: 'tags-category__name-input--editing',
};

export default {
  name: 'TagsCategory',
  components: {
    AddItemInput,
    TagItem,
    TagsGroup,
  },
  extends: TagSelectableBase,
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      categoryLabel: this.category.name,
      newTagName: '',
      lockSubmitTag: false,
    };
  },
  computed: {
    ...mapState({
      focusOnNewGroup: state => state.adminTags.focusOnNewGroup,
    }),
    componentClass() {
      const classMap = [tagsCategoryClass.base];
      if (this.isSelected) {
        classMap.push(tagsCategoryClass.selected);
      }
      return classMap;
    },
    mainInputClass() {
      const classMap = [tagsCategoryClass.mainInput];
      if (this.isEditing) {
        classMap.push(tagsCategoryClass.editingInput);
      }
      return classMap;
    },
  },
  created() {
    this.focusRefs = [
      'mainInput',
      'delete',
    ];
  },
  mounted() {
    // New category created?, focus on the add children input
    if (this.focusOnNewGroup) {
      this.$refs.addSubtagInput.focus();
      this.resetFocusFlag();
    }
  },
  methods: {
    ...mapMutations({
      resetFocusFlag: RESET_FOCUS_FLAG,
      requestDelete: SET_DELETE_REQUEST,
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    ...mapActions({
      createTag: CREATE_NEW_TAG,
      updateTag: UPDATE_TAG,
    }),
    // eslint-disable-next-line no-shadow
    submitNewTag({ name, parent, onBlur } = {}) {
      // Lock when a submission is already set, when @keypress resolution triggers @blur
      if (this.lockSubmitTag) {
        return;
      }
      this.lockSubmitTag = true;
      const payload = {
        name: name || this.newTagName,
        parentTag: parent || this.category,
        discardFocus: onBlur,
      };
      return this.createTag(payload)
        .then(() => {
          this.newTagName = '';
        })
        .catch(this.setErrorMessageFromAPI)
        .finally(() => {
          this.lockSubmitTag = false;
        });
    },
    // eslint-disable-next-line no-shadow
    submitUpdateTag({ name, tag } = {}) {
      const payload = {
        name: name || this.categoryLabel,
        currentTag: tag || this.category,
      };
      return this.updateTag(payload)
        .catch(response => {
          this.categoryLabel = payload.currentTag.name;
          return this.setErrorMessageFromAPI(response);
        });
    },
    // eslint-disable-next-line no-shadow
    submitDeleteTag({ id, name, version } = {}) {
      // if !id, the category will be deleted
      const payload = {
        id: id || this.category.id,
        name: name || this.category.name,
        version: id ? version : this.category.version,
        parentTag: id ? this.category : null,
      };
      this.requestDelete(payload);
    },
  },
};
</script>
