<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <RSModal
    :active="true"
    :subject="subject"
    @close="$emit('cancel')"
  >
    <template #content>
      <div
        class="modalContent"
        data-automation="confirm-modal"
      >
        <p class="message">
          {{ warning }}
        </p>

        <i18n-t
          keypath="parametersPanel.confirmModal.message"
          tag="p"
        >
          <template #cancel>
            <strong>{{ cancelLabel }}</strong>
          </template>

          <template #discard>
            <strong>{{ discardLabel }}</strong>
          </template>
        </i18n-t>
      </div>
    </template>

    <template #controls>
      <div class="controls">
        <RSButton
          type="secondary"
          :label="cancelLabel"
          @click="$emit('cancel')"
        />
        <RSButton
          type="primary"
          :label="discardLabel"
          @click="$emit('discard')"
        />
      </div>
    </template>
  </RSModal>
</template>

<script>
import RSModal from 'Shared/components/RSModal';
import RSButton from 'Shared/components/RSButton';

export default {
  name: 'ConfirmModal',
  components: { RSModal, RSButton },
  props: {
    subject: {
      type: String,
      required: true,
    },
    warning: {
      type: String,
      required: true,
    },
    cancelLabel: {
      type: String,
      required: true,
    },
    discardLabel: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  font-size: 16px;
  margin-bottom: 2rem;
  white-space: normal;
}

.prompt {
  font-size: 16px;
  margin-bottom: 2rem;
}

.controls {
  display: flex;
  justify-content: flex-end;

  :last-child {
    margin-left: 1em;
  }
}
</style>
