// Copyright (C) 2023 by Posit Software, PBC.

const renderSender = {
  title: 'Send Report',
  details: {
    isSite: 'Email a completion notification to:',
    notSite: 'Email this version of the report to:'
  },
  options: {
    me: 'just me',
    collaborators: 'collaborators',
    collaboratorsViewers: 'collaborators & viewers',
    all: 'everyone on the server'
  },
  titles: {
    notCollaborator: 'You must be a collaborator to send email to other users',
    isDirtyReportOutput: 'You may only email ad-hoc report output to yourself',
    noViewers: 'No viewers to email',
    noCollaborators: 'No collaborators to email',
  },
  send: 'Send',
  emailSubmitted: 'Your email request has been submitted.',
  noEmailAdmin: 'Email delivery has not been configured. See the Admin Guide for more information.',
  noEmail: `Email delivery has not been configured. Ask your @.sys:{'system.displayName'} administrator for assistance.`,
};

export { renderSender };
