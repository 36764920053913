// Copyright (C) 2022 by Posit Software, PBC.

const welcome = {
  tagline: 'Bienvenido a Posit Connect',
  description: 'Distribuya la ciencia de los datos en su organización',
  getStarted: 'Comenzar',
  oneDeploymentButton: 'Despliegue con un botón de',
  apps: {
    shiny: 'Applicaciones Shiny',
    python: 'Aplicaciones de Python creadas con',
    r: 'Documentos R Markdown',
    jupyter: 'Jupyter Notebooks',
    static: 'Gráficos y diagramas estáticos',
  },
  features: {
    manageAccess: 'Gestiona y controla el acceso al trabajo que has compartido con otros, y ve fácilmente el trabajo que han compartido contigo',
    handsFree: 'Programación "manos libres" de las actualizaciones de sus documentos y distribución automática por correo electrónico',
  },
  moreInfo: 'Para obtener más información sobre la ejecución de Posit Connect en su organización, visite',
  errors: {
    login: `Hubo un problema al iniciar la sesión. Por favor, inténtelo de nuevo.<br>Si el problema persiste, pida ayuda a su administrador de @.sys:{'system.displayName'}`,
    policy: `Su nombre de usuario no está permitido porque está prohibido por la política. Por favor, pida ayuda a su administrador de @.sys:{'system.displayName'}`,
    default: 'Se ha producido un error inesperado',
  }
};

export { welcome };
