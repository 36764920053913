<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div>
    <div
      v-if="!suppressTitle"
      class="flex"
    >
      <h1
        ref="title"
        tabindex="-1"
        class="sectionTitle focusedTitle"
        data-automation="environment-list-title"
      >
        {{ $t('executionEnvironments.viewHeader.title') }}
      </h1>
      <div
        class="controls"
      >
        <div class="actionBar inline showTitles">
          <BaseButton
            v-if="!readonly"
            v-bind="{
              label: $t('executionEnvironments.viewHeader.addButton'),
              buttonClass: 'action new'
            }"
            :title="$t('executionEnvironments.viewHeader.addButton')"
            :aria-label="$t('executionEnvironments.viewHeader.addButton')"
            data-automation="add-environment"
            @clicked="onAddEnvironment"
          />
        </div>
      </div>
    </div>
    <div class="search-container">
      <RSInputSearch
        :show-label="false"
        :value="searchString"
        :label="$t('executionEnvironments.viewHeader.search.label')"
        :placeholder="$t('executionEnvironments.viewHeader.search.placeholder')"
        :small="false"
        :show-clear="searchString.length > 0"
        name="environment-search"
        icon="search"
        @input="onSearchChange"
        @clear="clearTextSearch"
      />
    </div>
  </div>
</template>

<script>
import { debounce } from '@/utils/debounce';

import RSInputSearch from 'Shared/components/RSInputSearch';
import BaseButton from '@/components/BaseButton';

export default {
  name: 'EnvironmentsListHeader',
  components: {
    RSInputSearch,
    BaseButton,
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    suppressTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchString: '',
    };
  },
  mounted() {
    if (!this.suppressTitle) {
      this.$nextTick(() => this.$refs.title.focus());
    }
  },
  methods: {
    onSearchChange: debounce(300, function(value) {
      this.searchString = value;
      this.$emit('filter', this.searchString);
    }),
    clearTextSearch() {
      this.searchString = '';
      this.$emit('filter', this.searchString);
    },
    onAddEnvironment() {
      this.$emit('add-environment');
    }
  },
};
</script>
<style lang="scss" scoped>
@import 'connect-elements/src/styles/shared/_mixins';
.controls {
  display: flex;
  flex: 0 0 3rem;
}
.search-container {
  margin: 0 0 0.3rem 0;
  width: 100%;
}
</style>
