<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div data-automation="content-list__empty">
    <p
      v-if="viewerWithoutFiltering"
      class="rsc-content-list__no-content__message"
    >
      {{ $t('content.list.noContent.viewer') }}
    </p>
    <div v-else-if="publisherWithoutFiltering">
      <div class="rsc-content-list__no-content">
        <div :class="['rsc-content-list__no-content__arrow', { 'rsc-content-list__no-content__arrow--collapsed-options': !contentList.isOptionsVisible }]" />
      </div>
      <p class="rsc-content-list__no-content__message">
        {{ $t('content.list.noContent.startPublishing') }}
      </p>
    </div>
    <p
      v-else
      class="rsc-content-list__no-content__message"
    >
      {{ $t('content.list.noContent.noFilterMatch') }}
      <a
        href="#"
        @click.prevent="resetAllFilters"
        @keydown.prevent.space="resetAllFilters"
        @keydown.prevent.enter="resetAllFilters"
      >
        {{ $t('common.actions.clearAllFilters') }}
      </a>
    </p>
  </div>
</template>

<script>
import AppRoles from '@/api/dto/appRole';
import { mapState, mapMutations } from 'vuex';
import {
  CONTENT_LIST_RESET_FILTERS,
  CONTENT_LIST_UPDATE_START_PAGE,
} from '@/store/modules/contentList';
import ContentTypes from './contentType';

export default {
  name: 'EmptyContent',
  props: {
    isViewer: {
      type: Boolean,
      default: false,
    },
    noTagsFound: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      contentList: state => state.contentList,
    }),
    usingTagFiltering() {
      const {
        tags,
      } = this.contentList.filters;
      return Object.keys(tags).length || this.noTagsFound;
    },
    usingSearchFiltering() {
      const {
        search,
      } = this.contentList.filters;
      return !!search;
    },
    viewerWithoutFiltering() {
      const {
        visibility,
        contentType,
      } = this.contentList.filters;
      return (
        this.isViewer &&
        AppRoles.isViewer(visibility) &&
        ContentTypes.isAll(contentType) &&
        !this.usingSearchFiltering &&
        !this.usingTagFiltering
      );
    },
    publisherWithoutFiltering() {
      const {
        visibility,
        contentType,
      } = this.contentList.filters;
      return (
        !this.isViewer &&
        (AppRoles.isViewer(visibility) || AppRoles.isEditor(visibility)) &&
        ContentTypes.isAll(contentType) &&
        !this.usingSearchFiltering &&
        !this.usingTagFiltering
      );
    },
  },
  methods: {
    ...mapMutations({
      resetFilters: CONTENT_LIST_RESET_FILTERS,
      updateStartPage: CONTENT_LIST_UPDATE_START_PAGE,
    }),
    resetAllFilters() {
      this.resetFilters();
      this.updateStartPage(0);
      this.$emit('reset');
    },
  },
};
</script>
