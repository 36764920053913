<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
Read-only Mail Settings page, with Send Test Email button
-->
<template>
  <div class="settings flex">
    <h1
      ref="title"
      class="sectionTitle focusedTitle"
      tabindex="-1"
    >
      {{ $t('email.settings.form.title') }}
    </h1>
    <div
      class="settingsNote"
      data-automation="read-only-note"
    >
      <br>
      <p>
        {{ $t('email.settings.readOnly.intro') }}
      </p>
      <i18n-t
        keypath="email.settings.readOnly.docLink"
        tag="span"
      >
        <template #emailSetup>
          <a
            :href="emailSetupUrl"
            target="_blank"
          >
            {{ $t('email.settings.readOnly.linkLabel') }}
          </a>
        </template>
      </i18n-t>
    </div>
    <div
      v-show="loaded"
      class="settingsInfo"
    >
      <div class="section">
        <div class="label">
          {{ $t('email.settings.sendType.label') }}
        </div>
        <div data-automation="sendtype">
          {{ $t(sendType) }}
        </div>
      </div>

      <div v-if="isSMTP">
        <div
          class="section"
        >
          <div class="label">
            {{ $t('email.settings.smtp.address.label') }}
          </div>
          <div data-automation="smtp-host">
            {{ form.host }}
          </div>
        </div>

        <div
          class="section"
        >
          <div class="label">
            {{ $t('email.settings.smtp.port.label') }}
          </div>
          <div data-automation="smtp-port">
            {{ form.port }}
          </div>
        </div>

        <div class="section">
          <div class="label">
            Use TLS
          </div>
          <div data-automation="smtp-starttls">
            {{ $t(startTls) }}
          </div>
        </div>

        <div
          v-if="form.auth"
          class="section"
        >
          <div
            class="label"
            data-automation="smtp-req-auth"
          >
            ✓ {{ $t('email.settings.smtp.auth.label') }}
          </div>
        </div>

        <div
          v-if="form.auth"
          class="section"
        >
          <div class="label">
            {{ $t('email.settings.smtp.auth.userName.label') }}
          </div>
          <div data-automation="smtp-user">
            {{ form.user }}
          </div>
        </div>
        <div
          v-if="form.hasPassword"
          class="section aside"
          data-automation="password"
        >
          {{ $t('email.settings.smtp.auth.password.label') }}
        </div>
      </div>

      <div class="actions">
        <BaseButton
          v-if="isConfigured"
          v-bind="{
            label: $t('email.settings.form.sendTestEmail.label'),
            buttonClass: 'default'
          }"
          data-automation="test-email"
          @clicked.stop.prevent="sendTestEmail"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  getMailSettings,
  testMailSettings,
} from '@/api/serverSettings';
import BaseButton from '@/components/BaseButton';
import { docsPath } from '@/utils/paths';
import { mapState, mapMutations, mapActions } from 'vuex';
import {
  SHOW_INFO_MESSAGE,
  SHOW_ERROR_MESSAGE,
  CLEAR_STATUS_MESSAGE,
} from '@/store/modules/messages';

export default {
  name: 'MailSettingsView',
  components: { BaseButton },
  data() {
    return {
      emailSetupUrl: docsPath('admin/email/'),
      readOnly: false,
      form: {
        type: '',
        host: '',
        port: 0,
        ssl: false,
        startTls: '',
        auth: false,
        user: '',
        password: '',
      },
    };
  },
  computed: {
    hasAuth() {
      return this.isSMTP && this.form.auth;
    },
    isConfigured() {
      return this.form.type !== 'none';
    },
    isSMTP() {
      return this.form.type === 'smtp';
    },
    loaded() {
      return Boolean(this.form.type);
    },
    sendType() {
      if (this.isSMTP) {
        return 'email.settings.sendType.option.smtp';
      } else if (!this.isSMTP && this.isConfigured) {
        return 'email.settings.sendType.option.sendmail';
      }
      return 'email.settings.sendType.option.none';
    },
    startTls() {
      let tlsOption = 'whenAvailable';
      if (this.form.startTls !== '') {
        tlsOption = this.form.startTls;
      }
      return `email.settings.smtp.startTLS.options.${tlsOption}`;
    },
    ...mapState({
      currentUser: state => state.currentUser.user,
    })

  },
  created() {
    this.getSettings();
  },
  mounted() {
    this.$refs.title.focus();
  },
  beforeUnmount() {
    this.clearStatusMessage();
  },
  methods: {
    ...mapMutations({
      clearStatusMessage: CLEAR_STATUS_MESSAGE,
    }),
    ...mapActions({
      setInfoMessage: SHOW_INFO_MESSAGE,
      setErrorMessage: SHOW_ERROR_MESSAGE,
    }),
    errorHandler(e) {
      let message;
      if (e && e.response && e.response.data && e.response.data.error) {
        message = this.$t('common.errors.withString', {
          errorMsg: e.response.data.error,
        });
      } else if (e && e.error) {
        message = this.$t('common.errors.withString', {
          errorMsg: e.error,
        });
      } else {
        message = this.$t('common.errors.unknown');
      }
      this.setErrorMessage({ message });
    },
    getSettings() {
      return getMailSettings()
        .then(res => {
          this.readOnly = (res && res.readOnly) || false;
          // add password field back to form, since it is not returned by API
          this.form = { ...(res || {}), password: '' };
          // must not display a port value of zero or less
          if (this.form.port < 1) {
            this.form.port = null;
          }
        })
        .catch(e => this.errorHandler(e));
    },
    sendTestEmail() {
      this.clearStatusMessage();
      this.setInfoMessage({ message: this.$t('email.settings.form.sendTestEmail.eventMsg.activity') });
      return testMailSettings()
        .then(() => {
          this.clearStatusMessage();
          this.setInfoMessage({
            message: this.$t('email.settings.form.sendTestEmail.eventMsg.success', {
              address: this.currentUser.email,
            }),
          });
        })
        .catch(e => {
          this.clearStatusMessage();
          this.errorHandler(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'connect-elements/src/styles/shared/_colors';

.settings {
  flex-direction: column;
}

.settingsInfo {
  width: 400px;
}

.section {
  background-color: $color-light-grey;
  line-height: 24px;
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.4rem;
}

.label {
  font-size: 18px;
  font-weight: 300;
  padding-right: 0.4rem;
}

.aside {
  font-style: italic;
}
</style>
