<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <RSModal
    data-automation="delete-group"
    :active="true"
    :subject="$t('groups.actions.delete.title')"
    @close="$emit('close')"
  >
    <template #content>
      <div
        class="details rsc-text"
        data-automation="content-remove-message"
      >
        <p class="rsc-alert warning">
          {{ $t('groups.actions.delete.implications', { name: group.name }) }}
        </p>
        <p>
          {{ $t('groups.actions.delete.desc', { name: group.name }) }}
        </p>
      </div>
    </template>
    <template #controls>
      <RSButton
        id="dgd-submit"
        :label="$t('common.buttons.ok')"
        data-automation="delete-group__ok-button"
        @click="deleteGroup"
      />
    </template>
  </RSModal>
</template>

<script>
import RSButton from 'Shared/components/RSButton';
import RSModal from 'Shared/components/RSModal';
import { deleteGroup } from '@/api/groups';
import { mapMutations, mapActions } from 'vuex';
import {
  SHOW_INFO_MESSAGE,
  SET_ERROR_MESSAGE_FROM_API,
  CLEAR_STATUS_MESSAGE,
} from '@/store/modules/messages';

export default {
  name: 'DeleteGroupDialog',
  components: { RSButton, RSModal },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.$el.querySelector('#dgd-submit').focus();
  },
  methods: {
    ...mapMutations({
      clearStatusMessage: CLEAR_STATUS_MESSAGE,
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    ...mapActions({
      setInfoMessage: SHOW_INFO_MESSAGE,
    }),
    deleteGroup() {
      this.clearStatusMessage();
      return deleteGroup(this.group.guid)
        .then(() => {
          this.setInfoMessage({
            message: this.$t('groups.status.deleted', {
              name: this.group.name,
            }),
          });
          this.$emit('close', this.group.guid);
        })
        .catch(e => {
          this.setErrorMessageFromAPI(e);
          this.$emit('close');
        });
    },
  },
};
</script>

<style scoped>
.rsc-text p {
  margin-bottom: 1rem;
}
</style>
